<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div ref="formFields">
                    <div class="tile is-ancestor">
                        <div class="tile is-parent is-vertical is-9">
                            <article class="tile is-child box">
                                <div class="columns">
                                    <div class="column">
                                        <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                            <b-field :label="$t('MEDICOCADASTRO.NOME')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                                <b-input type="text" v-model="model.nome" maxlength="150"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <buscaCepEndereco :cep.sync="model.cep"
                                                :endereco.sync="model.endereco"
                                                :bairro.sync="model.bairro"
                                                :cidadeid.sync="model.cidadeId"
                                                :cidadenome.sync="model.cidadeNome">
                                </buscaCepEndereco>
                                <div class="columns">
                                    <div class="column">
                                        <b-field :label="$t('MEDICOCADASTRO.FONE')">
                                            <b-input v-model="model.fone" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                        </b-field>
                                    </div>
                                    <div class="column">
                                        <b-field :label="$t('MEDICOCADASTRO.CEL')">
                                            <b-input v-model="model.celular" v-mask="['(##) ####-####', '(##) #####-####']"></b-input>
                                        </b-field>
                                    </div>
                                    <div class="column is-half-mobile">
                                        <b-field :label="$t('MEDICOCADASTRO.DATA')">
                                            <b-datepicker  editable v-model="model.dataDeNascimento" trap-focus></b-datepicker>
                                        </b-field>
                                    </div>
                                    <div class="column is-half-mobile">
                                        <div class="field">
                                            <label class="label">{{$t('MEDICOCADASTRO.IDADE')}}</label>
                                            <div class="control">
                                                <input class="input" type="text" v-model="idade" readonly>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="columns is-multiline is-mobile">
                                    <div class="column">
                                            <b-field :label="$t('MEDICOCADASTRO.CPF')">
                                                <b-input type="text" v-model="model.cpf" v-mask="config.language == 'es' ? '########' : '###.###.###-##'"></b-input>
                                            </b-field>
                                    </div>
                                    <div class="column">
                                        <div class="field">
                                            <label class="label">{{$t('MEDICOCADASTRO.CONSELHO')}}</label>
                                            <div class="select full-width">
                                                <select v-model="model.conselho" class="full-width">
                                                    <option v-for="conselho in $store.state.conselhos"
                                                            :key="conselho.value"
                                                            :value="conselho.value">
                                                        {{ conselho.text }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <div class="field">
                                            <label class="label">{{$t('MEDICOCADASTRO.UF')}}</label>
                                            <div class="select full-width">
                                                <select v-model="model.conselhoUF" class="full-width">
                                                    <option v-for="uf in $store.state.estados"
                                                            :key="uf"
                                                            :value="uf">
                                                        {{ uf}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <div class="field">
                                            <label class="label">{{$t('MEDICOCADASTRO.NUMEROCONSELHO')}}</label>
                                            <div class="control">
                                                <b-input type="text" v-model="model.conselhoNumero" maxlength="15" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <div class="column is-half-mobile">
                                            <div class="field">
                                                <label class="label">{{$t('MEDICOCADASTRO.CODOPERADORA')}}</label>
                                                <div class="control">
                                                    <b-input type="text" v-model="model.codigoOperadora" maxlength="14" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <div class="column is-half-mobile">
                                            <div class="field">
                                                <label class="label">{{$t('MEDICOCADASTRO.CNES')}}</label>
                                                <div class="control">
                                                    <b-input type="text" v-model="model.cnes" maxlength="7" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <div class="column is-half-mobile">
                                            <div class="field">
                                                <label class="label">{{$t('MEDICOCADASTRO.CBOS')}}</label>
                                                <cbos :items.sync="model.medicoCbos" campoChave="id" campoValor="codigo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <ValidationProvider rules="email" name="e-mail" v-slot="{ errors, valid }">
                                            <b-field :label="$t('MEDICOCADASTRO.EMAIL')"
                                                    ref="email"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                                <b-input maxlength="50" type="email" v-model="model.email" @blur="verificarEmail"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        <div class="field">
                                            <label class="label">{{$t('MEDICOCADASTRO.OBSERVACAO')}}</label>
                                            <div class="control">
                                                <textarea class="textarea" maxlength="500" v-model="model.observacoes"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </article>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child">
                                <historico-alteracoes v-show="model.hidden" :tabela="'Medico'" :chave="model.id"></historico-alteracoes>
                                <article class="panel" v-if="config.moduloSline">
                                    <div class="panel-block">
                                        <div class="field">
                                            <b-switch v-model="model.publicaResultadosNaInternet">
                                                {{$t('MEDICOCADASTRO.PUBLICAR')}}
                                            </b-switch>
                                        </div>
                                    </div>
                                    <p v-show="publicarresultado" class="control has-icons-left">
                                        <input class="input" type="text" placeholder="Chave" v-model="model.senha">
                                        <span class="icon is-left">
                                            <i class="fas fa-key" aria-hidden="true"></i>
                                        </span>
                                    </p>
                                </article>
                            </article>
                        </div>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p v-if="model.id" class="buttons">
                            <b-button type="is-success"
                                      native-type="button"
                                      icon-left="check-circle"
                                      @click="$router.back();">
                                {{$t('MEDICOCADASTRO.VOLTAR')}}
                            </b-button>
                        </p>
                        <p v-else class="buttons">
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('MEDICOCADASTRO.SALVAR')}}
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('MEDICOCADASTRO.CANCELAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<style lang="scss">
    .full-width {
        width: 100%;
    }

    .image {
        width: 100%;
    }
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import buscaCepEndereco from '@/components/buscaCepEndereco.vue'
    import titlebar from '@/components/titlebar.vue'
    import cbos from '@/components/manutencao/cbos.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    hidden: false,
                    bairro: null,
                    cbosPadrao: null,
                    celular: null,
                    cep: null,
                    cnes: null,
                    codigoOperadora: null,
                    conselho: null,
                    conselhoInfo: null,
                    conselhoNumero: null,
                    conselhoUF: null,
                    conselhos: [],
                    cpf: null,
                    dataDeNascimento: null,
                    dataUltimaModificacao: null,
                    email: null,
                    endereco: null,
                    fone: null,
                    nome: null,
                    nomeFonetico: null,
                    obervacoes: null,
                    rg: null,
                    estados: [],
                    cidades: [],
                    possuiUsuario: null,
                    criarUsuario: false,
                    clientId: null,
                    foto: null,
                    cidadeId: null,
                    cidadeNome: null,
                    desativado: false,
                    medicoCbos: null,
                },
                carregandoHistoricoArquivos: true,
                historicoArquivos: [],
                salvandoDados: false,
                nomeSocial: false,
                fotoAlterada: false,
                usuarioEstadoInicial: false,
                retornarMedico: Boolean(this.$route.query.retornarMedico) ?? false,
                opcoesIndicacao: [
                    'Google',
                    'Facebook',
                    'Instagram',
                    'Panfleto',
                    'Revista',
                    'Solicitante',
                    'Amigo',
                    'Outros'
                ]
            };
        },
        components: {
            buscaCepEndereco,
            titlebar,
            historicoAlteracoes,
            cbos
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/portal/medico?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;
                        if(this.model.dataDeNascimento){
                            this.model.dataDeNascimento = new Date(this.model.dataDeNascimento);
                        }
                        this.model.medicoCbos = this.model.medicoCbos?.filter(i => i.codigo?.trim()?.length);
                        this.model.criarUsuario = false;
                        this.possuiUsuario = this.model.possuiUsuario;
                        this.inputsReadOnly();
                    })
            }
            document.addEventListener('visibilitychange', this.verificaRetornarMedico, false);   
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SISTEMA.SOLICITANTE'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            idade() {
                if(this.model.dataDeNascimento == null){
                    return "";
                }
                return ~~((Date.now() - this.model.dataDeNascimento) / (31557600000));
            },
            ...mapState([
                'unidadeId',
                'config',
                'usuario'
            ]),
            ...mapState('componentes', [
                'novoMedico'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            inputsReadOnly() {
                const formFields = this.$refs.formFields.querySelectorAll('input, textarea, select, button');

                formFields.forEach((field) => {
                    field.disabled = this.model.id;
                });
            },
            verificaRetornarMedico() {                         
                if(localStorage.retornarMedico > 0 && !this.retornarMedico){                    
                    this.model.id = localStorage.retornarMedico;
                    localStorage.retornarMedico = null;
                }                
            },              
            async onSubmit() {
                if(this.config.crmObrigatorioSolicitante){

                    if(this.model.conselho == null){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('MEDICOCADASTRO.PREENCHERCONSELHO'),
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }

                    if (this.model.conselhoUF == null) {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('MEDICOCADASTRO.PREENCHERCONSELHOUF'),
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }

                    if(this.model.conselhoNumero == null || this.model.conselhoNumero == 0){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('MEDICOCADASTRO.PREENCHERCONSELHONUMERO'),
                            type: 'is-danger',
                            queue: false
                        });
                        return;
                    }
                }

                this.salvandoDados = true;

                if (!this.nomeSocial && this.model.nomeSocial != null) {
                    this.model.nomeSocial = null
                }

                const newItem = Object.assign({}, this.model);
                newItem.medicoCboss = newItem.medicoCbos;

                if (newItem.cidadeId) {
                    newItem.Cidade = this.$store.state.cidades.find(c => c.id === newItem.cidadeId);
                } else {
                    newItem.Cidade = null;
                }

                if (this.model.foto && this.fotoAlterada) {
                    const dadosFotoArray = newItem.foto.split(',');
                    dadosFotoArray.shift();
                    newItem.foto = dadosFotoArray.join();
                } else {
                    newItem.foto = null;
                }

                if (!newItem.id) {
                    newItem.id = 0;
                }

                if(this.config.crmUnicoSolicitante && this.model.conselhoUF != null && this.model.conselhoNumero > 0 && 
                    this.model.conselhoNumero != null && this.model.conselho != null){
                    this.salvandoDados = true;

                    if(!this.model.id){
                        this.model.id = 0;
                    }

                    this.$http.get(`/api/portal/CheckCRM?conselhoUf=${this.model.conselhoUF}&conselhoNumero=${this.model.conselhoNumero}&conselho=${this.model.conselho}&id=${this.model.id}&convenioLogadoId=${this.usuario.convenioId}`)
                        .then(res => {
                            this.salvandoDados = false;

                            if(res.data.length == 1){
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('MEDICOCADASTRO.CRMUSO') + res.data[0].id,
                                    type: 'is-danger',
                                    queue: false
                                })
                            }
                            else if (res.data.length > 1){
                                const ids = res.data.map(item => item.id).join(', ');
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('MEDICOCADASTRO.CRMUSOSOLICITANTES') + ids,
                                    type: 'is-danger',
                                    queue: false
                                })
                            }
                            else {
                                this.criarSolicitante(newItem);
                            }
                        })
                        .catch(err => {
                            this.salvandoDados = false;
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('MEDICOCADASTRO.ERROSALVARMEDICO') + err.body.title,
                                type: 'is-danger',
                                queue: false
                            })
                        })
                }
                else {
                    this.criarSolicitante(newItem);
                }
            },
            criarSolicitante(newItem){
                this.$http.post(`/api/portal/medico?criarUsuario=${this.model.criarUsuario}`, newItem)
                    .then(res => {
                        if(this.retornarMedico) {
                            localStorage.retornarMedico = res.data.id;
                            window.close()
                        }
                        if (this.$route.params.return) {
                            this.$store.commit('componentes/SET_NOVO_MEDICO',
                                {
                                    id: res.data.id,
                                    nome: res.data.nome,
                                    cboss: res.data.cboss
                                })
                        }
                        this.$router.back()
                        this.salvandoDados = false;
                    })
                    .catch(err => {
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.salvandoDados = false;
                        if(err.body.errors.CPF[0]){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('MEDICOCADASTRO.ERROSALVARMEDICO') + err.body.errors.CPF[0],
                                type: 'is-danger',
                                queue: false
                            })    
                        }
                        else {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('MEDICOCADASTRO.ERROSALVARMEDICO') + err.body.title,
                                type: 'is-danger',
                                queue: false
                            })
                        }
                    })
            },
            verificarEmail() {
                this.$http.get(`/api/portal/VerificarUsuarioEmail?email=${this.model.email}`)
                    .then(res => {
                        if (res.data?.existe && !(res.data.tipo == 'médico' && res.data.id == this.model.id)) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('MEDICOCADASTRO.OEMAIL') + res.data.email + this.$t('MEDICOCADASTRO.EMUSO') + res.data.tipo + ' código ' + res.data.id,
                                type: 'is-danger',
                                queue: false
                            })
                            this.model.email = null
                        }
                    });
            },
            deletarUsuarioMedico(id) {
                this.$http.get(`/api/portal/DeletarUsuarioMedico?medicoId=${id}`).then(res => {
                    
                    if(res.data.ok){
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: res.data.messagem,
                            type: 'is-success',
                            queue: false
                        })                           
                        this.$router.back();
                    }else{
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: res.data.messagem,
                            type: 'is-danger',
                            queue: false
                        })
                    }                                                                                               
                })
                .catch(err => {
                    console.error(err)
                    Object.keys(err.body.errors).forEach(x => {
                        this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                    })
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('MEDICOCADASTRO.ERROSALVARMEDICO') + err.body.title,
                        type: 'is-danger',
                        queue: false
                    })
                })
            }
        }
    }
</script>