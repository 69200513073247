<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">Questionário não respondido!</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">Atenção !</h5>
                <p>Existem questionários não respondidos para o(s) exame(s) desta amostra.</p>
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field label="Exames">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in amostra.examesApelidos" :key="index">{{ exame }}</b-tag>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosIncidenciaAmostra"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosIncidenciaAmostra = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="5"
                              expanded
                              v-model="motivoIncidenciaAmostraSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosIncidenciaAmostra" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                      @click="aceitarRestricao()">Aceitar (Com restrição)</b-button>
            <b-button type="is-danger"
                      @click="rejeitar()">Não aceitar (Rejeição)</b-button>
            <b-button type="is-info"
                      @click="responderPesquisa()">Responder questionário</b-button>
            <b-button @click="$emit('close')">Cancelar</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            amostra: Object
        },
        data() {
            return {
                procurarMotivosIncidenciaAmostra: '',
                listaMotivosIncidenciaAmostra: [],
                motivoIncidenciaAmostraSelecionado: [],
            }
        },
        methods: {
            aceitarRestricao(){
                if(this.motivoIncidenciaAmostraSelecionado == null || this.motivoIncidenciaAmostraSelecionado.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message:  this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                    
                    return;
                }
                
                this.amostra.motivoIncidencia = this.motivoIncidenciaAmostraSelecionado[0]
                this.amostra.incidencia = 'R'
                this.$emit('confirmarIncidencia', this.amostra)
                this.$emit('close');
            },
            rejeitar(){
                if(this.motivoIncidenciaAmostraSelecionado == null || this.motivoIncidenciaAmostraSelecionado.length == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: 'Motivo obrigatório.',
                        type: 'is-warning'
                    })
                    
                    return;
                }

                this.amostra.motivoIncidencia = this.motivoIncidenciaAmostraSelecionado[0]
                this.amostra.incidencia = 'N'
                this.$emit('confirmarIncidencia', this.amostra)
                this.$emit('close');
            },
            responderPesquisa(){
                this.$router.push({ name: 'pesquisaclinica', 
                    params: {
                        id: this.amostra.guia,
                        amostraColeta: true
                    }
                })
                this.$emit('close');
            }
        },
        computed: {
            filteredMotivosIncidenciaAmostra() {
                return this.procurarMotivosIncidenciaAmostra
                    ? this.listaMotivosIncidenciaAmostra
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosIncidenciaAmostra.toUpperCase()) > -1)
                    : this.listaMotivosIncidenciaAmostra
            },
        },
        created() {
            this.$http.get('/api/search/MotivosIncidenciaAmostra')
                .then(m => {
                    this.listaMotivosIncidenciaAmostra = m.data
                })
        }
    }
</script>