<template>
    <section>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {},
                examesValidacao: false,
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            verificarExameValidacao() {                
                this.$http.get('api/Dashboard/PossuiExameValidacao')
                    .then(res => {
                        this.examesValidacao = res.data
                    });
            }                       
        },
        mounted() {                
            this.verificarExameValidacao();                                    

        }         
    }
</script>