<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                    {{$t('SETORESCADASTRO.SALVARSETOR')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SETORESCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="50" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-one-third">
                                    <ValidationProvider name="model.setoragrupado.id" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SETORESCADASTRO.AGRUPASETOR')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('SETORESCADASTRO.SELECIONASETOR')" class="is-fullwidth" v-model="model.setorAgrupado.id">
                                                <option value="0"></option>
                                                <option v-for="item in setores" :key="item.id" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="Departamento" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SETORESCADASTRO.DEPTO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('SETORESCADASTRO.SELEDEPTO')" class="is-fullwidth" v-model="model.departamento.id">
                                                <option v-for="(item, index) in departamentos" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-third">
                                    <ValidationProvider rules="required" name="Modelo de etiqueta" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SETORESCADASTRO.MODELETQ')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select :placeholder="$t('SISTEMA.SELECMODEL')" class="is-fullwidth" v-model="model.modeloEtiqueta">
                                                <option v-for="(item, index) in model.modelos" v-bind:key="index" :value="item">{{item}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-half">
                                    <ValidationProvider name="model.quantidadeDeExamesAmostra" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SETORESCADASTRO.QTDEEXAME')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="number" v-model="model.quantidadeDeExamesAmostra"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-quarter">
                                    <ValidationProvider name="model.cor" v-slot="{ errors, valid }">
                                        <b-field :label="$t('SETORESCADASTRO.COR')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="text" v-model="model.cor"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-quarter">
                                    <br>
                                    <input type="color" :change="corSelecionada != null ? model.cor = corSelecionada.substring(1) : null" v-model="corSelecionada">
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-tooltip :label="$t('SETORESCADASTRO.CENTRORESULTADOS')" multilined>    
                                        <b-field :label="$t('SETORESCADASTRO.CENTRORESULTADOS')">
                                            <b-select v-model="model.centroResultados.id" class="fullwidth" expanded>
                                                <option v-for="option in centrosResultados"
                                                        :value="option.id"
                                                        :key="option.id">
                                                    {{ option.registroNomes }}
                                                </option>
                                            </b-select>                                                                                      
                                        </b-field>
                                    </b-tooltip>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            {{$t('SETORESCADASTRO.INTEG')}}
                                        </div>
                                        <div class="panel-block">
                                            <table class="table is-bordered is-striped is-fullwidth">
                                                <thead>
                                                    <tr>
                                                        <th width="10%"></th>
                                                        <th width="10%">{{$t('SETORESCADASTRO.ID')}}</th>
                                                        <th width="80%">{{$t('SETORESCADASTRO.NOME')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in model.integradores" v-bind:key="index">
                                                        <td>
                                                            <b-button
                                                                type="is-danger"
                                                                native-type="button"
                                                                icon-left="delete"
                                                                class="button-delete"
                                                                @click="deletaIntegrador(index)">
                                                            </b-button>
                                                        </td>
                                                        <td>
                                                            <input class="input numerico" type="text" v-model="item.id" readonly>
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.nome" readonly>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="box">
                                            <article class="level">
                                                <div class="level-left">
                                                    <b-select :placeholder="$t('SETORESCADASTRO.SELECINTEG')" v-model="integradorId">
                                                        <option v-for="(integrador, index) in integradores"
                                                                :key="index"
                                                                :value="integrador.id">
                                                            {{ integrador.nome }}
                                                        </option>
                                                    </b-select>
                                                </div>
                                                <div class="level-right">
                                                    <button v-if="integradorId != null" type="button" class="button is-success is-fullwidth" @click="addIntegrador()">
                                                        {{$t('SETORESCADASTRO.ADDINTEG')}}
                                                    </button>
                                                </div>
                                            </article>
                                        </div>
                                    </article>
                                </div>
                                <div class="column is-half">
                                    <ValidationProvider name="model.integrador.id" v-slot="{ errors, valid }">
                                        <b-field label="Integrador"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-select :placeholder="$t('SETORESCADASTRO.SELECINTEG')" class="is-fullwidth" v-model="model.integrador.id">
                                                <option v-for="(item, index) in model.integradores" v-bind:key="index" :value="item.id">{{item.nome}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>
                    </div>  
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="$t('SETORESCADASTRO.SETOR')" :chave="model.id"></historico-alteracoes>
                            
                            <article>
                                <div class="field">
                                    <b-switch v-model="model.naoEmiteBancada">
                                        {{$t('SETORESCADASTRO.NAOBANCADA')}}
                                    </b-switch>
                                </div>
                                <div class="field">
                                    <b-switch v-model="model.emiteBancadaComRascunho">
                                        {{$t('SETORESCADASTRO.COMRASCUNHO')}}
                                    </b-switch>
                                </div>
                                <div class="field">
                                    <b-switch v-model="model.naoCriaAmostrasAutomaticamente">
                                         {{$t('SETORESCADASTRO.NAOCRIA')}}
                                    </b-switch>
                                </div>
                                <div class="field">
                                    <b-switch v-model="model.examesDeImagem">
                                        {{$t('SETORESCADASTRO.EXAMESIMAGEM')}}
                                    </b-switch>
                                </div>
                                <div class="field">
                                    <b-switch v-model="model.notificarExames">
                                        {{$t('SETORESCADASTRO.EXAMESNOTIFICAR')}}                                        
                                    </b-switch>
                                </div>
                                <div class="field">
                                    <b-switch v-model="model.aliquotaAutomatica">
                                        {{$t('SETORESCADASTRO.ALIQUOTAAUTOMATICA')}}                                        
                                    </b-switch>
                                </div>                                
                                <div class="field">
                                    <b-switch v-model="model.consulta">
                                        {{$t('SETORESCADASTRO.CONSULTA')}}
                                    </b-switch>
                                </div>
                            </article>
                        </article>
                    </div>                  
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SETORESCADASTRO.CANCELAR')}}
                            </b-button>
                            <b-button type="is-success"
                                      v-show="isInRole('setor-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SETORESCADASTRO.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'
    import Vue from 'vue'

    export default {
        components: {
            titlebar,
            historicoAlteracoes
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    naoemitebancada: null,
                    emitebancadacomrascunho: null,
                    setoragrupado: null,
                    naocriaamostrasautomaticamente: null,
                    modeloetiqueta: null,
                    departamento: null,
                    cor: null,
                    integrador: null,
                    modelos: [],
                    integradores: [],
                    centroResultados: { id: null, registroNomes: null },
                },
                setores: [],
                centrosResultados: [],
                departamentos: [],
                salvandoDados: false,
                corSelecionada: null,
                integradorId: null
            }
        },
        created() {            
            this.$http.get('/api/manutencao/setor' + ( this.$route.params.id ? '?id=' + this.$route.params.id : ''))
                .then(res => {
                    this.model = res.data;
                    this.corSelecionada = "#" + this.model.cor;
                });

            this.$http.get('/api/search/CentrosResultados')
                .then(res => {
                    this.centrosResultados = res.data;
                })

            this.$http.get('/api/search/setores')
                .then(res => {
                    this.setores = res.data;
                });

            this.$http.get('/api/search/departamentos')
                .then(res => {
                    this.departamentos = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SETORESLISTAR.MANUTENCAO'),
                    this.$t('SETORESLISTAR.SETORES'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'integradores',
                'config'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;

                this.$http.post(`/api/manutencao/setor`, this.model)
                    .then((res) => {
                        this.model.id = res.data;
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            },
            ...mapMutations('componentes', [
                'TOGGLE_MODAL_HISTORICO'
            ]),
            addIntegrador() {

                if(this.model.integradores == null){
                    this.model.integradores = [];
                }

                this.model.integradores.push({
                    id: this.integradorId,
                    nome: this.integradores.filter(item => item.id == this.integradorId)[0].nome
                });
            },
            deletaIntegrador(index){
                Vue.delete(this.model.integradores, index);
            }
        }
    }
</script>