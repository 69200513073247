<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('STATUSAMOSTRA.SALVANDOAGENDAMENTO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('STATUSAMOSTRA.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="100" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <searchIdName :id.sync="model.setorId" :label="$t('STATUSAMOSTRA.SETOR')" table="Setor"></searchIdName>
                                </div>
                            </div>
                            
                            <div class="columns">                                
                                <div class="column is-one-quarter">
                                    <b-field :label="$t('STATUSAMOSTRA.ORDEM')">
                                        <b-input icon="arrow-right-bold" type="number" max="300" v-model="model.ordem"></b-input>
                                    </b-field>
                                </div>
                                <div class="column column is-one-quarter">
                                    <ValidationProvider name="cor" v-slot="{ errors, valid }">
                                        <b-field :label="$t('STATUSAMOSTRA.COR')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input type="text" v-model="model.cor"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-one-quarter">
                                    <br>
                                    <input type="color" :change="corSelecionada != null ? model.cor = corSelecionada.substring(1) : null" v-model="corSelecionada">
                                </div>
                            </div>                                                            
                        </article>
                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('STATUSAMOSTRA.CANCELAR')}}
                            </b-button>
                            <b-button type="is-success" v-show="isInRole('amostrastatus-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('STATUSAMOSTRA.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        components: {
            titlebar,
            searchIdName
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    ordem: null,
                    cor: null,
                    setorId: null,
                },
                corSelecionada: null,
                salvandoDados: false,
            }
        },
        created() {
            this.$http.get('/api/manutencao/amostraStatus?id=' + this.$route.params.id)
                .then(res => {                        
                    this.model = res.data;

                    this.corSelecionada = "#" + this.model.cor;
                });
            
        },
        computed: {
            titleStack() {
                return [
                    this.$t('STATUSAMOSTRA.MANUTENCAO'),
                    this.$t('STATUSAMOSTRA.STATUS'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {                 
            onSubmit() {
                this.salvandoDados = true;

                if(this.model.ordem != null){
                    this.model.ordem = parseInt(this.model.ordem);
                }

                if(this.model.setorId == null) {
                    this.model.setorId = 0;
                }

                if(this.model.id == null){
                    this.model.id = 0
                }

                this.$http.post(`/api/manutencao/amostraStatus`, this.model)
                .then((res) => {
                    this.salvandoDados = false;

                  
                    this.$router.back();
                })
                .catch((error) => {
                    this.salvandoDados = false;
                    throw error
                });                      
            },
        }
    }
</script>