<template>
  <section>
    <titlebar :title-stack="titleStack"></titlebar>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="notification is-info" v-if="salvandoDados">
          <button class="delete"></button>
          {{ $t("CONSUMIVELCADASTRO.SALVANDO") }}
        </div>

        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-9">
            <article class="tile is-child box">
              <div class="columns">
                <div class="column is-half">
                  <ValidationProvider
                    rules="required"
                    name="nome"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="$t('CONSUMIVELCADASTRO.NOME')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input type="text" v-model="model.nome"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-one-third">
                  <div class="field">
                    <b-switch v-model="model.controlado">
                      {{ $t("CONSUMIVELCADASTRO.ESTOQUECONTROLADO") }}
                    </b-switch>
                  </div>
                  <div class="field">
                    <b-switch v-model="model.servico">
                      {{ $t("CONSUMIVELCADASTRO.SERVICO") }}
                    </b-switch>
                  </div>
                </div>
              </div>
              <div class="columns" v-if="!model.servico">
                <div class="column is-one-third">
                  <b-field :label="$t('CONSUMIVELCADASTRO.REGISTROMS')">
                    <b-input type="text" v-model="model.registroMS"></b-input>
                  </b-field>
                </div>
                <div class="column is-one-third">
                    <b-field v-if="model.controlado" :label="$t('CONSUMIVELCADASTRO.QUANTIDADEMINIMA')">
                        <b-input type="number" step="0.01" v-model="model.qtdeMinima"></b-input>
                    </b-field>
                </div>                

                <div class="column is-one-third">
                    <b-field
                      :label="$t('CONSUMIVELCADASTRO.CODIGODEBARRAS')">
                      <b-input
                        type="text"
                        v-model="model.codigoDeBarras"
                      ></b-input>
                    </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-one-third">
                  <b-field :label="$t('CONSUMIVELCADASTRO.CATEGORIA')">
                    <b-select
                      :placeholder="$t('CONSUMIVELCADASTRO.SELCATEGORIA')"
                      v-model="model.categoria.id"
                      expanded
                      @input="changed()">
                      <option id="-1" value="-1"> </option>
                      <option
                        v-for="option in categorias"
                        :value="option.value"
                        v-bind:key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </b-select>
                        <slot>
                            <b-button icon-left="plus-box"
                                tag="router-link"
                                :to="{ name: 'cadastrocategoria',query: { retornarCategoria:true}}"
                                target="_blank"
                                :title="$t('CONSUMIVELCADASTRO.NOVACATEGORIA')"></b-button>
                        </slot>                     
                  </b-field>
                </div>
                <div class="column is-one-third">
                  <b-field :label="$t('CONSUMIVELCADASTRO.MARCA')">
                    <b-select
                      :placeholder="$t('CONSUMIVELCADASTRO.SELMARCA')"
                      v-model="model.marca.id"
                      expanded
                      @input="changed()">
                      <option id="-1" value="-1"> </option>
                      <option
                        v-for="option in marcas"
                        :value="option.value"
                        v-bind:key="option.value">
                        {{ option.text }}
                      </option>
                    </b-select>
                        <slot>
                            <b-button icon-left="plus-box"
                                tag="router-link"
                                :to="{ name: 'cadastromarca',query: { retornarMarca:true}}"
                                target="_blank"
                                :title="$t('CONSUMIVELCADASTRO.NOVAMARCA')"></b-button>
                        </slot>                    
                  </b-field>
                </div>
                <div class="column is-one-third">
                  <b-field :label="$t('CONSUMIVELCADASTRO.UNIDADESDEMEDIDA')">
                    <b-select
                      :placeholder="$t('CONSUMIVELCADASTRO.UNIDADESDEMEDIDA')"
                      v-model="model.unidadeDeMedida.id"
                      expanded
                      @input="changed()">
                      <option id="-1" value="-1"> </option>
                      <option
                        v-for="option in unidadesDeMedida"
                        :value="option.value"
                        v-bind:key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </b-select>
                        <slot>
                            <b-button icon-left="plus-box"
                                tag="router-link"
                                :to="{ name: 'cadastrounidadedemedida', query: { retornarUnidadeMedida:true}}"
                                target="_blank"
                                :title="$t('CONSUMIVELCADASTRO.NOVAUNIDADE')"></b-button>
                        </slot>                     
                  </b-field>
                </div>                
              </div>
                <div class="panel-block column">
                    <div class="column">
                        <b-field :label="$t('CONSUMIVELCADASTRO.OBSERVACOES')">
                            <b-input v-model="model.observacoes" type="textarea" expanded/>
                        </b-field>                               
                    </div>
                </div>              
            </article>
          </div>
          <div class="tile is-parent">
              <article class="tile is-child">
                  <historico-alteracoes :tabela="'Consumivel'" :chave="model.id"></historico-alteracoes>
              </article>
          </div>          
        </div>
        <nav class="level">
          <div class="level-item">
            <p class="buttons">
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="close-circle"
                @click="$router.back()"
              >
                {{ $t("CONSUMIVELCADASTRO.CANCELAR") }}
              </b-button>
              
              <b-button
                type="is-success"
                v-show="isInRole('estoque-alterar')"
                :loading="salvandoDados"
                native-type="submit"
                icon-left="check-circle"
              >
                {{ $t("CONSUMIVELCADASTRO.SALVAR") }}
              </b-button>
            </p>
          </div>
        </nav>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
  import { mapGetters } from "vuex";
  import titlebar from "@/components/titlebar.vue";
  import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

  export default {
    components: {
      titlebar,
      historicoAlteracoes
    },
    data() {
      return {
        model: {
             categoria: {
                 id: 0,
                 nome: ''
             },
             marca: {
                 id: 0,
                 nome: ''
             },
             unidadeDeMedida: {
                 id: 0,
                 nome: ''
             },
        },
        categorias: [],
        marcas: [],
        unidadesDeMedida: [],
        salvandoDados: false,
      };
    },
    created() {
      this.model.id = this.$route.params.id;

      this.loadData();

      if (this.model.id >= 0) {
        this.$http
          .get("/api/estoque/consumivel?id=" + this.$route.params.id)
          .then((res) => {
            this.model = res.data;
          });
      }

      document.addEventListener('visibilitychange', this.verificaRetornar, false);            

    },
    computed: {
      titleStack() {
        return [
          this.$t("ESTOQUE.TITULO"),
          this.$t("CONSUMIVELCADASTRO.TITULO"),
          this.model.id ?? "novo",
        ];
      },
      ...mapGetters(["isInRole"]),
    },
    methods: {

        async loadData(){

          await this.$http.get("/api/estoque/listarConsumivelMarca").then((res) => {
            this.marcas = res.data;
          });

          await this.$http.get("/api/estoque/listarConsumivelCategoria").then((res) => {
            this.categorias = res.data;
          });

          await this.$http
            .get("/api/estoque/listarConsumivelUnidadesDeMedida")
            .then((res) => {
              this.unidadesDeMedida = res.data;
            });


        },        
        verificaRetornar() { 
          
            this.loadData();

            if(localStorage.retornarMarca > 0){                             
                this.model.marca.id = localStorage.retornarMarca;                    
                localStorage.retornarMarca = null;
            } 
            if(localStorage.retornarCategoria > 0){                    
                this.model.categoria.id = localStorage.retornarCategoria;                    
                localStorage.retornarCategoria = null;
            }    
            if(localStorage.retornarUnidadeMedida > 0){                                  
                this.model.unidadeDeMedida.id = localStorage.retornarUnidadeMedida;                    
                localStorage.retornarUnidadeMedida = null;
            }                          
                                        
        },        
      onSubmit() {
        this.salvandoDados = true;
        let nomePreenchido = true;

        if (this.model.id == null) {
          this.model.id = 0;
        }

        if (!this.model.nome) {
          nomePreenchido = false;
        }

        if (this.model.unidadeDeMedida.id > 0) {
          this.model.unidadeDeMedida.nome = this.unidadesDeMedida.filter(
            (x) => x.value == this.model.unidadeDeMedida.id
          )[0].text;
        } else {
          this.model.unidadeDeMedida.nome = "nenhum";
        }

        if (this.model.categoria.id > 0) {
          this.model.categoria.nome = this.categorias.filter(
            (x) => x.value == this.model.categoria.id
          )[0].text;
        } else {
          this.model.categoria.nome = "nenhum";
        }

        if (this.model.marca.id > 0) {
          this.model.marca.nome = this.marcas.filter(
            (x) => x.value == this.model.marca.id
          )[0].text;
        } else {
          this.model.marca.nome = "nenhum";
        }

        if (nomePreenchido) {
          this.$http
            .post(`/api/estoque/consumivel`, this.model)
            .then(() => {
              this.salvandoDados = false;
              this.$router.back();
            })
            .catch((error) => {
              this.salvandoDados = false;
              throw error;
            });
        } else {
          this.$buefy.dialog.alert({
            title: this.$t("CONSUMIVELCADASTRO.ATENCAO"),
            message: this.$t("CONSUMIVELCADASTRO.CAMPOSOBRIGATORIOS"),
            type: "is-warning",
            hasIcon: true,
          });
          this.salvandoDados = false;
          return null;
        }
      },
    },
  };
</script>
