<template>
  <section>
    <titlebar :title-stack="titleStack"></titlebar>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="notification is-info" v-if="salvandoDados">
          <button class="delete"></button>
          {{ $t("LOTECADASTRO.SALVANDO") }}
        </div>
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical is-9">
            <article class="tile is-child box">
              <div class="columns">
                <div class="column is-half">
                  <ValidationProvider
                    rules="required"
                    name="nome"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :label="$t('LOTECADASTRO.NOME')"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                      :message="errors"
                    >
                      <b-input type="text" v-model="model.nome"></b-input>
                    </b-field>
                  </ValidationProvider>
                </div>
                <div class="column is-one-third">
                  <b-field :label="$t('LOTECADASTRO.CONSUMIVEL')">
                    <b-select
                      :placeholder="$t('LOTECADASTRO.SELCONSUMIVEL')"
                      v-model="model.consumivel.id"
                      expanded
                      @input="changed()"
                    >
                      <option id="-1" value="-1"> </option>
                      <option
                        v-for="option in consumiveis"
                        :value="option.value"
                        v-bind:key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-one-third">
                  <b-field :label="$t('LOTECADASTRO.DIASAVISO')">
                    <b-input type="text" v-model="model.diasAviso"></b-input>
                  </b-field>
                </div>
                <div class="column is-one-third">
                  <b-field
                    :label="$t('LOTECADASTRO.VALIDADE')"
                  >
                    <b-datepicker
                      v-model="model.validade"
                      trap-focus
                      editable
                      :min-date="
                        new Date(
                          new Date().getFullYear() - 200,
                          new Date().getMonth(),
                          new Date().getDate()
                        )
                      "
                    ></b-datepicker>
                  </b-field>
                </div>
              </div>
            </article>
          </div>
          <div class="tile is-parent">
              <article class="tile is-child">
                  <historico-alteracoes :tabela="'Lote'" :chave="model.id"></historico-alteracoes>
              </article>
          </div>


        </div>
        
        <nav class="level">
          <div class="level-item">
            <p class="buttons">
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="close-circle"
                @click="$router.back()"
              >
                {{ $t("LOTECADASTRO.CANCELAR") }}
              </b-button>
              
              <b-button
                type="is-success"
                v-show="isInRole('estoque-alterar')"
                :loading="salvandoDados"
                native-type="submit"
                icon-left="check-circle"
              >
                {{ $t("LOTECADASTRO.SALVAR") }}
              </b-button>
            </p>
          </div>
        </nav>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
  import { mapGetters } from "vuex";
  import titlebar from "@/components/titlebar.vue";
  import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

  export default {
    components: {
      titlebar,
      historicoAlteracoes
    },
    data() {
      return {
        model: {
          consumivel: {
            id: 0,
            nome: "",
          },
        },
        consumiveis: [],
        salvandoDados: false,
      };
    },
    created() {
      this.model.id = this.$route.params.id;

      this.$http.get("/api/estoque/listarConsumiveisSelectList").then((res) => {
        this.consumiveis = res.data;
      });

      if (this.model.id >= 0) {
        this.$http
          .get("/api/estoque/lote?id=" + this.$route.params.id)
          .then((res) => {
            this.model = res.data;
            if (this.model.validade) {
              this.model.validade = new Date(
                this.model.validade
              );
            }
          });
      }
    },
    computed: {
      titleStack() {
        return [
          this.$t("ESTOQUE.TITULO"),
          this.$t("LOTECADASTRO.TITULO"),
          this.model.id ?? "novo",
        ];
      },
      ...mapGetters(["isInRole"]),
    },
    methods: {
      onSubmit() {
        this.salvandoDados = true;
        let nomePreenchido = true;

        if (this.model.id == null) {
          this.model.id = 0;
        }

        if (!this.model.nome) {
          nomePreenchido = false;
        }

        if (this.model.consumivel.id > 0) {
          this.model.consumivel.nome = this.consumiveis.filter(
            (x) => x.value == this.model.consumivel.id
          )[0].text;
        } else {
          this.model.consumivel.nome = "nenhum";
        }

        if (nomePreenchido) {
          this.$http
            .post(`/api/estoque/lote`, this.model)
            .then(() => {
              this.salvandoDados = false;
              this.$router.back();
            })
            .catch((error) => {
              this.salvandoDados = false;
              throw error;
            });
        } else {
          this.$buefy.dialog.alert({
            title: this.$t("LOTECADASTRO.ATENCAO"),
            message: this.$t("LOTECADASTRO.CAMPOSOBRIGATORIOS"),
            type: "is-warning",
            hasIcon: true,
          });
          this.salvandoDados = false;
          return null;
        }
      },
    },
  };
</script>
