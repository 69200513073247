<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-two-thirds">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('RATEIOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" maxlength="100" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>


                        <article  class="panel">
                            <div class="panel-heading has-text-centered">
                                {{$t('RATEIOCADASTRO.CENTRORESULTADO')}}
                            </div>
                            <div class="panel-block">
                                <table class="table is-bordered is-striped is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th class="is-1"></th> 
                                            <th width="10%">{{$t('CONTASPAGARCADASTRO.ID')}}</th>
                                            <th>{{$t('RATEIOCADASTRO.CENTRORESULTADO')}}</th>    
                                            <th>{{$t('RATEIOCADASTRO.PORCENTAGEM')}}</th>                                                                                                                                                                              
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in model.centrosResultados?.filter(i => i.show===true)" v-bind:key="index">
                                            <td><b-button
                                                type="is-danger"
                                                native-type="button"
                                                icon-left="delete"
                                                class="button-delete"
                                                @click ="deleteItem(item,index)" >
                                            </b-button>                                            
                                            </td>    
                                            <td>{{item.id}}</td>
                                            <td> 
                                                <b-select v-model="item.centroResultados.id" class="fullwidth" expanded>
                                                    <option v-for="option in centrosResultadosAtivos"
                                                            :value="option.id"
                                                            :key="option.id">
                                                        {{ option.registroNomes }}
                                                    </option>
                                                </b-select>                                                    
                                            </td>                                                                                        
                                            <td> <b-input v-model="item.porcentagem" type="number"  step=".00000001"></b-input>   </td>    
                                                                                                                                                                         
                                        </tr>
                                    </tbody>
                                </table>                                 
       
                            </div>     
                                <div class="panel-heading has-text-centered">
                                    <b-button
                                        type="is-success"
                                        native-type="button"
                                        icon-left="plus"
                                        class="center"
                                        @click="addItem()">
                                        {{$t('SISTEMA.INCLUIR')}}
                                    </b-button>  
                                </div>                                                     
                        </article>  
                        
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('SISTEMA.CANCELAR')}}
                            </b-button>
                            
                            <b-button type="is-success"
                                      v-show="isInRole('sorotecageladeira-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('SISTEMA.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>
<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'    

    export default {
        components: {
            titlebar                     
        },
        data() {
            return {
                model: {
                    id: null,
                    nome: null,                                       
                    centrosResultados:[]                                                
                },                                
                salvandoDados: false,
                centrosResultadosAtivos: [],               
            }
        },
        created() {

            this.$http.get('/api/search/CentrosResultados')
                .then(res => {
                    this.centrosResultadosAtivos = res.data;
                });

            if(this.$route.params.id){
                this.$http.get('/api/manutencao/rateio?id=' + this.$route.params.id)
                    .then(res => {


                        this.model = res.data;                                             
                        this.model.centrosResultados.forEach((e) => { e.show = true; });

                        this.$forceUpdate();  
                    });
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('RATEIOCADASTRO.MANUTENCAO'),
                    this.$t('RATEIOCADASTRO.RATEIOS'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {

            addItem() {
                
                if(this.model.centrosResultados == null )
                    this.model.centrosResultados = [];

                this.model.centrosResultados.push({
                    id:0,
                    centroResultados:{ id:0 },                    
                    porcentagem:0.0,
                    show:true                  
                });

                this.$forceUpdate();  
            },              
            deleteItem(item,index) {
                if(item.id==0){
                    this.model.centrosResultados.splice(index, 1);
                }else{
                    item.show = false; 
                }
                
                this.$forceUpdate();                
            },                              
                                                
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }

                    
                this.$http.post(`/api/manutencao/rateio`, this.model)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });
            }
        }
    }
</script>