<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-message
            v-show="model.repasseEmUso"
            type="is-info"
            >
            {{ $t('REPASSEMEDICOCADASTRO.EMUSO') }}
        </b-message>

        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-9">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column">
                            <ValidationProvider rules="required" :name="$t('REPASSEMEDICOCADASTRO.NOME')" v-slot="{ errors, valid }">
                                <b-field :label="$t('REPASSEMEDICOCADASTRO.NOME')"
                                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                         :message="errors">
                                    <b-input :disabled="model.repasseEmUso" type="text" v-model="model.nome" maxlength="100" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <ValidationProvider rules="required" :name="$t('REPASSEMEDICOCADASTRO.VALORMINIMO')" v-slot="{ errors, valid }">
                                <b-field :label="$t('REPASSEMEDICOCADASTRO.VALORMINIMO')"
                                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                         :message="errors">
                                    <b-input :disabled="model.repasseEmUso" type="number" v-model="model.valorMinimo" :has-counter="true"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>
                        <div class="column">
                            <b-field :label="$t('REPASSEMEDICOCADASTRO.PORCENTAGEM')">
                                <b-input :disabled="model.repasseEmUso" type="number" v-model="model.porcentagem" :has-counter="true" icon-right="percent"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('REPASSEMEDICOCADASTRO.IMPOSTOS')">
                                <b-input :disabled="model.repasseEmUso" type="number" v-model="model.impostos" :has-counter="true" icon-right="percent"></b-input>
                            </b-field>
                        </div>
                    </div>

                    <div class="column is-12">
                        <searchIdName :leitura="model.repasseEmUso" :id.sync="model.funcionario.id" :nome.sync="model.funcionario.nome" table="Funcionario"
                                      :label="$t('REPASSEMEDICOCADASTRO.FUNCIONARIO')" />
                    </div>

                    <div class="column is-12">
                        <searchIdName :leitura="model.repasseEmUso" :id.sync="model.convenio.id" table="Convenio"
                                      :label="$t('REPASSEMEDICOCADASTRO.CONVENIO')" />
                    </div>

                    <div class="column is-12">
                        <searchIdName :leitura="model.repasseEmUso" :id.sync="model.unidade.id" table="Unidade"
                                      :label="$t('REPASSEMEDICOCADASTRO.UNIDADE')" />
                    </div>

                    <div class="column is-12">
                        <searchIdName :leitura="model.repasseEmUso" :id.sync="model.setor.id" table="Setor"
                                      :label="$t('REPASSEMEDICOCADASTRO.SETOR')" />
                    </div>

                    <div class="column is-12" >
                        <searchIdName :leitura="model.repasseEmUso" :id.sync="model.tabelaPreco.id" table="TabelaPreco"
                                      :label="$t('REPASSEMEDICOCADASTRO.TABELADEEXAMES')" />
                    </div>
                </article>
            </div>

            <div class="tile is-parent">
                <article class="tile is-child">
                    <article class="panel">
                        <div class="panel-heading">
                            <div class="field">
                                <b-switch :disabled="model.repasseEmUso" v-model="model.consideraDescontoGuia">
                                    {{$t('REPASSEMEDICOCADASTRO.DESCONTOGUIA')}}
                                </b-switch>
                            </div>
                             <div class="field">
                                <b-switch type="is-danger" v-model="model.desativado">
                                    {{$t('REPASSEMEDICOCADASTRO.DESATIVADO')}}
                                </b-switch>
                            </div>
                        </div>
                    </article>
                </article>
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                         {{$t('EXAMECADASTRO.CANCELAR')}}
                    </b-button>
                    <b-button type="is-success"
                                v-if="isInRole('repassemedico-alterar')"
                                native-type="button"
                                icon-left="check-circle"
                                :loading="loading"
                                @click="onSubmit()"
                    >
                        {{$t('EXAMECADASTRO.SALVAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue';

    export default {
        data() {
            return {
                model: {
                    nome: null,
                    valorMinimo: null,
                    porcentagem: null,
                    impostos: null,
                    funcionario: {
                        id: null,
                        nome: null,
                    },
                    convenio: {
                        id: null
                    },
                    unidade: {
                        id: null
                    },
                    setor: {
                        id: null
                    },
                    tabelaPreco: {
                        id: null
                    }
                },
                loading: false
            }
        },
        components: {
            titlebar,
            searchIdName
        },
        created() {
            this.$http.get('/api/manutencao/repassemedico?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data;

                    if(!this.model.convenio){
                        this.model.convenio = {
                            id: null
                        }
                    }
                    
                    if(!this.model.unidade){
                        this.model.unidade = {
                            id: null
                        }
                    }
                    
                    if(!this.model.setor){
                        this.model.setor = {
                            id: null
                        }
                    }
                    
                    if(!this.model.tabelaPreco){
                        this.model.tabelaPreco = {
                            id: null
                        }
                    }

                });
        },
        computed: {
            titleStack() {
                return [
                    this.$t('REPASSEMEDICOCADASTRO.MANUTENCAO'),
                    this.$t('REPASSEMEDICOCADASTRO.REPASSEMEDICO'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.loading = true;

                if(!this.model.nome){
                    this.$buefy.toast.open({
                        duration: 3000,
                        message: this.$t('REPASSEMEDICOCADASTRO.INSIRANOMEREPASSE'),
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }
                
                else if(!this.model.valorMinimo){
                    this.$buefy.toast.open({
                        message: this.$t('REPASSEMEDICOCADASTRO.INSIRAVALORMINIMO'),
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }
                
                else if(!this.model.funcionario.id){
                    this.$buefy.toast.open({
                        message: this.$t('REPASSEMEDICOCADASTRO.INSIRAIDMEDICO'),
                        type: 'is-warning'
                    })
                    this.loading = false;
                    
                    return null;
                }
                
                if(!this.model.convenio.id)
                    delete this.model.convenio
                if(!this.model.unidade.id)
                    delete this.model.unidade
                if(!this.model.setor.id)
                    delete this.model.setor
                if(!this.model.tabelaPreco.id)
                    delete this.model.tabelaPreco

                this.$http.post('/api/manutencao/RepasseMedico', this.model)
                    .then(() => {
                        this.$route.params.id ? this.$router.back() : this.$router.push({ name: 'repassesmedicos' })
                        this.loading = false;
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('REPASSEMEDICOCADASTRO.ERROSALVAR') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                        
                        this.loading = false;
                    })
            }
        }
    }
</script>