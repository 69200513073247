<template>
    <section>
        <titlebar :title-stack="titleStack">
           
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('DEPTOCADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="100" :has-counter="true"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>                            
                            </div>
                        </article>
                    </div>   
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('DEPTOCADASTRO.FATURA')}}
                                </div>
                                <div v-if="isInRole('usuarioweb-criar')" class="panel-block">
                                    <div class="field">
                                        <b-switch v-model="model.faturaSeparado">
                                            {{$t('DEPTOCADASTRO.CRIAFATURA')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </article>
                        </article>
                    </div>                                     
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('DEPTOCADASTRO.CANCELAR')}}
                            </b-button>
                            <b-button type="is-success"
                                      v-show="isInRole('departamento-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('DEPTOCADASTRO.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'    
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,           
                    faturaSeparado: false
                },

                salvandoDados: false,
            };
        },
        components: {
            titlebar,
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/manutencao/departamento?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;                      
                    })

            }
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Departamento',
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },          
            ...mapState([
                'unidadeId',
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            onSubmit() {
                const newItem = Object.assign({}, this.model);
                if (!this.$route.params.id){
                    delete newItem.id;
                }

                this.$http.post('/api/manutencao/departamento', newItem)
                    .then(() => {
                        this.$router.back()
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar conservante: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },                   
        }
    }
</script>