<template>
    <div v-if="this.$props.parcela != null">
        <b-button type.native="button" type="is-primary" icon-left="clock" size="is-small"
                @click="buscarDadosParcela()" title="Dados"></b-button>
        <b-modal :active.sync="exibirModal" has-modal-card trap-focus aria-role="dialog" aria-modal>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Dados - Parcela <b>{{this.$props.parcela.id}}</b></p>
                </header>
                <section class="modal-card-body">
                    <div class="panel">
                        <div class="panel-block is-justified-between" v-for="(item, index) in this.$props.parcela.dados" v-bind:key="index">
                            <h4>{{item.destinoMotivo}}</h4>
                            <span><b>{{ item.motivoId }}</b> {{item.motivoDescricaoInterno}}</span>
                            <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                            <span v-if="item.dados">{{ item.dados }}</span>
                        </div>
                        <table class="table is-fullwidth">
                            <tr v-for="(item, index) in lista" v-bind:key="index">
                                <div v-if="(item.acao == 94 && item.descricao.includes(parcela.id)) || item.acao == 84 && parcela.situacao !== 2 && parcela.situacao !== 3 || item.acao == 253">
                                    <td>
                                        <h4>{{item.descricao.replace('antes:', '')}}</h4>
                                    </td>
                                    <td>
                                        <span><b>{{ item.usuarioNome }}</b> ({{ item.usuarioId }})</span>
                                    </td>
                                    <td>
                                        <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                                    </td>
                                </div>
                            </tr>
                        </table>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-pulled-right" type="button" @click="exibirModal = false">Fechar</button>
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
    export default {
        props: {
            tabela: String,
            chave: Number,
            parcela: Object
        },
        data() {
            return {
                exibirModal: false,
                lista: [],
            }
        },
        methods: {
            buscarDadosParcela(){
                //ajax para buscar os dados
                this.$http.get(`/api/financeiro/dadosparcela?chave=${this.$props.parcela.id}`).then(res => {
                    this.$props.parcela.dados = res.data;
                    //modal exibindo o resultado
                    this.exibirModal = true;
                });

                if(this.$props.tabela != null && this.$props.chave != null){
                    this.$http.get('/api/manutencao/log?tabela=' + this.$props.tabela + "&chave=" + this.$props.chave)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null && data.length > 0) {
                                this.lista = data;                     
                            }
                        });
                }

            }
        }
    }
</script>