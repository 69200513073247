<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <div class="columns">
                    <div class="column">
                        <div class="panel">
                            <p class="panel-tabs">
                                <a :class="{ 'is-active': searchFields.dataFiltro == 'competencia' }"
                                    @click="searchFields.dataFiltro = 'competencia'">{{ $t('CONTASPAGARLISTAR.COMPETENCIA') }}</a>
                                <a :class="{ 'is-active': searchFields.dataFiltro == 'vencimento' }"
                                    @click="searchFields.dataFiltro = 'vencimento'">{{ $t('CONTASPAGARLISTAR.VENCIMENTO') }}</a>
                                <a :class="{ 'is-active': searchFields.dataFiltro == 'pagamento' }"
                                    @click="searchFields.dataFiltro = 'pagamento'">{{ $t('CONTASPAGARLISTAR.PAGAMENTO') }}</a>
                            </p>
                            <div class="panel-block">
                                <periodoHora :dataInicial.sync="searchFields.datas[0]"
                                    :dataFinal.sync="searchFields.datas[1]"
                                    :placeHolder="$t('CONTASPAGARLISTAR.SELECIONEDATA')" @limparDatas="limpaDatas" />
                            </div>
                        </div>
                    </div>
                    <div class="column is-3">
                        <form @submit.prevent="abrirCadastroInput">
                            <b-field :label="$t('CONTASPAGARLISTAR.ID')">
                                <b-input placeholder="Código" v-model="id" type="text" icon-right="edit"
                                    icon-right-clickable @icon-right-click="abrirCadastroInput">
                                </b-input>
                            </b-field>
                        </form>
                    </div>
                </div>
                <div>
                    <searchIdName :id.sync="searchFields.unidadeId" table="Unidade"
                        :label="$t('CONTASPAGARLISTAR.UNIDADE')" />
                </div>
                <div>
                    <searchIdName :id.sync="searchFields.categoriaFinanceiraId"
                        :label="$t('CONTASPAGARLISTAR.CATEGORIAFINANCEIRA')" table="CategoriasFinanceirasDespesas" />
                </div>
            </div>
            <div class="column is-5">
                <div class="is-flex is-flex-direction-column is-justify-content-flex-end">

                    <div class="columns p-0 is-flex is-align-items-flex-start" style="width: 100%;">
                        <div class="column is-7">
                            <b-field :label="$t('CONTASPAGARLISTAR.DESCRICAO')">
                                <b-input :placeholder="$t('CONTASPAGARLISTAR.DESCRICAO')" v-model="procurar"
                                    icon="magnify"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-5">
                            <b-field :label="$t('CONTASPAGARLISTAR.STATUS')">
                                <b-select v-model="searchFields.statusFiltro" expanded>
                                    <option :value="null">{{ $t('CONTASPAGARLISTAR.TODOS') }}</option>
                                    <option :value="10">{{ $t('CONTASPAGARLISTAR.RECEBIMENTOSATRASADOS') }}</option>
                                    <option :value="9">{{ $t('CONTASPAGARLISTAR.RECEBIMENTOSPENDENTES') }}</option>
                                    <option :value="8">{{ $t('CONTASPAGARLISTAR.RECEBIMENTOSLIQUIDADOS') }}</option>
                                    <option :value="3">{{ $t('CONTASPAGARLISTAR.RECEBIMENTOSCANCELADOS') }}</option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>

                    <div style="width: 100%;">
                        <searchIdName class="is-fullwidth" :id.sync="searchFields.fornecedorId" table="fornecedor"
                            :label="$t('SISTEMA.FORNECEDOR')" />
                    </div>
                    <div style="width: 100%;">
                        <searchIdName class="is-fullwidth" :id.sync="searchFields.funcionarioId" table="funcionario"
                            :label="$t('CONTASPAGARLISTAR.FUNCIONARIO')" />
                    </div>
                </div>
            </div>
            <div class="column">
                <div class=" is-justified-between is-justify-content-flex-end is-align-items-flex-end">
                    <div class="is-flex-grow-1 mb-2">
                        <b-button type="is-primary" icon-left="magnify"
                            @click="ehPesquisa = true; loadAsyncData()">Pesquisar</b-button>
                    </div>
                    <div>
                        <router-link v-if="isInRole('pagar-alterar')" :to="{ name: 'contaspagar' }"
                            class="button is-success">
                            {{ $t('CONTASPAGARLISTAR.INCLUIR') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <b-table :data="model.lista" :loading="loading" paginated striped hoverable backend-pagination :total="total"
            @page-change="onPageChange" :aria-next-label="$t('CONTASPAGARLISTAR.PROXPAGINA')"
            :aria-previous-label="$t('CONTASPAGARLISTAR.PAGANTERIOR')" :aria-page-label="$t('CONTASPAGARLISTAR.PAGINA')"
            :aria-current-label="$t('CONTASPAGARLISTAR.PAGATUAL')" backend-sorting
            :default-sort-direction="defaultOrdenar" :default-sort="[ordenarPor, ordenar]" sort-icon-size="is-small"
            @sort="onSort" class="table">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column v-slot="props" field="id" :label="$t('CONTASPAGARLISTAR.ID')" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column v-slot="props" field="Data" :label="$t('CONTASPAGARLISTAR.COMPETENCIA')" sortable>
                    <div style="width: 7rem;"> {{ $moment(props.row.dataDeCompetencia).format("DD/MM/YYYY") }} </div>
                </b-table-column>
                <b-table-column v-slot="props" field="Descricao" :label="$t('CONTASPAGARLISTAR.DESCRICAO')" sortable>
                    {{ props.row.descricao }}
                </b-table-column>
                <b-table-column v-slot="props" field="Pedido" :label="$t('CONTASPAGARLISTAR.PEDIDO')">
                    {{ props.row.pedido.descricao }}
                </b-table-column>
                <b-table-column v-slot="props" field="Fornecedor" :label="$t('CONTASPAGARLISTAR.FORNECEDOR')">
                    {{ props.row.fornecedor.nome }}
                </b-table-column>
                <b-table-column v-slot="props" field="Categoria financeira"
                    :label="$t('CONTASPAGARLISTAR.CATEGORIAFINANCEIRA')">
                    {{ props.row.categoriaFinanceira.nome }}
                </b-table-column>
                <b-table-column v-slot="props" field="Vencimento" :label="$t('CONTASPAGARLISTAR.VENCIMENTO')" sortable>
                    <span v-if="props.row.dataDeVencimento">
                        {{ $moment(props.row.dataDeVencimento).format("DD/MM/YYYY") }}
                    </span>
                </b-table-column>
                <b-table-column v-slot="props" field="Valor" :label="$t('CONTASPAGARLISTAR.VALOR')">
                    {{ props.row.valor | currency }}
                </b-table-column>
                <b-table-column v-slot="props" field="Parcela" :label="$t('CONTASPAGARLISTAR.PARCELAS')">
                    {{ props.row.qdteParcelaPaga }} / {{ props.row.qdteParcela }}
                </b-table-column>
                <b-table-column v-slot="props" field="Cancelada" label="">
                    <b-icon v-if="props.row.cancelada" type="is-danger" icon="close-circle" title="Cancelada"></b-icon>
                </b-table-column>

                <b-table-column v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'contaspagar', params: { id: props.row.id } }"
                        class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<style scoped>
    .table {
        overflow: auto;
        table-layout: fixed; 
    }
</style>

<script>
import { mapGetters } from 'vuex'
import titlebar from '@/components/titlebar.vue'
import periodoHora from '@/components/periodoHora.vue'
import searchIdName from "@/components/searchIdName.vue"

export default {
    data() {
        return {
            model: {
                parcelas: [],
            },
            total: 0,
            loading: false,
            procurar: this.$route.query.procurar ?? '',
            ordenarPor: 'Data',
            ordenar: 'desc',
            defaultOrdenar: 'desc',
            page: 1,
            perPage: 20,
            selecionado: null,
            id: null,
            searchFields: {
                unidadeId: null,
                fornecedorId: null,
                funcionarioId: null,
                categoriaFinanceiraId: null,
                dataFiltro: 'competencia',
                datas: [
                    null,
                    null
                ],
                statusFiltro: null
            },
            ehPesquisa: false
        }
    },
    components: {
        titlebar,
        searchIdName,
        periodoHora,
    },
    computed: {
        titleStack() {
            return [
                this.$t('SIDEBAR.FINANCEIRO'),
                this.$t('CONTASPAGARLISTAR.TITULO')
            ]
        },
        ...mapGetters([
            'isInRole'
        ])
    },
    methods: {
        loadAsyncData() {
            const params = [
                `procurar=${this.procurar}`,
                `ordem=${this.ordenarPor}.${this.ordenar}`,
                `page=${this.page}`
            ]

            if (this.id) params.push(`Id=${this.id}`);
            if (this.searchFields.fornecedorId) params.push(`fornecedorId=${this.searchFields.fornecedorId}`);
            if (this.searchFields.funcionarioId) params.push(`funcionarioId=${this.searchFields.funcionarioId}`);
            if (this.searchFields.categoriaFinanceiraId) params.push(`categoriaFinanceiraId=${this.searchFields.categoriaFinanceiraId}`);
            if (this.searchFields.dataFiltro) params.push(`dataFiltro=${this.searchFields.dataFiltro}`);
            if (this.searchFields.datas[0]) params.push(`dataInicial=${this.$moment(this.searchFields.datas[0]).format('YYYY-MM-DDTHH:mm:ss')}`);
            if (this.searchFields.datas[1]) params.push(`dataFinal=${this.$moment(this.searchFields.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`);
            if (this.searchFields.statusFiltro) params.push(`statusFiltro=${this.searchFields.statusFiltro}`);
            if (this.searchFields.unidadeId) params.push(`buscaUnidadeId=${this.searchFields.unidadeId}`);
            params.push(`ehPesquisa=${this.ehPesquisa}`);

            this.loading = true
            this.$http.get(`/api/financeiro/listarpagar?${params.join('&')}`)
                .then(({ data }) => {
                    this.model = data;
                    if (data && data.lista && data.lista.length == 0 && this.page > 1) {
                        this.page = 1
                        this.loadAsyncData()
                    }
                    this.total = this.perPage * data.pageCount
                    this.loading = false
                    this.$router.replace({ query: { procurar: this.procurar, page: this.page } })
                })
                .catch((error) => {
                    this.model = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        onSort(field, order) {
            this.ordenarPor = field
            this.ordenar = order
            this.loadAsyncData()
        },
        abrirCadastro() {
            this.$router.push({ path: `/financeiro/pagar/${this.selecionado.id}` })
        },
        abrirCadastroInput() {
            this.$router.push({ path: `/financeiro/pagar/${this.id}` })
        },
        limpaDatas(event) {
            this.searchFields.datas[0] = event.dataInicial;
            this.searchFields.datas[1] = event.dataFinal;
        }
    },
    mounted() {
        this.loadAsyncData()
    }
}
</script>