<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <filtros :tipo="'RepasseMedico'" :habilitarBotaoPdf="true" @filtrar="filtrar($event)"></filtros>
        <div v-if="isLoading">
            <b-loading active></b-loading>
        </div>
        <div v-else>       
            <div class="box is-hero-bar is-main-hero" v-if="medicos != null">     
                <nav class="panel" v-for="(medico, index) in medicos" :key="index">
                    <p class="panel-heading background-1">
                        <nav class="level">
                            <div class="level-left">
                                <div class="level-item">
                                    <router-link :title="$t('REPASSEMEDICOFINANCEIRO.MEDICO')" :to="{ name: 'medico', params: { id: medico[0].medicoId }}">
                                        {{ medico[0].medicoNome }}
                                    </router-link>
                                </div>
                            </div>
                            <div class="level-right">
                                <div class="level-item">
                                    <b-checkbox v-model="medico.selecionado" @click.prevent.native="registrarRepasseMedico(medico)"></b-checkbox>
                                </div>
                            </div>
                        </nav>
                    </p>
                    <div class="panel-block columns is-multiline is-mobile">
                        <nav class="panel column is-12" v-for="(convenio, index) in medico.convenios" :key="index">
                            <p class="panel-heading background-2">
                                <router-link :title="$t('REPASSEMEDICOFINANCEIRO.CONVENIO')" :to="{ name: 'convenio', params: { id: convenio[0].convenioId }}">
                                    {{ convenio[0].convenioNome }}
                                </router-link>
                            </p>
                            <div class="panel-block columns is-multiline is-mobile">
                                <nav class="panel column is-12" v-for="(guia, index) in convenio.guias" :key="index">
                                    <p class="panel-heading">
                                        <router-link :title="$t('REPASSEMEDICOFINANCEIRO.GUIA')" :to="{ name: 'guia', params: { id: guia[0].guiaId }}">
                                            {{ guia[0].guiaId }}
                                        </router-link>
                                    </p>
                                    <div v-for="(exame, index) in guia" :key="index" class="panel-block columns is-multiline is-mobile">
                                        <div class="column is-1 is-half-mobile">
                                            <small>{{$t('REPASSEMEDICOFINANCEIRO.EXAME')}}:</small>
                                            <br>
                                            <b>{{exame.exameApelido}}</b>
                                        </div>
                                        <div class="column is-2 is-half-mobile">
                                            <small>{{$t('REPASSEMEDICOFINANCEIRO.DATASOLICITACAO')}}:</small>
                                            <br>
                                            <b>{{$moment(exame.dataDeSolicitacao).format("DD/MM/YYYY HH:mm")}}</b>
                                        </div>
                                        <div class="column is-2 is-half-mobile">
                                            <small>{{$t('REPASSEMEDICOFINANCEIRO.DATAASSINATURA')}}:</small>
                                            <br>
                                            <b v-if="exame.dataDeAssinatura">{{$moment(exame.dataDeAssinatura).format("DD/MM/YYYY HH:mm")}}</b>
                                            <b v-else></b>
                                        </div>
                                        <div class="column is-1 is-half-mobile">
                                            <small v-if="exame.precoExame != null">{{$t('REPASSEMEDICOFINANCEIRO.VALORPROCEDIMENTO')}}:</small>
                                            <br>
                                            <b v-if="exame.precoExame != null">{{exame.precoExame | currency}}</b>
                                        </div>
                                        <div class="column is-1 is-half-mobile">
                                            <small>{{$t('REPASSEMEDICOFINANCEIRO.VALORREPASSE')}}:</small>
                                            <br>
                                            <b>{{exame.valorRepasseCalculado | currency}}</b>
                                        </div>
                                        <div class="column is-1 is-half-mobile">
                                            <small>{{$t('REPASSEMEDICOFINANCEIRO.VALORDESCONTO')}}:</small>
                                            <br>
                                            <b>{{exame.valorDesconto | currency}}</b>
                                        </div>
                                        <div v-if="exame.valorDescontoPontualidade != null" class="column is-1 is-half-mobile">
                                            <small>{{$t('REPASSEMEDICOFINANCEIRO.VALORDESCONTOPONTUALIDADE')}}:</small>
                                            <br>
                                            <b>{{exame.valorDescontoPontualidade | currency}}</b>
                                        </div>
                                        <div class="column is-1 is-half-mobile">
                                            <small>{{$t('REPASSEMEDICOFINANCEIRO.VALORIMPOSTO')}}:</small>
                                            <br>
                                            <b>{{exame.valorImposto | currency}}</b>
                                        </div>
                                        <div class="column is-1 is-half-mobile">
                                            <small>{{$t('REPASSEMEDICOFINANCEIRO.REPASSEPAGO')}}:</small>
                                            <br>
                                            <b-switch v-model="exame.repasseAplicado" disabled></b-switch>
                                        </div>
                                        <div class="column is-1 is-half-mobile" v-if="!exame.repasseAplicado">
                                            <b-checkbox v-model="exame.registrarRepasse">
                                            </b-checkbox>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </nav>
                    </div>
                    <div class="panel-block columns is-multiline is-mobile">
                        <div class="column is-7 is-half-mobile">
                            {{$t('REPASSEMEDICOFINANCEIRO.TOTAL')}} {{$t('REPASSEMEDICOFINANCEIRO.MEDICO')}}
                        </div>
                        <div class="column is-2 is-half-mobile">
                            <small>{{$t('REPASSEMEDICOFINANCEIRO.VALORREPASSE')}}:</small>
                            <br>
                            <b>{{calculateSum(Object.values(medico), 'valorRepasseCalculado') | currency}}</b>
                        </div>
                        <div class="column is-1 is-half-mobile">
                            <small>{{$t('REPASSEMEDICOFINANCEIRO.VALORIMPOSTO')}}:</small>
                            <br>
                            <b>{{calculateSum(Object.values(medico), 'valorImposto') | currency}}</b>
                        </div>                                        
                    </div>
                </nav>
                
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('REPASSEMEDICOFINANCEIRO.CANCELAR')}}
                            </b-button>
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="button"
                                      icon-left="check-circle"
                                      @click="registrarRepasses()">
                                {{$t('REPASSEMEDICOFINANCEIRO.REGISTRARREPASSE')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import filtros from '@/components/financeiro/filtrosAcompanhamento.vue';

    export default {
        data() {
            return {
                isLoading: false,
                salvandoDados: false,
                medicos: null
            }
        },
        components: {
            titlebar,
            filtros
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    'Repasse médico'
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },        
        methods: {
            filtrar(filtros){
                const params = [
                    `dataFiltro=${filtros.dataFiltro}`,
                    `filtrarPor=${filtros.filtrarPor}`,
                    `dataInicial=${this.$moment(filtros.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(filtros.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`,
                    `ocultarValorBruto=${filtros.ocultarValorBruto}`,
                    `exportarPdf=${filtros.exportarPdf}`
                ]
                
                if(filtros.funcionario?.id != null)
                {
                    params.push(`funcionarioId=${filtros.funcionario.id}`)
                }
                if(filtros.tipoConvenio?.id != null)
                {
                    params.push(`tipoConvenioId=${filtros.tipoConvenio.id}`)
                }
                if(filtros.medico?.id != null)
                {
                    params.push(`medicoId=${filtros.medico.id}`)
                }
                if(filtros.unidade?.id != null)
                {
                    params.push(`unidadeId=${filtros.unidade.id}`)
                }
                if(filtros.convenio?.id != null)
                {
                    params.push(`convenioId=${filtros.convenio.id}`)
                }
                if(filtros.setor?.id != null)
                {
                    params.push(`setorId=${filtros.setor.id}`)
                }
                if(filtros.exame?.id != null)
                {
                    params.push(`exameId=${filtros.exame.id}`)
                }

                if(filtros.exportarPdf){
                    this.abrirPdf(params);
                }else{
                    this.carregarLista(params);
                }
                

            },
            abrirPdf(params){                
                const url = `/api/financeiro/RepassesMedicos?${params.join('&')}`;
                window.open(url, "_blank");
            },
            carregarLista(params){
                this.isLoading = true;
                this.$http.get(`/api/financeiro/RepassesMedicos?${params.join('&')}`)
                    .then(res => {
                        this.medicos = this.agruparMedicos(res.data);
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        throw error
                    });
            },
            agruparMedicos(lista){
                const medicosAgrupados = this.groupBy(lista, 'medicoId');
                Object.values(medicosAgrupados).forEach(elementMedico => {
                    const conveniosAgrupados = this.groupBy(elementMedico, 'convenioId');
                    Object.values(conveniosAgrupados).forEach(elementConvenio => {
                        const guiasAgrupadas = this.groupBy(elementConvenio, 'guiaId');
                        elementConvenio.guias = guiasAgrupadas;
                    });                            
                    elementMedico.convenios = conveniosAgrupados;
                });

                return medicosAgrupados;
            },
            calculateSum(array, property) {
                const total = array.reduce((accumulator, object) => {
                    if(object[property] != null){
                        return accumulator + object[property];
                    }
                    else {
                        return accumulator;
                    }
                }, 0);
                return total;
            },
            groupBy(array, key) {
                return array.reduce((acc, item) => ({
                    ...acc,
                    [item[key]]: [...(acc[item[key]] ?? []), item],
                    }),
                {})
            },
            registrarRepasseMedico(medico){
                medico.selecionado = !medico.selecionado

                Object.values(medico).forEach(elementMedico => {
                    if(elementMedico.registrarRepasse != null){
                        elementMedico.registrarRepasse = medico.selecionado;
                    }
                });
            },
            registrarRepasses(){
                const registrarRepassesMedicos = [];
                const repasses = [];
                Object.values(this.medicos).forEach(medico => {
                    Object.values(medico.convenios).forEach(convenio => {
                        Object.values(convenio.guias).forEach(guia => {
                            Object.values(guia).forEach(exame => {
                                if(exame.registrarRepasse){
                                    registrarRepassesMedicos.push({
                                        guiaId: exame.guiaId,
                                        item: exame.item,
                                        id: exame.repasseMedico.id,
                                    });

                                    repasses.push(exame);
                                }
                            })
                        })
                    })
                });

                this.salvandoDados = true;
                this.$http.post(`/api/financeiro/RegistrarRepassesMedicos`, registrarRepassesMedicos)
                    .then(() => {
                        this.salvandoDados = false;                        
                        Object.values(repasses).forEach(element => {
                            element.repasseAplicado = true;
                        });                        

                        this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('REPASSEMEDICOFINANCEIRO.SUCESSO'),
                                type: 'is-info',
                                queue: false
                        });
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: error.body,
                            type: 'is-danger',
                            queue: false
                        });
                        throw error
                    }); 
            }
        }
    }
</script>