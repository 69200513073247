import { Doughnut } from 'vue-chartjs'
import Chart from 'chart.js'

Chart.plugins.register({
    beforeDraw: function(chart) {
        if (chart.config.options.elements.center) {
            // Pega o contexto (ctx) da string
            const ctx = chart.chart.ctx;

            // Carrega as opções que foram passadas
            const centerConfig = chart.config.options.elements.center;
            const fontStyle = centerConfig.fontStyle || 'Arial';
            const txt = centerConfig.text;
            const color = centerConfig.color || '#000';
            const maxFontSize = centerConfig.maxFontSize || 20;
            const sidePadding = centerConfig.sidePadding || 20;
            const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            
            let minFontSize = centerConfig.minFontSize;
            
            if (minFontSize === undefined) {
                minFontSize = 20;
            }
            
            ctx.font = `${minFontSize}px ` + fontStyle;

            // pega o width da string que será colocada no meio do grafico e o width do gráfico
            const stringWidth = ctx.measureText(txt).width;
            const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

            // Calcula o tamanho da fonte em relação ao gráfico
            const widthRatio = elementWidth / stringWidth;
            const newFontSize = Math.floor(minFontSize * widthRatio);
            const elementHeight = (chart.innerRadius * 2);

            // Pega qual o tamanho de fonte que será usada e ela nunca deve ser maior que o minFontSize
            let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
            const lineHeight = centerConfig.lineHeight || 25;

            if (fontSizeToUse < minFontSize) {
                fontSizeToUse = minFontSize;
            }

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            const eixoYMiddle = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            let centerY = eixoYMiddle + Math.round(eixoYMiddle * 0.16);
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            const words = txt.split(' ');
            const firstWord = words.shift();
            let line = '';
            const lines = [firstWord];

            // Quebra as palavras em multiplas linhas se necessário
            for (let wl = 0; wl < words.length; wl++) {
                const testLine = line + words[wl] + ' ';
                const testWidth = ctx.measureText(testLine).width;
                if (testWidth > elementWidth && wl > 0) {
                    lines.push(line);
                    line = words[wl] + ' ';
                } else {
                    line = testLine;
                }
            }

            lines.push(line);

            // Move para o centro dependendo do numero de linhas e do tamanho da linha
            centerY -= (lines.length / 2) * lineHeight;

            // Plota as palavras no centro do gráfico
            for (let ll = 0; ll < lines.length; ll++) {
                ctx.fillText(lines[ll], centerX, centerY);
                centerY += lineHeight;
            }
        }
    }
});

export default {
    name: 'doughnut-chart-complete',
    extends: Doughnut,
    props: ['chartData', 'chartId', 'centerText'],
    mounted() {
        if (this.chartData == undefined)
            return;

        this.renderChart({
            labels: this.chartData.map((item) => { return item.setor }),
            datasets: [{
                data: this.chartData.map((item) => { return item.qtde }),
                backgroundColor: this.chartData.map((item) => { return item.cor })
            }]
        }, {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            animation: {
                animateScale: true,
                animateRotate: true
            },
            cutoutPercentage: 60,
            elements: {
                center: {
                    text: this.centerText,
                    color: '#000',
                    fontStyle: 'Arial',
                    sidePadding: 20,
                    minFontSize: 20,
                    lineHeight: 20 // Usado para definir o tamanho da linha, usado para quebrar o texto
                }
            },
            tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    if (data.datasets[0].data[tooltipItem.index] < 1) {
                        return 'Nenhuma amostra';
                    } 
                    else {
                        return `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]}`;
                    }
                  }
                }
              },
            onClick: this.handle
        })
    },
    methods: {
        handle(point, event) {
            const setor = this.chartId.find(s => s.setorNome === event[0]._model.label);
            this.$emit('on-selecionarSetorStatus', {
                setorId: setor.setorId,
                status: setor.status,
                label: event[0]._model.label
            })
        }
    }
}
