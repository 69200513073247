<template>
    <section>
        <div style="padding: 1rem; border-bottom: 1px solid black;">
        <span style="display: flex; align-items: center">
            <span style="color: grey;">Configurações / &nbsp; </span> 
            <strong> Exames</strong>
        </span>
        </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column is-4">
                        <div v-if="isInRole('exame-alterar') || isInRole('exame-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.EXAMES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'exames', query: { procurar: procurar.exame }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.exame"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'exames' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('exame-alterar')" :to="{ name: 'exame' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>                       
                    </div>
                    <div class="column is-4">
                        <div v-if="isInRole('material-alterar') || isInRole('material-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.MATERIAIS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'materiais', query: { procurar: procurar.material }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.material"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'materiais' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('material-alterar')" :to="{ name: 'material' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div v-if="isInRole('conservante-alterar') || isInRole('conservante-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.CONSERVANTES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'conservantes', query: { procurar: procurar.conservante }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.conservante"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'conservantes' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('conservante-alterar')" :to="{ name: 'conservante' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-4">
                        <div v-if="isInRole('mascaraAbreviatura-alterar') || isInRole('mascaraAbreviatura-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.ABREVIATURAS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'abreviaturas', query: { procurar: procurar.mascaraAbreviatura }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.mascaraAbreviatura"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form> 
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'abreviaturas' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('mascaraAbreviatura-alterar')" :to="{ name: 'abreviatura' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div v-if="isInRole('exameGrupo-alterar') || isInRole('exameGrupo-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.GRUPOEXAME')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'grupoexames', query: { procurar: procurar.exameGrupo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.exameGrupo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'grupoexames' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('exameGrupo-alterar')" :to="{ name: 'grupoexame' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>                       
                    </div>
                    <div class="column is-4">
                        <div v-if="isInRole('liberacaoAutomatica-alterar') || isInRole('liberacaoAutomatica-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.LIBERACAOAUTO')}}
                            </p>                            
                            <form @submit.stop.prevent="$router.push({ name: 'liberacoesautomatica', query: { procurar: procurar.liberacaoAutomatica }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.liberacaoAutomatica"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'liberacoesautomatica' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('liberacaoAutomatica-alterar')" :to="{ name: 'liberacaoautomaticaconfig' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.CONFIGURAR')}}
                                </router-link>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="columns">

                    <div class="column is-4" v-if="isInRole('modelolaudosdescritivos-alterar') || isInRole('modelolaudosdescritivos-consultar')">
                        <div class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.LAUDODESCRITIVO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'modeloslaudodescritivo', query: { procurar: procurar.modeloLaudo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.modeloLaudo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'modeloslaudodescritivo' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('modelolaudosdescritivos-alterar')" :to="{ name: 'modelolaudodescritivo' }"  class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="column is-4">
                        <div v-if="isInRole('amostrastatus-alterar') || isInRole('amostrastatus-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.AMOSTRASSTATUS')}}
                            </p>                            
                            <form @submit.stop.prevent="$router.push({ name: 'amostrastatus', query: { procurar: procurar.amostrasStatus }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.amostrasStatus"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'amostrasstatus' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('amostrastatus-alterar')" :to="{ name: 'amostrastatus' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}} 
                                </router-link>
                            </div>
                        </div>                        
                    </div>
                    <div class="column is-4" v-if="isInRole('exameextracao-alterar') || isInRole('exameextracao-consultar')">
                        <div class="panel">
                            <p class="panel-heading background-1">
                               {{$t('MANUTENCAOLISTAR.EXAMEEXTRACAO')}} 
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'examesextracao', query: { procurar: procurar.exameextracao }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.exameextracao"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'examesextracao' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}} 
                                </router-link>
                                <router-link v-if="isInRole('exameextracao-alterar')" :to="{ name: 'exameextracao' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}} 
                                </router-link>
                            </div>
                        </div>
                    </div>

                </div>                     
            </div>
            <div class="columns">
                <div class="column is-4">
                    <div v-if="isInRole('pesquisa-alterar') || isInRole('pesquisa-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.PESQUISAS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'pesquisas', query: { procurar: procurar.pesquisa }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.pesquisa"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'pesquisas' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('pesquisa-alterar')" :to="{ name: 'pesquisa' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div> 
                <div class="column is-4">
                    <div v-if="isInRole('sorotecageladeira-alterar') || isInRole('sorotecageladeira-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.SOROTECA')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'sorotecageladeiras', query: { procurar: procurar.sorotecageladeira }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.sorotecageladeira"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'sorotecageladeiras' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('sorotecageladeira-alterar')" :to="{ name: 'sorotecageladeira' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>    
                <div class="column is-4">
                    <div v-if="isInRole('sorotecacontainer-alterar') || isInRole('sorotecacontainer-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.CONTAINERS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'sorotecascontainers', query: { procurar: procurar.sorotecacontainer }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.sorotecacontainer"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'sorotecascontainers' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('sorotecacontainer-alterar')" :to="{ name: 'sorotecacontainer' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>            
            </div>
            <div class="columns">
                <div class="column is-4">
                        <div v-if="isInRole('exameCodigoTipo-alterar') || isInRole('exameCodigoTipo-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.TIPOCODIGO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'examescodigostipos', query: { procurar: procurar.exameCodigoTipo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.exameCodigoTipo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'examescodigostipos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('exameCodigoTipo-alterar')" :to="{ name: 'examecodigotipo' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
            <div class="column is-8" v-if="examesValidacao" >
                        <div class="panel">
                            <p class="panel-heading background-1">
                               {{$t('MANUTENCAOLISTAR.VALIDACAOEXAMES')}} 
                            </p>
                        <p class="panel-block  ">
                            <small class="mb-4">{{$t('MANUTENCAOLISTAR.SERVICOVALIDACAOEXAMES')}}</small>                                                        
                        </p>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'examesvalidacao' }" class="button is-success is-fullwidth">
                                    {{$t('SISTEMA.VALIDAR')}} 
                                </router-link>
                            </div>
                        </div>
                </div> 
                </div>

                
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {},
                examesValidacao: false,
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            verificarExameValidacao() {                
                this.$http.get('api/Dashboard/PossuiExameValidacao')
                    .then(res => {
                        this.examesValidacao = res.data
                    });
            }                       
        },
        mounted() {                
            this.verificarExameValidacao();                                    

        }         
    }
</script>