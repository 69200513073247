<template>
    <section>
        <titlebar :title-stack="titleStack">
        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-success" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('ESPECIECADASTRO.SALVANDOESPECIE')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('ESPECIECADASTRO.NOME')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-input maxlength="100" type="text"  v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </article>                        
                    </div>
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <article class="panel">
                                <div class="panel-heading has-text-centered">
                                    {{$t('CONVENIOCADASTRO.CODINTEGRACAO')}}
                                </div>
                                <div class="panel-block">
                                    <table class="table is-bordered is-striped is-fullwidth">
                                        <thead>
                                            <tr>
                                                <th>{{$t('CONVENIOCADASTRO.TIPO')}}</th>
                                                <th>{{$t('CONVENIOCADASTRO.CODIGO')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                <td>
                                                    <b-select placeholder="Integrador" v-model="item.integradorEspecieId.integradorId">
                                                        <option v-for="option in integradores"
                                                                :value="option.id"
                                                                :key="option.id">
                                                            {{ option.nome }}
                                                        </option>
                                                    </b-select>
                                                </td>
                                                <td>
                                                    <input class="input" type="text" v-model="item.integradorEspecieId.codigo">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="panel-block">
                                    <button type="button" class="button is-success is-fullwidth" @click="addCodigo">
                                        {{$t('CONVENIOCADASTRO.ADDCODIGO')}}
                                    </button>
                                </div>
                            </article>                            
                        </article>
                    </div>                    
                </div>
                <br>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('ESPECIECADASTRO.CANCELAR')}}
                            </b-button>
                            <b-button type="is-success"
                                      v-show="isInRole('especie-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('ESPECIECADASTRO.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>
<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    codigosIntegrador:[],
                },
                salvandoDados: false
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('ESPECIECADASTRO.MANUTENCAO'),
                    this.$t('ESPECIECADASTRO.ESPECIE'),
                    this.model.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'integradores'
            ]),            
        },
        methods:{
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorEspecieId: {
                        integradorId: null
                    }

                });
            },            
            onSubmit() {
                this.salvandoDados = true;
                if(this.model.id == null){
                    this.model.id = 0
                }

                if (this.model.codigosIntegrador?.length) {
                    this.model.codigosIntegrador = this.model.codigosIntegrador.filter(c => c.integradorEspecieId.integradorId != null)?.map(i => {
                        return {
                            codigo: i.integradorEspecieId.codigo,
                            ...i
                        }
                    });
                }

                this.$http.post(`/api/manutencao/especie`, this.model )
                    .then(({ data }) => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
                    .catch((error) => {
                        this.salvandoDados = false;
                        throw error
                    });       
            }
        },
        created() {            
            if(this.$route.params.id){
                this.$http.get('/api/manutencao/especie?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;

                        if (this.model.codigosIntegrador?.length) {
                            this.model.codigosIntegrador = this.model.codigosIntegrador.map(c => {
                                return {
                                    integradorEspecieId: {
                                        integradorId: c.integradorId,
                                        codigo: c.codigo
                                    }
                                }
                            })
                        }

                    })
            }
        }
    }
</script>