<template>
    <section class="hero is-fullheight" style="background-color: white">
        <div class="hero-body">
            <div class="container">
                <div class="box centered">
                    <div class="column is-7-desktop is-8-tablet">
                        <div class="has-text-centered">
                            <img class="imagelimit" v-if="config.logoBase64" :src="'data:image/png;base64, ' + config.logoBase64" />
                            <h3 class="title" v-else>{{ config.nomeDoLaboratorio }}</h3>
                        </div>
                        <br><br>
                        <div v-if="isServerOut" class="notification is-warning">
                            Sistema em manutenção.<br/>
                            Tente novamente mais tarde.
                        </div>
                        <div v-if="erros.length > 0" class="notification is-info">
                            <ul>
                                <li v-for="(erro, index) in erros" v-bind:key="index">
                                    {{ erro }}
                                </li>
                            </ul>                                        
                        </div>
                        <form @submit.prevent="onSubmit" method="post" role="form">
                            <b-input icon="account" size="is-medium" placeholder="E-mail ou Usuário" required v-model="UserName" autocomplete="no"></b-input>
                            <br>
                            <b-input type="password" size="is-medium" password-reveal placeholder="Senha" icon="lock" required v-model="Password" autocomplete="new-password"></b-input>
                            <br>
                            <button v-if="config.nomeBotaoLoginLDAP != null" @click="usarLDAP(LDAP)" type="submit" class="button is-primary is-fullwidth is-medium has-text-weight-bold" style="margin-left: 0 !important">{{config.nomeBotaoLoginLDAP}}</button>
                            <br />
                            <button type="submit" class="button is-primary is-fullwidth is-medium has-text-weight-bold" style="margin-left: 0 !important">Login</button>
                            <br />
                            <p class="has-text-centered is-size-6">
                                <a class="has-text-centered is-size-6 has-text-primary has-text-weight-bold" href="/Account/LostPassword">Esqueceu a senha?</a>
                            </p>
                        </form>

                    </div>

                </div>                    
                <p class="has-text-centered is-size-6">
                    <a href="http://proradis.com.br/" target="_blank">
                        <img src="/img/logo-Proradis-Lab.png" alt="Logo da PRORADIS LAB" style="margin-top: 10px; width: 130px;">
                    </a>
                </p>
            </div>            
        </div>
    </section>
</template>

<style scoped>

.box{ 
    -webkit-box-shadow: none !important;
    box-shadow: none !important;

}

html{
 background-color: white;
}

input{
    border-radius: 15px;
}

.box {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    margin: auto; /* Adicionado para centralizar a div */
    width: 50%; /* Ajuste a largura conforme necessário */
}

@media screen and (max-width: 768px) {
    .box {
        width: 90%; /* Ajuste a largura conforme necessário */
    }
    .centered {
        flex-direction: column;
    }
    .hero-body{
        padding: 0;
    }
}


.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .centered {
        flex-direction: column-reverse;
    }
}
</style>





<script>
/* global Tawk_API */

    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'App',
        data() {
            return {
                UserName: '',
                Password: '',
                LDAP: false
            }
        },
        computed: {
            ...mapState([
                'config',
                'erros',
                'usuario',
                'isServerOut'
            ])
        },
        methods: {

            ...mapActions([
                'login',
                'commit'
            ]),
            usarLDAP() {
                this.LDAP = true;
            },
            onSubmit() {
                // se estiver num portal, sai
                if (this.$route.path.startsWith('/portal/')) {
                    this.$router.push('/')
                }

                this.login({ authMethod: this.authMode, UserName: this.UserName, Password: this.Password, LDAP: this.LDAP })
                    .then(() => {   
                        if(this.usuario.tipos[0] == 'Convenio'){
                            this.$router.push({ name: 'portalconvenio' });
                        }
                        else if(this.usuario.tipos[0] == 'Local'){
                            this.$router.push({ name: 'portallocal' });
                        }
                        else if(this.usuario.tipos[0] == 'Medico'){
                            this.$router.push({ name: 'portalmedico' });
                        }
                        else if(this.usuario.tipos[0] == 'Paciente'){
                            this.$router.push({ name: 'portalpaciente' });
                        } else if ( this.usuario.tipos[0] == 'Funcionario' && this.usuario.radiologista ){
                            this.$router.push({ name: 'estudos',query: { unidadeId: (this.usuario.unidadeId) ? this.usuario.unidadeId  : 1 }  });
                        }       
                            

                    });
            }
        }
    }
</script>