<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('ESTOQUECADASTRO.SALVANDO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-half">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('ESTOQUECADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <b-field :label="$t('ESTOQUECADASTRO.UNIDADE')">
                                        <b-select :placeholder="$t('ESTOQUECADASTRO.SELECIONEUNIDADE')" v-model="model.unidade.id" expanded>
                                            <option v-for="(item, index) in unidades"
                                                    v-bind:key="index"
                                                    :value="item.id">
                                                {{item.nome}}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </div>
                            </div> 
                            <div class="columns">
                                <div class="column is-12">
                                    <searchIdName :id.sync="model.convenio.id" :item.sync="model.convenio" :label="$t('SISTEMA.CONVENIO')" table="Convenio"></searchIdName>   
                                </div>   
                            </div>                
                        </article>                
                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('ESTOQUECADASTRO.CANCELAR')}}
                            </b-button>
                            <b-button type="is-success" v-show="isInRole('estoque-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('ESTOQUECADASTRO.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters,mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'

    export default {
        components: {
            titlebar,
            searchIdName
        },
        data() {
            return {
                model: {
                    id: 0,
                    nome: null,
                    unidade: {
                        id: 0,
                        nome: ''
                    },
                    convenio: {
                        id: 0,
                        nome: ''
                    }
                },                
                salvandoDados: false
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/estoque/estoque?id=' + this.$route.params.id)
                    .then(res => {                        
                        this.model = res.data;
                    });                
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('ESTOQUE.TITULO'),
                    this.$t('ESTOQUECADASTRO.TITULO'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([                
                'unidades'
            ])            
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                let dadosObrigatorios = true;

                if(this.model.id == null){
                    this.model.id = 0
                }
           
                if(!this.model.nome){
                    dadosObrigatorios= false;
                }

                if(!this.model.unidade.id){
                    dadosObrigatorios= false;
                }                
          
                if(dadosObrigatorios){                    
                    this.$http.post(`/api/estoque/estoque`, this.model)
                            .then(() => {
                                this.salvandoDados = false;
                                this.$router.back();
                            })
                            .catch((error) => {
                                this.salvandoDados = false;
                                throw error
                            });                      

                }else{
                
                        this.$buefy.dialog.alert({
                            title: this.$t('ESTOQUECADASTRO.ATENCAO'),
                            message: this.$t('ESTOQUECADASTRO.CAMPOSOBRIGATORIOS'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });                    
                        this.salvandoDados = false;
                        return null;
                }
                
 

            }
        }
    }
</script>