<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <div v-if="mostrarCadastro" class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column">
                            <b-field :label="$t('CENTRORESULTADOS.ID')">
                                <b-input type="text" v-model="model.id" :readonly="true"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('CENTRORESULTADOS.NOME')">
                                <b-input type="text" maxlength="40" v-model="model.nome"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('CENTRORESULTADOS.REGISTROPAI')">
                                <b-input type="text" v-model="model.nomePai" :readonly="true"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field :label="$t('CENTRORESULTADOS.DEPARACONTABIL')">
                                <b-input type="text" maxlength="10" v-model="model.deparaContabil"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <nav class="level">
                        <div class="level-item">
                            <p class="buttons">
                                <b-button type="is-success"
                                        :loading="salvandoDados"
                                        native-type="button" @click="salvar()"
                                        icon-left="check-circle">
                                    {{$t('CENTRORESULTADOS.SALVAR')}}
                                </b-button>
                            </p>
                        </div>
                    </nav>
                </article>
            </div>              
        </div>

        <div class="columns box">
            <div class="column">
                <b-button style="margin-bottom: 2px;" type="type is-success" size="is-small"
                    icon-left="plus"
                    @click="clickPlusRoot">
                    {{$t('CENTRORESULTADOS.NOVORAIZ')}}
                </b-button> 
                <TreeView 
                    :nodes="treeData"
                    @clickPlus="plusWasClicked"
                    @clickEdit="editWasClicked"
                    />
            </div>
        </div>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import TreeView from "@/components/treeView.vue";

    export default {
        data() {
            return {
                model: {
                    id: null,
                    nome: null,
                    registroPai: null,
                    nomePai: null,
                    deparaContabil: null
                },
                centrosResultados: [],
                treeData: [],
                mostrarCadastro: false,
                salvandoDados: false,
            }
        },
        components: {
            titlebar,
            TreeView
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FINANCEIRO'),
                    this.$t('MENUFINANCEIRO.CENTRORESULTADOS')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },        
        created() {   
            this.loadCentrosResultados();
        },
        methods: {
            loadCentrosResultados(){
                this.treeData = [];

                this.$http.get('/api/manutencao/BuscarCentrosResultados')
                    .then(res => {
                        this.centrosResultados = res.data;
                        this.construirTreeData();
                    });
            },
            construirTreeData(){
                const roots = this.centrosResultados.filter(centro => centro.registroPai == null);

                roots.forEach(root => {
                    root = this.controiFilhos(root);

                    this.treeData.push({
                        id: root.id,
                        name: root.nome,
                        children: root.children
                    });
                });

            },
            controiFilhos(root){
                const suns = this.centrosResultados.filter(centro => centro.registroPai == root.id);

                const sunsRoot = [];
                if(suns != null && suns.length > 0){
                    suns.forEach(sun => {
                        //recursive
                        sun = this.controiFilhos(sun);

                        sunsRoot.push({
                            id: sun.id,
                            name: sun.nome,
                            children: sun.children
                        });
                    });
                }

                root.children = sunsRoot;

                return root;
            },
            clickPlusRoot(){
                this.model = {
                    id: 0,
                    nome: null,
                    registroPai: null,
                    nomePai: null,
                    deparaContabil: null
                };

                this.mostrarCadastro = true;
            },
            plusWasClicked(node){
                this.model = {
                    id: 0,
                    nome: null,
                    registroPai: node.id,
                    nomePai: node.name,
                    deparaContabil: null
                };

                this.mostrarCadastro = true;
            },
            editWasClicked(node){
                this.model = {
                    id: node.id,
                    nome: node.name,
                    registroPai: this.centrosResultados.filter(centro => centro.id == node.id)[0].registroPai,
                    deparaContabil: this.centrosResultados.filter(centro => centro.id == node.id)[0].deparaContabil
                };
                
                if(this.model.registroPai != null)
                {
                    this.model.nomePai = this.centrosResultados.filter(centro => centro.id == this.model.registroPai)[0].nome;
                }

                this.mostrarCadastro = true;
            },
            salvar(){


                if(!this.model.nome){

                        this.$buefy.dialog.alert({
                                title: this.$t('SISTEMA.ATENCAO'),
                                message: this.$t('CATEGORIAFINANCEIRA.CAMPONOMEOBRIGATORIO'),
                                type: 'is-warning',
                                hasIcon: true                                          
                            });                        
                        return true;          
                }      


                this.salvandoDados = true;

                this.$http.post('/api/manutencao/CentroResultados', this.model)
                    .then(res => {
                        this.model = {
                            id: null,
                            nome: null,
                            registroPai: null,
                            nomePai: null,
                            deparaContabil: null
                        };
                        
                        this.salvandoDados = false;
                        this.mostrarCadastro = false;
                        this.loadCentrosResultados();
                    });
            }
        }
    }
</script>