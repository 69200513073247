<template>
    <section>
        
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('pedidointerno-alterar') || isInRole('pedidointerno-consultar') || isInRole('pedidointerno-incluir')" class="panel">
                    <p class="panel-heading background-1">
                        Solicitação
                    </p>
                    <form action="/Estoque/ListarPedidosInternos" method="get" class="panel-block">
                        <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <a href="/Estoque/ListarPedidosInternos" class="button is-light is-fullwidth">
                            Listar todos
                        </a>
                        <a v-if="isInRole('pedidointerno-alterar') || isInRole('pedidointerno-incluir')" href="/Estoque/PedidoInterno" class="button is-primary is-fullwidth">
                            Incluir
                        </a>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('pedido-alterar') || isInRole('pedido-consultar') || isInRole('pedido-incluir')" class="panel">
                    <p class="panel-heading background-1">
                        {{ ($t('ESTOQUE.PEDIDOSAFORNECEDORES')) }}
                    </p>
                    <form action="/Estoque/ListarPedidos" method="get" class="panel-block">
                        <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <a href="/Estoque/ListarPedidos" class="button is-light is-fullwidth">
                            Listar todos
                        </a>
                        <a v-if="isInRole('pedido-alterar') || isInRole('pedido-incluir')" href="/Estoque/Pedido" class="button is-primary is-fullwidth">
                            Incluir
                        </a>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('estoqueMovimentacao-alterar') || isInRole('estoqueMovimentacao-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{ ($t('ESTOQUE.MOVIMENTOODEESTOQUE')) }}
                    </p>
                    <form action="/Estoque/ListarEstoqueMovimentacao" method="get" class="panel-block">
                        <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <a href="/Estoque/ListarEstoqueMovimentacao" class="button is-light is-fullwidth">
                            Listar todos
                        </a>
                        <a v-if="isInRole('estoqueMovimentacao-alterar')" href="/Estoque/EstoqueMovimentacao" class="button is-primary is-fullwidth">
                            Incluir
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="estoqueBaixo.length > 0" class="panel">
                    <p class="panel-heading background-1">
                        Estoque baixo
                    </p>
                    <div style="height: 500px; overflow: auto;">
                    <b-collapse class="card"
                                animation="slide"
                                v-for="(consumiveis, categoria, index) of estoqueBaixoByCategoria"
                                :key="index"
                                :open="true"
                                @open="isEstoqueBaixoOpen = index">
                        <div slot="trigger"
                             slot-scope="props"
                             class="card-header has-background-light"
                             role="button">
                            <p class="card-header-title">
                                {{ categoria }}
                            </p>
                            <a class="card-header-icon">
                                <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                                <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                            </a>
                        </div>
                        <div class="panel">
                            <div v-for="(item, index) in consumiveis" v-bind:key="index">
                                <a :href="'/Estoque/Pedido?consumivelId=' + item.id + '&quantidade=' + (item.quantidadeMinima - item.quantidade)" class="panel-block is-justified-between">
                                    <span>{{item.nome}}</span>
                                    <span class="tag is-rounded is-danger">{{item.quantidade}} / {{item.quantidadeMinima}}</span>
                                </a>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                </div>
            </div>
            <div class="column">
                <div class="panel" v-if="pedidosInternos.length > 0 || pedidosExternos.length > 0">
                    <p class="panel-heading background-1">
                        Pedidos Pendentes
                    </p>
                    <div style="height: 500px; overflow: auto;">
                        <div v-for="(item, index) in pedidosInternos" v-bind:key="index">
                            <a :href="'/Estoque/ReceberPedidoInterno?id=' + item.id" class="panel-block is-justified-between">
                                <span>{{item.localNome}}<br/>
                                <small><b>Id:</b>{{ item.id }}</small><br/>
                                <small><b>Referência:</b> {{item.referencia}}</small></span>
                                <span>{{item.setorNome}}</span>
                                <span class="tag is-rounded is-light">{{item.data | moment('DD/MM/YYYY')}}</span>
                            </a>
                        </div>
                    <div v-for="(item, index) in pedidosExternos" v-bind:key="index">
                        <a :href="'/Estoque/ReceberPedido?id=' + item.id" class="panel-block is-justified-between">
                            <div>
                                <span>{{item.pedidoNoFornecedor}}</span>
                                <p>
                                    <span v-if="item.fornecedor" title="Fornecedor">{{item.fornecedor.nome}}</span>
                                </p>
                            </div>
                            <span class="tag is-rounded is-light">{{item.previsaoDeEntrega | moment('DD/MM/YYYY')}}</span>
                        </a>
                    </div>
                </div>
                </div>
            </div>
            <div class="column">
                <div v-if="lotesExpirando.length > 0" class="panel">
                    <p class="panel-heading background-1">
                        Lotes expirando
                    </p>
                    <div style="height: 500px; overflow: auto;">
                    <div v-for="(item, index) in lotesExpirando" v-bind:key="index">
                        <a :href="'/Estoque/Lote?id=' + item.id" class="panel-block is-justified-between">
                            <span>{{item.nome}}</span>
                            <span class="tag is-rounded is-danger">{{item.validade | moment('DD/MM/YYYY')}}</span>
                        </a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                estoqueBaixo: [],
                lotesExpirando: [],
                pedidosInternos: [],
                pedidosExternos: [],
                procurar: {},

                isEstoqueBaixoOpen: -1,
                isLotesExpirandoOpen: -1,
                pedidosCarregados: false,
                lotesCarregados: false,
                estoqueBaixoCarregado: false
            }
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config'
            ]),
            estoqueBaixoByCategoria() {
                return this.estoqueBaixo.reduce((acc, consumivel) => {
                    (acc[consumivel.categoria] = acc[consumivel.categoria] || []).push(consumivel);
                    return acc;
                }, {})
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            ...mapActions([
                'get',
            ]),
            carregarEstoqueBaixo(){
                this.estoqueBaixoCarregado = true;

                this.$http.get('api/Dashboard/estoqueBaixo')
                    .then(res => {
                        this.estoqueBaixo = res.data
                    });
            },
            carregarLotesExpirando(){
                this.lotesCarregados = true;

                this.$http.get('api/Dashboard/lotesExpirando')
                    .then(res => {
                        this.lotesExpirando = res.data
                    });
            },
            carregarPedidosAguardando(){
                this.pedidosCarregados = true;

                this.$http.get('api/Dashboard/pedidosInternosAguardando')
                    .then(res => {
                        this.pedidosInternos = res.data
                    });
                    
                this.$http.get('api/Dashboard/pedidosExternosAguardando')
                    .then(res => {
                        this.pedidosExternos = res.data
                    });
            }
        },
        mounted() {
            this.carregarEstoqueBaixo();
                this.carregarLotesExpirando();
                this.carregarPedidosAguardando();
        }
    }
</script>