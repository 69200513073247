<template>
  <section>
    <b-loading :active.sync="isLoading"></b-loading>

    <titlebar :title-stack="titleStack"> </titlebar>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="tile is-ancestor">
          <div
            class="tile is-parent is-vertical"
            :class="{ 'is-9': model.id, 'is-12': !model.id }"
          >
              <article class="tile is-child box">
                  <div class="columns">
                      <div class="column">
                          <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                              <b-field :label="$t('DRECADASTRO.NOME')"
                                       :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                       :message="errors">
                                  <b-input v-model="model.nome" type="text" maxlength="100" :has-counter="false" />
                              </b-field>
                          </ValidationProvider>
                      </div>
                  </div>
                  <div class="columns">
                      <div class="column">
                          <ValidationProvider rules="required" name="operador" v-slot="{ errors, valid }">
                            <b-field :label="$t('DRECADASTRO.OPERADOR')"
                              :type="{ 'is-danger': errors[0], 'is-success': valid }"
                              :message="errors"
                            >
                                  <b-select placeholder=""
                                    v-model="model.operador"
                                    expanded
                                  >
                                      <option v-for="option in operadores"
                                              :value="option.valor"
                                              :key="option.valor">
                                          {{ 
                                            option.valor == 1 
                                              ? '+' 
                                              : option.valor == 2 
                                                ? '-'
                                                : '='
                                          }}
                                      </option>
                                  </b-select>
                            </b-field>
                          </ValidationProvider>
                      </div>
                      <div class="column">
                        <div class="column">
                                <b-field :label="$t('DRECADASTRO.SEQUENCIA')" expanded>
                                    <b-tooltip expanded :label="$t('DRECADASTRO.PROXIMOVALOR')"
                                    type="is-dark"
                                    position="is-top" >
                                    <b-input type="number" icon="forward" v-model="model.sequencia" icon-clickable @icon-click="buscarProximoNumeroSequencia" expanded></b-input>                                            
                                </b-tooltip>
                            </b-field>
                        </div>
                      </div>
                  </div>
              </article>           
          </div>

          <div class="tile is-parent">
            <article class="tile is-child">
             <article class="panel">
                <div class="panel-block">
                    <div class="field">
                        <b-switch type="is-danger" v-model="model.desativado">
                            {{$t('DRECADASTRO.DESATIVADO')}}
                        </b-switch>
                    </div>
                </div>
              </article>
            </article>
          </div>
        </div>
        <nav class="level">
          <div class="level-item">
            <p class="buttons">
              
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="close-circle"
                @click="$router.back()"
              >
                {{$t('SISTEMA.CANCELAR')}}
              </b-button>
              <b-button
                type="is-success"
                :loading="salvandoDados"
                native-type="submit"
                icon-left="check-circle"
              >
                {{$t('SISTEMA.SALVAR')}}
              </b-button>
            </p>
          </div>
        </nav>
      </form>
    </ValidationObserver>
  </section>
</template>

<style scoped>
.fix-size {
  max-height: 1.5rem;
  padding-top: 0rem;
}
.btn-action {
  margin: 0.2rem;
}

.label-parcela-cancelada {
  padding-top: 0.25rem;
}

.collapse-content {
  width: 100% !important;
}

.panel .control {
  max-width: 210px;
}

.centered {
  margin: 0 auto;
}

.fullwidth {
  width: 100%;
  max-width: 100% !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import titlebar from "@/components/titlebar.vue";
import moment from "moment";

export default {
  data() {
    return {
      model: {
        id: null,
        nome: null,
        sequencia: null,
        operador: null,
        desativado: false,
      },
      salvandoDados: false,
      operadores: [],
      isLoading: false,
    };
  },
  components: {
    titlebar
  },
  created() {
    this.isLoading = true;
    let id = "";
    if (this.$route.params.id) {
      id = "?id=" + this.$route.params.id;
    }
    this.$http
      .get("/api/financeiro/dre" + id)
      .then((res) => {
        const model = res.data?.item;
        
        this.operadores = res.data?.operadores;

        if (!model.operador){
          model.operador = this.operadores[0]?.valor;
        }
        
        this.model = model;
        this.isLoading = false;
      })
      .catch((ex) => {
        this.isLoading = false;
      });
  },
  computed: {
    titleStack() {
      return [
        this.$t('SIDEBAR.FINANCEIRO'),
        this.$t('DRECADASTRO.TITULO'),
        this.model.id ? this.model.id : this.$t('SISTEMA.NOVO')
      ];
    },
    ...mapState(["unidadeId", "config", "integradores"]),
    ...mapGetters(["isInRole"]),
  },
  methods: {
    onSubmit() {
      this.$forceUpdate();
      this.isLoading = true;
      const newItem = this.getItem();
      
      this.$http
        .post(`/api/financeiro/dre`, newItem)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "dre-lista" });
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
        });
    },
    buscarProximoNumeroSequencia(){
      this.$http.get('/api/financeiro/ProximoEtiquetaNumeroSequencia?id=' + this.model?.id)
      .then(res => {
          if (res.data != null){
            this.model.sequencia = res.data;
          }
      });

    },
    getItem() {
      const novoItem = Object.assign({}, this.model);
      return novoItem;
    },
  },
};
</script>
