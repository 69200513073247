<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button class="delete"></button>
                   {{$t('CATEGORIACADASTRO.SALVANDO')}}
                </div>

                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-half">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CATEGORIACADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>                            
                        </article>                
                    </div>                    
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('CATEGORIACADASTRO.CANCELAR')}}
                            </b-button>
                            
                            <b-button type="is-success" v-show="isInRole('estoque-alterar')"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('CATEGORIACADASTRO.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        components: {
            titlebar
        },
        data() {
            return {
                model: {
                    id: 0,
                    nome: null
                },
                salvandoDados: false,
                retornarCategoria: Boolean(this.$route.query.retornarCategoria) ?? false,
            }
        },
        created() {
            if(this.$route.params.id){
                this.$http.get('/api/estoque/categoria?id=' + this.$route.params.id)
                    .then(res => {                        
                        this.model = res.data;
                    });
                this.model.id = this.$route.params.id;
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('ESTOQUE.TITULO'),
                    this.$t('CATEGORIACADASTRO.TITULO'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {
                this.salvandoDados = true;
                let nomePreenchido = true;

                if(this.model.id == null){
                    this.model.id = 0
                }
           
                if(!this.model.nome){
                    nomePreenchido= false;
                }
          
                if(nomePreenchido){                    
                    this.$http.post(`/api/estoque/categoria`, this.model)
                            .then((res) => {
                                this.salvandoDados = false;
                                if(this.retornarCategoria){
                                    localStorage.retornarCategoria = res.data.id;   
                                    window.close();
                                }else{
                                    this.$router.back();
                                }
                            })
                            .catch((error) => {
                                this.salvandoDados = false;
                                throw error
                            });                      

                }else{
                
                        this.$buefy.dialog.alert({
                            title: this.$t('CATEGORIACADASTRO.ATENCAO'),
                            message: this.$t('CATEGORIACADASTRO.CAMPOSOBRIGATORIOS'),
                            type: 'is-warning',
                            hasIcon: true                                          
                        });                    
                        this.salvandoDados = false;
                        return null;
                }
                
 

            }
        }
    }
</script>