<template>
    <section>
        <b-loading :active.sync="loading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns box">
            <div class="column is-5">
                <div class="columns">
                    <div class="column">
                        <div class="panel">
                            <p class="panel-tabs">
                                <a :class="{ 'is-active': searchFields.dataFiltro == 'competencia' }"
                                    @click="searchFields.dataFiltro = 'competencia'">{{
            $t('CONTASRECEBERLISTAR.COMPETENCIA') }}</a>
                                <a :class="{ 'is-active': searchFields.dataFiltro == 'vencimento' }"
                                    @click="searchFields.dataFiltro = 'vencimento'">{{
            $t('CONTASRECEBERLISTAR.VENCIMENTO') }}</a>
                                <a :class="{ 'is-active': searchFields.dataFiltro == 'pagamento' }"
                                    @click="searchFields.dataFiltro = 'pagamento'">{{
            $t('CONTASRECEBERLISTAR.PAGAMENTO') }}</a>
                            </p>
                            <div class="panel-block">
                                <periodoHora :dataInicial.sync="searchFields.datas[0]"
                                    :dataFinal.sync="searchFields.datas[1]"
                                    :placeHolder="$t('CONTASRECEBERLISTAR.SELECIONEDATA')" @limparDatas="limpaDatas" />
                            </div>
                        </div>
                    </div>
                    <div class="column is-3">
                        <form @submit.prevent="abrirCadastroInput">
                            <b-field :label="$t('CONTASRECEBERLISTAR.ID')">
                                <b-input placeholder="Código" v-model="id" type="text" icon-right="edit"
                                    icon-right-clickable @icon-right-click="abrirCadastroInput">
                                </b-input>
                            </b-field>
                        </form>
                    </div>
                </div>
                <div>
                    <searchIdName :id.sync="searchFields.unidadeId" table="Unidade"
                        :label="$t('CONTASRECEBERLISTAR.UNIDADE')" />
                </div>
                <div>
                    <searchIdName :id.sync="searchFields.convenioId" table="convenio" :label="$t('SISTEMA.CONVENIO')" />
                </div>
                <div>
                    <searchIdName :id.sync="searchFields.categoriaFinanceiraId"
                        :label="$t('CONTASRECEBERLISTAR.CATEGORIAFINANCEIRA')" table="CategoriasFinanceirasReceitas" />
                </div>
            </div>
            <div class="column is-5">
                <div class="is-flex is-flex-direction-column is-justify-content-flex-end">
                    <div class="is-flex-grow-1" style="width: 100%; padding-top: 6px;">
                        <b-field :label="$t('CONTASRECEBERLISTAR.STATUS')">
                            <b-select v-model="searchFields.statusFiltro" expanded>
                                <option :value="null">{{ $t('CONTASRECEBERLISTAR.TODOS') }}</option>
                                <option :value="10">{{ $t('CONTASRECEBERLISTAR.RECEBIMENTOSATRASADOS') }}</option>
                                <option :value="9">{{ $t('CONTASRECEBERLISTAR.RECEBIMENTOSPENDENTES') }}</option>
                                <option :value="8">{{ $t('CONTASRECEBERLISTAR.RECEBIMENTOSLIQUIDADOS') }}</option>
                                <option :value="3">{{ $t('CONTASRECEBERLISTAR.RECEBIMENTOSCANCELADOS') }}</option>
                            </b-select>
                        </b-field>
                    </div>

                    <div style="width: 100%;">
                        <searchIdName :id.sync="searchFields.localDeOrigemId" table="local"
                            :label="$t('CONTASRECEBERLISTAR.LOCALORIGEM')" />
                    </div>
                    <div style="width: 100%;">
                        <searchIdName class="is-fullwidth" :id.sync="searchFields.pacienteId" table="paciente"
                            :label="$t('SISTEMA.PACIENTE')" />
                    </div>
                    <div style="width: 100%;">
                        <searchIdName class="is-fullwidth" :id.sync="searchFields.funcionarioId" table="funcionario"
                            :label="$t('CONTASRECEBERLISTAR.FUNCIONARIO')" />
                    </div>
                </div>
            </div>
            <div class="column">
                <div class=" is-justified-between is-justify-content-flex-end is-align-items-flex-end">
                    <div class="is-flex-grow-1 mb-2">
                        <b-button type="is-primary" icon-left="magnify"
                            @click="ehPesquisa = true; loadAsyncData()">Pesquisar</b-button>
                    </div>
                    <div>
                        <router-link v-if="isInRole('receber-alterar')" :to="{ name: 'cadastro-fatura' }"
                            class="button is-success">
                            {{ $t('CONTASRECEBERLISTAR.INCLUIR') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <b-table :data="model.lista" :loading="loading" paginated striped hoverable backend-pagination :total="total"
            @page-change="onPageChange" :aria-next-label="$t('CONTASRECEBERLISTAR.PROXPAGINA')"
            :aria-previous-label="$t('CONTASRECEBERLISTAR.PAGANTERIOR')"
            :aria-page-label="$t('CONTASRECEBERLISTAR.PAGINA')" :aria-current-label="$t('CONTASRECEBERLISTAR.PAGATUAL')"
            backend-sorting :default-sort-direction="defaultOrdenar" :default-sort="[ordenarPor, ordenar]"
            sort-icon-size="is-small" @sort="onSort" class="table">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column v-slot="props" field="id" :label="$t('CONTASRECEBERLISTAR.ID')">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column v-slot="props" field="glosa" :label="$t('CONTASRECEBERLISTAR.GLOSA')">
                    <div v-if="props.row.receberIdGlosa != 0">
                        {{ props.row.receberIdGlosa }}
                    </div>
                </b-table-column>

                <b-table-column v-slot="props" field="Data" :label="$t('CONTASRECEBERLISTAR.COMPETENCIA')">
                    {{ props.row.dataDeCompetencia | data }}
                </b-table-column>

                <b-table-column v-slot="props" field="Convenio" :label="$t('SISTEMA.CONVENIO')">
                    {{ props.row.convenio }}
                </b-table-column>

                <b-table-column v-slot="props" field="Paciente" :label="$t('SISTEMA.PACIENTE')">
                    {{ props.row.paciente }}
                </b-table-column>

                <b-table-column v-slot="props" field="Categoria Financeira"
                    :label="$t('CONTASRECEBERLISTAR.CATEGORIAFINANCEIRA')">
                    {{ props.row.categoriaFinanceira }}
                </b-table-column>

                <b-table-column v-slot="props" field="Funcionário" :label="$t('CONTASRECEBERLISTAR.FUNCIONARIO')">
                    {{ props.row.funcionario }}
                </b-table-column>

                <b-table-column v-slot="props" field="Unidade de Cadastro"
                    :label="$t('CONTASRECEBERLISTAR.UNIDADECADASTRO')">
                    {{ props.row.unidade }}
                </b-table-column>

                <b-table-column v-slot="props" field="QtdeExames" :label="$t('CONTASRECEBERLISTAR.QTDEXAMES')">
                    {{ props.row.qtdeExames }}
                </b-table-column>

                <b-table-column v-slot="props" field="Valor" :label="$t('CONTASRECEBERLISTAR.VALOR')">
                    {{ props.row.valor | currency }}
                </b-table-column>

                <b-table-column v-slot="props" field="Status" :label="$t('CONTASRECEBERLISTAR.STATUS')">
                    <b-tooltip v-if="props.row.status == 8" expanded :label="$t('CONTASRECEBERLISTAR.LIQUIDADO')"
                        type="is-dark" position="is-top" append-to-body>
                        <b-icon type="is-warning" icon="check"></b-icon>
                    </b-tooltip>
                    <b-tooltip v-if="props.row.status == 10" expanded :label="$t('CONTASRECEBERLISTAR.EMATRASO')"
                        type="is-dark" position="is-top" append-to-body>
                        <b-icon type="is-danger" icon="alert-circle-outline"></b-icon>
                    </b-tooltip>
                    <b-tooltip v-if="props.row.status == 9" expanded :label="$t('CONTASRECEBERLISTAR.EMABERTO')"
                        type="is-dark" position="is-top" append-to-body>
                        <b-icon type="is-grey-dark" icon="clock-outline"></b-icon>
                    </b-tooltip>
                    <b-tooltip v-if="props.row.cancelada" expanded :label="$t('CONTASRECEBERLISTAR.CANCELADA')"
                        type="is-dark" position="is-top" append-to-body>
                        <b-icon type="is-danger" icon="close-circle"></b-icon>
                    </b-tooltip>
                </b-table-column>

                <b-table-column v-slot="props" field="log" :label="$t('CONTASRECEBERLISTAR.ACOES')">
                    <b-dropdown v-if="props.row.qtdeExames > 0 || props.row.qtdeConsumiveis > 0">
                        <button class="button is-primary" slot="trigger">
                            <b-icon icon="format-list-bulleted"></b-icon>
                        </button>
                        <b-dropdown-item aria-role="listitem"
                            v-if="props.row.qtdeExames > 0 || props.row.qtdeConsumiveis > 0"
                            @click="download('ContasReceberPDF', props.row)">{{ $t('CONTASRECEBERLISTAR.PDF')
                            }}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" v-if="props.row.qtdeExames > 0"
                            @click="download('ContasReceberPDFSintetico', props.row)">{{
            $t('CONTASRECEBERLISTAR.PDFSINTETICO') }}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem"
                            v-if="props.row.qtdeExames > 0 || props.row.qtdeConsumiveis > 0"
                            @click="download('ContasReceberXLS', props.row, 'xls')">{{ $t('CONTASRECEBERLISTAR.XLS')
                            }}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" v-if="props.row.qtdeExames > 0"
                            @click="download('ContasReceberXLSSintetico', props.row, 'xls')">{{
            $t('CONTASRECEBERLISTAR.XLSSINTETICO') }}</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem"
                            v-if="props.row.tissVersao && props.row.id && props.row.situacaoReceber == 2 && props.row.qtdeExames > 0"
                            @click="carregaTISS(props.row)">{{ $t('CONTASRECEBERLISTAR.TISS') }}</b-dropdown-item>
                    </b-dropdown>
                </b-table-column>

                <b-table-column v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'cadastro-fatura', params: { id: props.row.id } }"
                        class="button is-small is-primary" title="Editar">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<style scoped>
.center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto;
}
.table {
    overflow: auto;
    table-layout: fixed; 
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import periodoHora from '@/components/periodoHora.vue'
import searchIdName from "@/components/searchIdName.vue";
import titlebar from '@/components/titlebar.vue'

export default {
    data() {
        return {
            model: [],
            total: 0,
            loading: false,
            procurar: this.$route.query.procurar ?? '',
            ordenarPor: 'Nome',
            ordenar: 'asc',
            defaultOrdenar: 'asc',
            page: 1,
            perPage: 20,
            selecionado: null,
            id: null,
            searchFields: {
                unidadeId: null,
                localDeOrigemId: null,
                funcionarioId: null,
                pacienteId: null,
                convenioId: null,
                categoriaFinanceiraId: null,
                dataFiltro: 'competencia',
                datas: [
                    null,
                    null
                ],
                statusFiltro: null
            },
            ehPesquisa: false
        }
    },
    components: {
        titlebar,
        searchIdName,
        periodoHora,
    },
    computed: {
        titleStack() {
            return [
                this.$t('SIDEBAR.FATURAMENTO'),
                this.$t('CONTASRECEBERLISTAR.TITULO')
            ]
        },
        ...mapGetters([
            'isInRole'
        ]),
        ...mapState([
            'config',
            'unidadeId',
        ])
    },
    methods: {
        loadAsyncData() {
            const params = [
                `procurar=${this.procurar}`,
                `ordem=${this.ordenarPor}.${this.ordenar}`,
                `page=${this.page}`,
                `unidadeId=${this.unidadeId}`
            ]

            if (this.$route.query.guiaId) {
                params.push(`guiaId=${this.$route.query.guiaId}`)
            }

            if (this.id) params.push(`Id=${this.id}`);
            if (this.searchFields.localDeOrigemId) params.push(`localDeOrigemId=${this.searchFields.localDeOrigemId}`);
            if (this.searchFields.funcionarioId) params.push(`funcionarioId=${this.searchFields.funcionarioId}`);
            if (this.searchFields.pacienteId) params.push(`pacienteId=${this.searchFields.pacienteId}`);
            if (this.searchFields.convenioId) params.push(`convenioId=${this.searchFields.convenioId}`);
            if (this.searchFields.categoriaFinanceiraId) params.push(`categoriaFinanceiraId=${this.searchFields.categoriaFinanceiraId}`);
            if (this.searchFields.dataFiltro) params.push(`dataFiltro=${this.searchFields.dataFiltro}`);
            if (this.searchFields.datas[0]) params.push(`dataInicial=${this.$moment(this.searchFields.datas[0]).format('YYYY-MM-DDTHH:mm:ss')}`);
            if (this.searchFields.datas[1]) params.push(`dataFinal=${this.$moment(this.searchFields.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`);
            if (this.searchFields.statusFiltro) params.push(`statusFiltro=${this.searchFields.statusFiltro}`);
            if (this.searchFields.unidadeId) params.push(`buscaUnidadeId=${this.searchFields.unidadeId}`);
            params.push(`ehPesquisa=${this.ehPesquisa}`);

            this.loading = true
            this.$http.get(`/api/financeiro/ListarReceber?${params.join('&')}`)
                .then(({ data }) => {
                    this.model = data;
                    if (data && data.lista && data.lista.length == 0 && this.page > 1) {
                        this.page = 1
                        this.loadAsyncData()
                    }
                    this.total = this.perPage * data.pageCount
                    this.loading = false
                    const queryReplace = { query: { procurar: this.procurar, page: this.page } }
                    if (this.$route.query.guiaId) {
                        queryReplace.query.guiaId = this.$route.query.guiaId
                    }
                    this.$router.replace(queryReplace)
                })
                .catch((error) => {
                    this.model = []
                    this.total = 0
                    this.loading = false
                    throw error
                })
        },
        onPageChange(page) {
            this.page = page
            this.loadAsyncData()
        },
        onSort(field, order) {
            this.ordenarPor = field
            this.ordenar = order
            this.loadAsyncData()
        },
        abrirCadastro() {
            this.$router.push({ path: `/faturamento/faturas/${this.selecionado.id}` })
        },
        abrirCadastroInput() {
            this.$router.push({ path: `/faturamento/faturas/${this.id}` })
        },
        download(itemName, item) {

            if (this.config.gerarXLSFaturamentoServico && itemName == 'ContasReceberXLS') {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: 'O arquivo está sendo gerado, pode demorar alguns minutos.',
                    type: 'is-info',
                    queue: false
                })
                this.$http.get(`/api/financeiro/ContasReceberXlsArquivo?id=${item.id}`);
            }
            else {
                window.open(`/api/financeiro/${itemName.toLowerCase()}?id=${item.id}`, '_blank');
            }
        },
        carregaTISS(doc) {
            this.$router.push({
                name: 'xmltiss',
                params: {
                    receberId: doc.id,
                    versao: doc.tissVersao,
                    temConvenioPadrao: doc.temConvenioPadrao
                }
            })
        },
        limpaDatas(event) {
            this.searchFields.datas[0] = event.dataInicial;
            this.searchFields.datas[1] = event.dataFinal;
        }
    },
    mounted() {
        this.loadAsyncData()
    }
}
</script>