
<template>
    
    <section>
    <div style="padding: 1rem; border-bottom: 1px solid black;">
    <span style="display: flex; align-items: center">
        <span style="color: grey;">Configurações / &nbsp; </span> 
        <strong> Pacientes</strong>
    </span>
    </div>
        <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column is-6" v-if="config.laboratorioVeterinario">
                        <div v-if="isInRole('raca-alterar') || isInRole('raca-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.RACA')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'racas', query: { procurar: procurar.raca }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                        v-model="procurar.raca"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'racas' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('raca-alterar')" :to="{ name: 'raca' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-6" v-if="config.laboratorioVeterinario">
                        <div v-if="isInRole('especie-alterar') || isInRole('especie-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.ESPECIE')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'especies', query: { procurar: procurar.especie }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                        v-model="procurar.especie"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'especies' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('especie-alterar')" :to="{ name: 'especie' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {},
                examesValidacao: false,
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            verificarExameValidacao() {                
                this.$http.get('api/Dashboard/PossuiExameValidacao')
                    .then(res => {
                        this.examesValidacao = res.data
                    });
            }                       
        },
        mounted() {                
            this.verificarExameValidacao();                                    

        }         
    }
</script>