<template>
  <section>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal
      :active.sync="isOpenModalData"
      has-modal-card
      trap-focus
      aria-role="dialog"
      full-screen
      aria-modal
    >
      <modalLocalizarContas :titulo="$t('LOTESCONTARECEBERCADASTRO.TITULO')"
        :items.sync="localizarModalData"
        :rows.sync="itemsSelecionados"
        @link="abrirReceber"
      ></modalLocalizarContas>
    </b-modal>

    <titlebar :title-stack="titleStack"> </titlebar>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical  is-10">
            <article v-for="(notaFiscal, index) in notasFiscais" :key="index" class="tile is-child box" >
                <p>{{ notaFiscal.mensagem }}</p>
                <div class="columns">
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.DOCUMENTO')}}</p>
                            <b>{{ notaFiscal.idIntegracao }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.PRESTADOR')}}</p>
                            <b>{{ notaFiscal.prestador }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.TOMADOR')}}</p>
                            <b>{{ notaFiscal.tomador }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.VALOR')}}</p>
                            <b>{{ notaFiscal.valorServico | currency }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.SITUACAO')}}</p>
                            <b>{{ notaFiscal.situacao }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.IDENTIFICACAONOTAFISCAL')}}</p>
                            <b>{{ notaFiscal.id }}</b>
                        </div>
                    </div>
                </div>
                <div v-if="notaFiscal.situacao === 'CONCLUIDO'" class="columns">
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.NUMERONFSE')}}</p>
                            <b>{{ notaFiscal.numeroNfse }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.SERIE')}}</p>
                            <b>{{ notaFiscal.serie }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.LOTE')}}</p>
                            <b>{{ notaFiscal.lote }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.NUMERO')}}</p>
                            <b>{{ notaFiscal.numero }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.CODIGOVERIFICACAO')}}</p>
                            <b>{{ notaFiscal.codigoVerificacao }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.AUTORIZACAO')}}</p>
                            <b>{{ notaFiscal.autorizacao }}</b>
                        </div>
                    </div>
                    <div class="column">
                        <div>
                            <p class="heading">{{$t('CONTASRECEBERCADASTRO.MENSAGEM')}}</p>
                            <b>{{ notaFiscal.mensagem }}</b>
                        </div>
                    </div>
                </div>
            </article>                    
            <article class="tile is-child box">
              <div class="columns">
                <div class="column">
                  <b-field :label="$t('LOTESCONTARECEBERCADASTRO.DESCRICAO')">
                    <b-input v-model="model.descricao" type="text" />
                  </b-field>
                </div>
                <div class="column">
                  <b-field :label="$t('LOTESCONTARECEBERCADASTRO.CONTACORRENTE')">
                    <b-select
                      placeholder=""
                      v-model="model.contaCorrente.id"
                      expanded
                    >
                      <option
                        v-for="option in contas"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.text }}
                      </option>
                    </b-select>
                  </b-field>                  
                </div>
              
              </div>

              <div class="columns">
                  <div class="column is-12">
                      <b-field :label="$t('LOTESCONTARECEBERCADASTRO.OBSERVACAO')">
                          <b-input maxlength="5000" type="textarea" v-model="model.observacao"></b-input>
                      </b-field>
                  </div>
              </div>                 


    
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child">
              <article class="panel">
                <div class="panel-heading has-text-centered">
                  {{$t('LOTESCONTARECEBERCADASTRO.SITUACAO')}}
                </div>
                <div class="panel-block has-text-centered">
                  <p
                    v-if="(model.situacaoLoteContasReceber == 1 || model.situacaoLoteContasReceber == 0)"
                    class="tile is-child notification is-light is-12 fix-size"
                  >
                    {{$t('LOTESCONTARECEBERCADASTRO.PENDENTE')}}
                  </p>
                  <p
                    v-if="model.situacaoLoteContasReceber == 2"
                    class="tile is-child notification is-success is-12 fix-size"
                  >
                    {{$t('LOTESCONTARECEBERCADASTRO.PAGO')}}
                  </p>
                  <p
                    v-if="model.situacaoLoteContasReceber == 3"
                    class="tile is-child notification is-danger is-12 fix-size"
                  >
                    {{$t('LOTESCONTARECEBERCADASTRO.CANCELADO')}}
                  </p>
                </div>
                <br />
                <div v-if="(model.situacaoLoteContasReceber == 1 || model.situacaoLoteContasReceber == 0)">
                  
                  <div v-if="(model.situacaoLoteContasReceber == 1 )">
                    <div class="panel-block" v-if="isInRole('receber-alterar')">
                      <b-button
                        type="is-success"
                        native-type="button"
                        expanded
                        icon-left="download"
                        @click.prevent.stop="baixa"
                      >
                        {{$t('LOTESCONTARECEBERCADASTRO.BAIXAR')}}
                      </b-button>
                    </div>
                    <div class="panel-block" v-if="isInRole('receber-alterar')">
                      <b-button
                        type="is-danger"
                        native-type="button"
                        icon-left="close-circle"
                        expanded
                        @click.prevent.stop="cancelarLote(model.id)"
                      >
                        {{$t('LOTESCONTARECEBERCADASTRO.CANCELARLOTE')}}
                      </b-button>
                    </div>
                    <div class="panel-block" v-if="baixar">
                      <b-field label="Espécie" class="fullwidth" expanded>
                        <b-select
                          placeholder=""
                          v-model="baixar.especie.id"
                          expanded
                          class="fullwidth"
                        >
                          <option
                            v-for="option in especies"
                            :value="option.id"
                            :key="option.id"
                          >
                            {{ option.nome }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>

                    <div class="panel-block" v-if="baixar && baixar.especie.id == 3" >
                      <b-field :label="$t('LOTESCONTARECEBERCADASTRO.BANDEIRA')" class="fullwidth">
                        <b-select
                          placeholder=""
                          v-model="baixar.bandeira.id"
                          class="fullwidth"
                          expanded
                        >
                          <option
                            v-for="option in bandeiras"
                            :value="option.value"
                            :key="option.value"
                          >
                            {{ option.text }}
                          </option>
                        </b-select>
                      </b-field>
                    </div>
                    <div class="panel-block" v-if="baixar">
                      <b-button
                        type="is-primary"
                        native-type="button"
                        expanded
                        @click.prevent.stop="confirmarBaixar"
                      >
                        {{$t('SISTEMA.CONFIRMAR')}}
                      </b-button>
                    </div>
                    <br v-if="false" />
                    <div class="panel-block" v-if="false && isInRole('receber-alterar')">
                      <b-button
                        type="is-warning"
                        native-type="button"
                        expanded
                        icon-left="file"
                        @click.prevent.stop="arquivos"
                      >
                        {{$t('LOTESCONTARECEBERCADASTRO.ARQUIVOS')}}
                      </b-button>
                    </div>
                  </div>
                  <br />
                  <div class="panel-block">
                      <b-button v-if="(model.situacaoLoteContasReceber == 1 || model.situacaoLoteContasReceber == 0 ) && ( model.desconto == 0 || model.desconto ==null ) && items.length>0" class="btn-action is-light center" icon-left="cash" @click.prevent.stop="aplicarDesconto()" expanded>{{$t('LOTESCONTARECEBERCADASTRO.DESCONTO')}}</b-button>
                      <div v-else class="well well-sm">{{$t('LOTESCONTARECEBERCADASTRO.DESCONTO')}}: <b>R$ {{formatPrice(model.desconto)}}</b></div>
                  </div>
                </div>
                <br />
                <div>
                  <div v-if="items.some(x => x.receber != null && x.receber.situacaoReceber === 1)" class="panel-block">
                      <b-button
                          @click.prevent.stop="fecharFatura()"
                          class="button is-warning is-fullwidth"
                          icon-left="lock-open-variant-outline">{{$t('LOTESCONTARECEBERCADASTRO.FECHARFATURA')}}
                      </b-button>
                  </div>
                </div>
                <br />
                <div v-if="items.some(x =>  x.receber != null && x.receber.situacaoReceber === 2)">
                  <div class="panel-block">
                    <b-button class="btn-action is-primary center"
                              v-if="loteId"
                              @click.prevent.stop="emitirDocumentos()"
                              expanded>{{$t('LOTESCONTARECEBERCADASTRO.EMITIRDOCUMENTOS')}}</b-button>
                  </div>
                  <div class="panel-block">
                    <b-button class="btn-action is-info center"
                              v-if="temNotaFiscal && loteId"
                              @click.prevent.stop="consultarNotaFiscal()"
                              expanded>{{$t('LOTESCONTARECEBERCADASTRO.CONSULTARNOTAFISCAL')}}</b-button>
                  </div>
                </div>
              </article>
            </article>
          </div>
        </div>             
      <article class="tile is-child box" >
        <div class="panel-heading has-text-centered  is-light fullwidth" >
          {{$t('LOTESCONTARECEBERCADASTRO.LOCALIZARCONTAS')}}
        </div>

          <div class="columns">
            <div class="column is-6">
                <div class="panel">
                    <p class="panel-tabs">
                        <a :class="{ 'is-active': status == 'emissao' }" @click="status = 'emissao'">{{$t('LOTESCONTARECEBERCADASTRO.EMISSAO')}}</a>
                        <a :class="{ 'is-active': status == 'vencimento' }" @click="status = 'vencimento'">{{$t('LOTESCONTARECEBERCADASTRO.VENCIMENTO')}}</a>
                    </p>
                    <div class="panel-block">
                        <periodoHora :dataInicial.sync="datas[0]"
                            :dataFinal.sync="datas[1]"/>
                    </div>
                </div>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <searchIdName table="unidade" :label="$t('LOTECONTASPAGARCADASTRO.UNIDADE')"
                :id.sync="model.unidades"/>
            </div>
            <div class="column">
              <b-field :label="$t('LOTESCONTARECEBERCADASTRO.ESPECIE')">
                <b-select
                  placeholder=""
                  v-model="model.especie.id"
                  expanded
                >
                  <option
                    v-for="option in especies"
                    :value="option.id"
                    :key="option.id"
                  >
                    {{ option.nome }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
               

        <div class="columns">
              <div class="column">
                <p class="buttons">
                  <b-button
                    type="is-primary"
                    v-if="model.situacaoLoteContasReceber === 1 || !model.id"
                    native-type="button"
                    class="centered"
                    @click.prevent.stop="localizarContas()"
                  >
                    {{$t('LOTESCONTARECEBERCADASTRO.LOCALIZARCONTAS')}}
                  </b-button>
                </p>
              </div>
            </div>        

      </article>           

        <br />
        <article class="tile is-child box" v-if="items.length">
          <div class="panel-heading has-text-centered button is-light fullwidth">
            {{$t('LOTESCONTARECEBERCADASTRO.CONTAS')}}
          </div>
          <div class="panel">
            <b-table :data="items" 
              striped 
              hoverable
              :checked-rows.sync="checkedRows"
              checkable>
              <template >
                <b-table-column  v-slot="props" field="documento" :label="$t('LOTESCONTARECEBERCADASTRO.DOCUMENTO')">
                  <a @click="abrirReceber(props.row)" class="is-primary">
                    {{ props.row.documento }}</a
                  >
                </b-table-column>
                <b-table-column  v-slot="props" field="convenio" :label="$t('LOTESCONTARECEBERCADASTRO.CONVENIO')">
                  <p>{{ props.row.receber.convenio.nome}}</p>
                </b-table-column>
                <b-table-column  v-slot="props" field="notaFiscal" :label="$t('LOTESCONTARECEBERCADASTRO.NOTAFISCAL')">
                  {{ props.row.notaFiscal }}
                </b-table-column>
                <b-table-column  v-slot="props" field="notaFiscal" :label="$t('LOTESCONTARECEBERCADASTRO.BOLETO')">
                  {{ props.row.statusBoleto }}
                </b-table-column>
                <b-table-column  v-slot="props"
                  field="contaCorrente"
                  :label="$t('LOTESCONTARECEBERCADASTRO.CONTACORRENTE')"
                >
                  {{ props.row.nome }}
                </b-table-column>
                <b-table-column  v-slot="props" field="dataEmissao" :label="$t('LOTESCONTARECEBERCADASTRO.EMISSAO')">
                  {{ props.row.dataReceber | data }}
                </b-table-column>
                <b-table-column  v-slot="props" field="dataDeVencimento" :label="$t('LOTESCONTARECEBERCADASTRO.VENCIMENTO')">
                  {{ props.row.dataDeVencimento | data }}
                </b-table-column>
                <b-table-column v-slot="props" field="emailEnviado" :label="$t('LOTESCONTARECEBERCADASTRO.ENVIADO')">
                  <b-switch v-model="props.row.emailFaturamento" disabled></b-switch>
                </b-table-column>
                <b-table-column  v-slot="props" field="observacoes" :label="$t('LOTESCONTARECEBERCADASTRO.OBSERVACAO')">
                  <p v-if="!props.row.observacao"></p>
                  {{ props.row.observacao }}
                </b-table-column>
                <b-table-column  v-slot="props" field="valor" :label="$t('LOTESCONTARECEBERCADASTRO.VALOR')">
                  {{ props.row.valor | currency }}
                </b-table-column>
                <b-table-column  v-slot="props" field="" label="">
                  <b-button
                    v-if="
                      model.situacaoLoteContasReceber === 1 || !model.id
                    "
                    icon-left="close"
                    type="is-danger"
                    @click.prevent.stop="removerItem(props.row)"
                  ></b-button>
                </b-table-column>
              </template>
              <template slot="footer">
                <th class="is-hidden-mobile" colspan="9"></th>
                <th class="is-hidden-mobile">TOTAL:</th>
                <th class="is-hidden-mobile">
                  {{ totalContas | currency }}
                </th>
                <th class="is-hidden-mobile"></th>
              </template>
            </b-table>
          </div>
        </article>

        <br/>
        <nav class="level">
          <div class="level-item">
            <p class="buttons">
              <b-button
                type="is-danger"
                native-type="button"
                icon-left="close-circle"
                @click="$router.back()"
              >
                {{$t('LOTESCONTARECEBERCADASTRO.CANCELAR')}}
              </b-button>
              <b-button
                type="is-success"
                v-if="checkedRows.length > 0"
                icon-left="check-circle"
                @click="enviarEmail(checkedRows)"
              >
                {{$t('LOTESCONTARECEBERCADASTRO.ENVIAREMAIL')}}
              </b-button>
              <b-button
                type="is-success"
                v-if="
                  (model.situacaoLoteContasReceber === 1 || !model.id) &&
                  isInRole('pagar-parcela-lote-alterar')
                "
                :loading="salvandoDados"
                native-type="submit"
                icon-left="check-circle"
              >
                {{$t('LOTESCONTARECEBERCADASTRO.SALVAR')}}
              </b-button>
            </p>
          </div>
        </nav>
      </form>
    </ValidationObserver>
  </section>
</template>

<style scoped>
.fix-size {
  max-height: 1.5rem;
  padding-top: 0rem;
}
.btn-action {
  margin: 0.2rem;
}

.label-parcela-cancelada {
  padding-top: 0.25rem;
}

.collapse-content {
  width: 100% !important;
}

.panel .control {
  max-width: 210px;
}

.centered {
  margin: 0 auto;
}

.fullwidth {
  width: 100%;
  max-width: 100% !important;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import titlebar from "@/components/titlebar.vue";
import modalLocalizarContas from "@/components/financeiro/modalLocalizarContas.vue";
import moment from "moment";
import periodoHora from "@/components/periodoHora.vue";
import searchIdName from "@/components/searchIdName";

export default {
  data() {
    return {
      model: {
        id: null,
        descricao: null,
        observacoes: null,
        atualizarParcelasDesconto : false,
        unidades: null,
        especie: {
          id: 0,
        },
        contaCorrente: {
          id: 0,
        },
      },
      datas: [
        this.$moment().subtract(1, 'days').toDate(),
        this.$moment().toDate()
      ],
      status: 'emissao',
      checkedRows: [],
      contas: [],
      salvandoDados: false,
      isOpenModalData: false,
      localizarModalData: null,
      erroCadastro: [],
      especies: [],
      isLoading: false,      
      itemsSelecionados: [],
      items: [],
      totalContas: 0,
      baixar: null,
      idsExcluidos: [],
      temNotaFiscal: false,
      loteId: this.$route.params.id,
      notasFiscais: [],
    };
  },
  components: {
    titlebar,
    modalLocalizarContas,
    periodoHora,
    searchIdName
  },
  filters: {
    data: (valor) => {
      if (!valor) return null;

      return moment(valor).format("DD/MM/YYYY");
    },
  },
  created() {
    this.isLoading = true;
    let id = "";
    if (this.$route.params.id) {
      id = "?id=" + this.$route.params.id;
    }
    this.$http
      .get("/api/financeiro/ReceberParcelaLote" + id)
      .then((res) => {
        const model = res.data?.item;
        this.contas = res.data?.contasCorrentes;
        this.especies = res.data?.especies;
        this.bandeiras = res.data?.bandeira;

        if (!model.especie) {
          model.especie = {
            id: 0,
          };
        }
        model.contaCorrente = !model.contaCorrente ? {
          id: this.contas[0]?.value,
        } : model.contaCorrente;
        
        if (model.itens) {
          this.items = model.itens.map((i) => {
            return {
              ...i,
              dataReceber: i.receber?.data,
              notaFiscal: i.receber.notaFiscal,
              nome: i.contaCorrente?.nome,
              documento: i.receber?.id,
            };
          });
          this.recalcularTotal();
        }
        
        this.model = model;

        this.items.some(x => x.receber != null && x.receber.notaFiscal_Id) ? this.temNotaFiscal = true : this.temNotaFiscal = false;
        this.isLoading = false;
      })
      .catch((ex) => {
        console.error(ex);
        this.isLoading = false;
      });
  },
  computed: {
    titleStack() {
      return [
        "Faturamento",
        "Lote de Faturas",
        this.$route.params.id ?? this.$t('SISTEMA.NOVO')
      ];
    },
    ...mapState(["unidadeId", "config", "integradores"]),
    ...mapGetters(["isInRole"]),
  },
  watch: {
    itemsSelecionados: function (novoValor) {
      if (novoValor) {
        this.items.push(...novoValor);
        this.recalcularTotal();
      }
    },

  },
  methods: {
    onSubmit() {
      this.$forceUpdate();
      this.isLoading = true;
      const newItem = this.getItem();
      const idsExcluidos = this.idsExcluidos.length
        ? "?idsExcluidos=" + this.idsExcluidos.map(i => i.id).join(",")
        : "";     
      newItem.atualizarParcelasDesconto = this.model.atualizarParcelasDesconto;           
      this.$http
        .post(`/api/financeiro/ReceberParcelaLote`+idsExcluidos, newItem)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "lista-parcela-lote" });
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err);
          if (err.body?.errors) {
            Object.keys(err.body.errors).forEach((x) => {
              this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
            });
          } else {
            const mensagem =
              err.status === 500
                ? "Foi encontrado um erro no servidor ao salvar o registro"
                : "Erro ao salvar registro";
            this.$buefy.toast.open({
              duration: 5000,
              message: mensagem,
              type: "is-danger",
              queue: false,
            });
          }
        });
   
   
   },
    getItem() {
      const novoItem = {};
      novoItem.id = this.model.id;
      novoItem.descricao = this.model.descricao;
      novoItem.contaCorrente = this.contas?.find(
        (c) => c.value == this.model.contaCorrente?.id
      );
      novoItem.dataInicial = this.datas[0];
      novoItem.dataFinal = this.datas[1];
      novoItem.unidades = this.model.unidades;
      novoItem.dataCancelamento = this.model.dataCancelamento;
      novoItem.observacao = this.model.observacao;
      novoItem.desconto = this.model.desconto;      
      novoItem.situacaoLoteContasReceber = this.model.situacaoLoteContasReceber;
      novoItem.itens = this.items?.map((i) => {
        const valor = {
          ...i,
        };

        return valor;
      });

      if (this.model.especie) {
        novoItem.especie = this.model.especie;
      }

      return novoItem;
    },
    localizarContas() {
      const index = this.items.length ? this.items.length : 1;
      this.localizarModalData = null; 
      let filtrarPor = this.status;  
      let unidades = this.model.unidades;
      let dataInicial = this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss');
      let dataFinal = this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss');

      if (dataInicial) {
        dataInicial = `&dataInicial=${dataInicial}`;
      } else {
        dataInicial = "";
      }
      if (dataFinal) {
        dataFinal = `&dataFinal=${dataFinal}`;
      } else {
        dataFinal = "";
      }

      if(filtrarPor){
        filtrarPor = `filtrarPor=${filtrarPor}`
      }

      if(unidades != null){
        unidades = `unidade=${unidades}`;
      }

      const especie = this.model.especie?.id ? this.model.especie?.id : 0;
      this.isLoading = true;
      this.$http
        .get(
          `/api/financeiro/ListarParcelasLoteReceber?especieDePagamento=${especie}&${filtrarPor}&${unidades}${dataInicial}${dataFinal}&index=${index}&_=${new Date().getTime()}`
        )
        .then((res) => {
          //filtrando os dados quejá existem
          const idsSelecionados = this.items?.map((i) => i.id);
          this.localizarModalData = res.data?.filter(
            (i) => idsSelecionados.indexOf(i.id) < 0
          );
          this.isOpenModalData = true;
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
          this.isLoading = false;
        });
    },
    abrirReceber(item) {
      this.$router.push({
        name: "cadastro-fatura",
        params: { id: item.documento },
      });
    },
    removerItem(item) {
      if (this.model.id) {
        this.idsExcluidos.push(this.items?.find((i) => i.id == item.id));
      }
      this.items = this.items?.filter((i) => i.id !== item.id);
      this.recalcularTotal();
    },
    recalcularTotal() {
      this.totalContas = 0;
      this.items.forEach((v) => {
        this.totalContas += parseFloat(v.valor?.toString());
      });
      this.totalContas = this.totalContas.toFixed(2);
    },
    arquivos() {
      this.isLoading = true;
      this.$http
        .post(`/api/financeiro/GenerateFiles?loteReceberId=${this.model.id}`)
        .then((res) => {
          this.$buefy.toast.open({
            duration: 10000,
            message: res.data?.mensagem,
            type: "is-success",
            queue: false,
          });
          this.isLoading = false;
          this.$router.push({ name: "lista-parcela-lote" });
        })
        .catch((e) => {
          this.isLoading = false;
          console.error(e);
          this.$buefy.toast.open({
            duration: 5000,
            message: "Erro ao gerar arquivo",
            type: "is-danger",
            queue: false,
          });
        });
    },
    baixa() {
      this.baixar = {
        especie: {
          id: this.especies[1]?.id,
        },
        bandeira: {
          id: this.bandeiras[0]?.value,
        },
      };
      this.$forceUpdate();
    },
    aplicarDesconto() {

      this.$buefy.dialog.prompt({
          message: `Desconto`,
          inputAttrs: {
              type: 'text',
              placeholder: '',
              value: 0,
              title: 'O campo precisa ser informado'
          },
          trapFocus: true,
          confirmText: 'OK',
          cancelText: 'Cancelar',
          onConfirm: (value) => {
              const regex = /^[0-9.,%-]+$/;
                if (!regex.test(value)) {
                this.$buefy.toast.open({
                duration: 4000,
                message: `O valor inserido não é válido. Insira apenas números e %.`,
                type: "is-danger",
                queue: false,
              });
                return false;
              }

            
              this.model.desconto = value;

              if (this.model.desconto != null) {
                  const porcentagem = this.model.desconto.indexOf("%") != -1;
                  this.model.atualizarParcelasDesconto = (this.model.id);
                  if (porcentagem) {
                      this.model.desconto = this.model.desconto.replace("%", "").replace(",", ".");                      
                      let total = 0;
                      this.items?.forEach(p => {                          
                          p.valor = (parseFloat(p.valor) - (parseFloat(p.valor) * (parseFloat(this.model.desconto) / 100)));
                          total += parseFloat(p.valor);                                                        
                      });
                      this.model.desconto = (this.totalContas - total);
                      this.totalContas = total;
                      
                  } else {
                      this.model.desconto = this.model.desconto.replace(",", ".");
                      this.totalContas = parseFloat(this.totalContas) - parseFloat(this.model.desconto);
                      this.items?.forEach(p => {                          
                          p.valor = (parseFloat(p.valor) - (parseFloat(this.model.desconto) / this.items.length));                                                                                      
                      });                      
                  }
              }

              this.$forceUpdate();

          }
      })
            
        
    },  
    formatPrice(value) {
        const val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },      
    confirmarBaixar() {
      this.isLoading = true;
      try {
        const bandeira = this.baixar.bandeira?.id
            ? `&bandeiraId=${this.baixar.bandeira.id}`
            : "";
        const especie = this.baixar.especie?.id
            ? `&especieDePagamento=${this.baixar.especie.id}`
            : "&especieDePagamento=";
        this.$http
            .post(
            `/api/financeiro/ReceberParcelaLoteBaixar?id=${this.model.id}${bandeira}${especie}`
            )
            .then(() => {
              this.isLoading = false;
              this.$router.push({ name: "lista-parcela-lote" });
            })
            .catch((e) => {
            this.isLoading = false;
            console.error(e);
            this.$buefy.toast.open({
                duration: 5000,
                message: "Erro ao baixar",
                type: "is-danger",
                queue: false,
            });
            });
      }catch(e){
          this.isLoading = false;
      }
    },
    cancelarLote(loteId){      
      this.$http.get(`/api/financeiro/cancelarLote?loteId=${loteId}`)
      .then(() => {
        this.$router.back();

        this.$buefy.toast.open({
          duration: 2000,
          message: "Lote de faturas cancelado.",
          type: 'is-success',
          queue: false
        });
      })
    },
    fecharFatura() {
        this.$buefy.dialog.confirm({
            title: "Fatura",
            message: this.$t('LOTESCONTARECEBERCADASTRO.FECHARFATURAMSG'),
            confirmText: "Confirmar",
            type: "is-info",
            hasIcon: true,
            cancelText: "Cancelar",
            onConfirm: () => {
                const receberId = [];
                
                this.items.filter(x => x.receber.situacaoReceber === 1).forEach(x => {
                  receberId.push(x.documento);
                })

                this.$http.post('/api/financeiro/fecharFatura', receberId)
                .then(res => res.data)
                .then(data => {  
                  if(data.ok){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Fatura fechada!",
                        type: "is-danger",
                        queue: false,
                    });                  
                  }
                })
                .catch(e => {
                    console.error(e.body);
                });
            },
        });
    }, 
    enviarEmail(items){
      const contas = [];
      let documentosNaoEnviados = ""

      items.forEach(x => {
        if(x.receber.convenio.condicaoPagamento.emiteNotaFiscal || x.receber.convenio.condicaoPagamento.gerarBoleto){
          if(!Number.isInteger(x.receber.notaFiscal) || x.receber.notaFiscal == null ){
            if(x.statusBoleto != 'Registrado' && x.statusBoleto != 'Liquidado' && x.statusBoleto != 'Baixado'){
              documentosNaoEnviados += `${x.documento}, `     
            }
          }
        }
        
        if(!documentosNaoEnviados.includes(x.documento)) {
          contas.push({
            parcelaId: x.id,
            receberId: x.documento
          })  
        }
      })

      if(documentosNaoEnviados != "") {
        return this.$buefy.toast.open({
          duration: 6000,
          message: this.$t('LOTESCONTARECEBERCADASTRO.MENSAGEMNOTIFICAOENVIOEMAIL') + documentosNaoEnviados + this.$t('LOTESCONTARECEBERCADASTRO.MENSAGEMNOTIFICACAO'),
          type: 'is-info',
          queue: false
        });
      }
      
      if(contas.length > 0){
        this.$http.post('/api/financeiro/enviarEmailParcela', {
          contas
        })
        .then((res) => {
          this.$router.back();
          if(res.ok){
            this.$buefy.toast.open({
              duration: 2000,
              message: "E-mails encaminhados para fila de envios",
              type: 'is-success',
              queue: false
            });
          }else{
            this.$buefy.toast.open({
              duration: 2000,
              message: "Erro ao encaminhar e-mail para envio:" + res.message,
              type: 'is-danger',
              queue: false
            });
          }
        })
      }

    },
    emitirDocumentos() {
      const documentos = []

      this.checkedRows.filter(x =>  x.receber.situacaoReceber === 2 &&  (x.receber.convenio.condicaoPagamento.emiteNotaFiscal || x.receber.convenio.condicaoPagamento.gerarBoleto)).forEach(item => {
        documentos.push({
          receberId: item.documento,
          parcelaId: item.id,
          emiteNotaFiscal: item.receber.convenio.condicaoPagamento.emiteNotaFiscal,
          gerarBoleto: item.receber.convenio.condicaoPagamento.gerarBoleto,
          boletoEmitido: item.boletoId,
          notaEmitida: item.receber.notaFiscal_Id
        })
      });

      if(documentos != null && documentos.length > 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('LOTESCONTARECEBERCADASTRO.EMITIRLOTE'),
          type: 'is-success',
          queue: false
        });
      }
      else {
        return this.$buefy.toast.open({
          duration: 5000,
          message: this.$t('LOTESCONTARECEBERCADASTRO.NENHUMDOCUMENTOSELECIONADO'),
          type: 'is-danger',
          queue: false
        });
      }

      this.$http.post(`/api/financeiro/emitirLote?unidadeId=${this.unidadeId}`, {documentos})
      .catch((err) => {
          console.error(err);
      });
    },
    consultarNotaFiscal() {
        this.isLoading = true;
        this.notasFiscais = [];
        const consultar = []

        this.items.filter(x => x.receber.notaFiscal_Id != null).forEach(item => {
            consultar.push({
            receberId: item.documento,
            clienteId: item.receber.convenio.id,
            documentoId: item.receber.notaFiscal_Id,
            })
            this.$http.get(`/api/financeiro/consultar?documentoId=${item.receber.notaFiscal_Id}&clienteId=${item.receber.convenio.id}&receberId=${item.documento}&unidadeId=${this.unidadeId}`)
            .then((response) => {
                this.isLoading = false;
                if (response.body.error) {
                    this.erro = response.body.error.message;
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: "Erro ao consultar a nota fiscal" + this.erro,
                        type: "is-danger",
                        queue: false,
                    });
                } else {
                    this.notasFiscais.push(response.body[0]);
                }
            })
            .catch((err) => {
                this.isLoading = false;
                console.error(err);
                if (err.body?.errors) {
                    Object.keys(err.body.errors).forEach((x) => {
                        this.$store.commit("ADD_ERRO", x + ": " + err.body.errors[x]);
                    });
                } else {
                    const mensagem =
                        err.status === 500
                            ? "Foi encontrado um erro no servidor ao consultar nota fiscal"
                            : "Erro ao consultar nota fiscal";
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: mensagem,
                        type: "is-danger",
                        queue: false,
                    });
                }
            });
        });
    },
  },
};
</script>
