<template>
    <section>
        <div style="padding: 1rem; border-bottom: 1px solid black;">
        <span style="display: flex; align-items: center">
            <span style="color: grey;">Configurações / &nbsp; </span> 
            <strong> Faturamento</strong>
        </span>
        </div>
        <div class="columns">
                    <div class="column is-4">
                        <div v-if="isInRole('convenio-alterar') || isInRole('convenio-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('SISTEMA.CONVENIO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'convenios', query: { procurar: procurar.convenio }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.convenio"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'convenios' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('convenio-alterar') && 
                                integradores.filter(x => x.ativo && x.versao == 30).length == 0" 
                                    :to="{ name: 'convenio' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                     <div class="column is-4">
                        <div v-if="isInRole('tipoConvenio-alterar') || isInRole('tipoConvenio-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.TIPOCONVENIO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'tiposdeconvenios', query: { procurar: procurar.tipoConvenio }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.tipoConvenio"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tiposdeconvenios' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('tipoConvenio-alterar')" :to="{ name: 'tipodeconvenio' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div v-if="isInRole('tabelapreço-alterar') || isInRole('tabelapreço-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.TABELASPRECO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'tabelasprecos', query: { procurar: procurar.tabelapreco }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.tabelapreco"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tabelasprecos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('tabelapreço-alterar')" :to="{ name: 'novatabelapreco' }"  class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="columns">
                    <div class="column is-4">
                        <div v-if="isInRole('valorescbphm-alterar') || isInRole('valorescbphm-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.VALORES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'valorescbhpm', query: { procurar: procurar.valorescbphm }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.valorescbphm"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'valorescbhpm' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('valorescbphm-alterar')" :to="{ name: 'valorcbhpm' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>  
                    <div class="column is-4" v-if="isInRole('reducaoacrescimo-alterar') || isInRole('reducaoacrescimo-consultar')">
                        <div class="panel">
                            <p class="panel-heading background-1">
                               {{$t('MANUTENCAOLISTAR.REDUCAOACRESCIMO')}} 
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'reducoesacrescimos', query: { procurar: procurar.reducaoacrescimo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.reducaoacrescimo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'reducoesacrescimos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}} 
                                </router-link>
                                <router-link v-if="isInRole('reducaoacrescimo-alterar')" :to="{ name: 'reducaoacrescimo' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}} 
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                    <div v-if="isInRole('configTISS-alterar') || isInRole('configTISS-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.CONFIGTISS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'configuracoestiss', query: { procurar: procurar.configTISS }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.configTISS"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'configuracoestiss' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('configTISS-alterar')" :to="{ name: 'configuracaotiss' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                </div>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {},
                examesValidacao: false,
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            verificarExameValidacao() {                
                this.$http.get('api/Dashboard/PossuiExameValidacao')
                    .then(res => {
                        this.examesValidacao = res.data
                    });
            }                       
        },
        mounted() {                
            this.verificarExameValidacao();                                    

        }         
    }
</script>