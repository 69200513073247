<template>
    <section>
        <div style="padding: 1rem; border-bottom: 1px solid black;">
        <span style="display: flex; align-items: center">
            <span style="color: grey;">Configurações / &nbsp; </span> 
            <strong> Estoque</strong>
        </span>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('estoque-alterar') || isInRole('estoque-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('ESTOQUE.ESTOQUES')}}
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'estoques', query: { procurar: procurar.estoque }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                        v-model="procurar.estoque"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'estoques' }" class="button is-light is-fullwidth">
                            {{$t('ESTOQUE.LISTARTODOS')}}
                        </router-link>
                         <router-link v-if="isInRole('estoque-alterar')" :to="{ name: 'cadastroestoque' }" class="button is-success is-fullwidth">
                            {{$t('ESTOQUE.INCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('unidadeDeMedida-alterar') || isInRole('unidadeDeMedida-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Unidades de medida
                    </p>
                    <form action="/Estoque/ListarUnidadesDeMedida" method="get" class="panel-block">
                        <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'unidadesdemedida' }" class="button is-light is-fullwidth">
                            {{$t('ESTOQUE.LISTARTODOS')}}
                        </router-link>
                         <router-link v-if="isInRole('unidadeDeMedida-alterar')" :to="{ name: 'cadastrounidadedemedida' }" class="button is-success is-fullwidth">
                            {{$t('ESTOQUE.INCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('categoria-alterar') || isInRole('categoria-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Categorias
                    </p>
                    <form action="/Estoque/ListarCategorias" method="get" class="panel-block">
                        <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                     <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'categorias' }" class="button is-light is-fullwidth">
                            {{$t('ESTOQUE.LISTARTODOS')}}
                        </router-link>
                         <router-link v-if="isInRole('unidadeDeMedida-alterar')" :to="{ name: 'cadastrocategoria' }" class="button is-success is-fullwidth">
                            {{$t('ESTOQUE.INCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div v-if="isInRole('marca-alterar') || isInRole('marca-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Marcas
                    </p>
                    <form action="/Estoque/ListarMarcas" method="get" class="panel-block">
                        <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'marcas' }" class="button is-light is-fullwidth">
                            {{$t('ESTOQUE.LISTARTODOS')}}
                        </router-link>
                         <router-link v-if="isInRole('marca-alterar')" :to="{ name: 'cadastromarca' }" class="button is-success is-fullwidth">
                            {{$t('ESTOQUE.INCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('consumivel-alterar') || isInRole('consumivel-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Consumível
                    </p>
                    <form action="/Estoque/ListarConsumiveis" method="get" class="panel-block">
                        <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'consumiveis' }" class="button is-light is-fullwidth">
                            {{$t('ESTOQUE.LISTARTODOS')}}
                        </router-link>
                         <router-link v-if="isInRole('consumivel-alterar')" :to="{ name: 'cadastroconsumivel' }" class="button is-success is-fullwidth">
                            {{$t('ESTOQUE.INCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column">
                <div v-if="isInRole('lote-alterar') || isInRole('lote-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        Lotes
                    </p>
                    <form action="/Estoque/ListarLotes" method="get" class="panel-block">
                        <b-input :placeholder="$t('ESTOQUE.PROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'lotes' }" class="button is-light is-fullwidth">
                            {{$t('ESTOQUE.LISTARTODOS')}}
                        </router-link>
                         <router-link v-if="isInRole('consumivel-alterar')" :to="{ name: 'cadastrolote' }" class="button is-success is-fullwidth">
                            {{$t('ESTOQUE.INCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {},
                examesValidacao: false,
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            verificarExameValidacao() {                
                this.$http.get('api/Dashboard/PossuiExameValidacao')
                    .then(res => {
                        this.examesValidacao = res.data
                    });
            }                       
        },
        mounted() {                
            this.verificarExameValidacao();                                    

        }         
    }
</script>