<template>
    <article v-if="chave != null" class="panel mr-3 mb-0">
        <div class="panel-heading has-text-centered">
            {{$t('MODALCOPIAREXAMES.COPIARCONFIGS')}}
        </div>
        <div class="panel-block">
            <b-button type.native="button" type="is-primary" expanded icon-left="content-duplicate" @click="abrirCopiarExame" :title="$t('MODALCOPIAREXAMES.COPIARCONFIGS')"></b-button>
        </div>
    </article>
</template>

<script>
    import modalCopiarExame from './atendimento/modalCopiarExame.vue'

    export default {
        props: {
            chave: Number,
            loadConfigFunction: {
                type: Function,
                required: true
            }
       },
        data() {
            return {
                lista: []
            }
        },
        created(){
            if(this.$props.toggle){
                this.abrirCopiarExame();
            }
        },
        methods: {
            abrirCopiarExame() {
                this.$buefy.modal.open({
                        parent: this,
                        component: modalCopiarExame,
                        props: {
                            selecionado: this.chave 
                        },
                        events: {
                            loadConfigFunction: this.loadConfigFunction
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })
            }   
        }
    }
</script>