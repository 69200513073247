<template>
    <section>
        <div style="padding: 1rem; border-bottom: 1px solid black;">
        <span style="display: flex; align-items: center">
            <span style="color: grey;">Configurações / &nbsp; </span> 
            <strong> Agenda</strong>
        </span>
        </div>
        <div class="columns">
        <div class="column is-4">
                    <div v-if="isInRole('feriado-alterar') || isInRole('feriado-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.FERIADO')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'feriados', query: { procurar: procurar.feriado }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.feriado"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'feriados' }" class="button is-light is-fullwidth">
                               {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('feriado-alterar')" :to="{ name: 'feriado' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div v-if="isInRole('regras-agendamento-alterar') || isInRole('regras-agendamento-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.REGRASAGENDAMENTO')}}
                            </p>                                                
                            <form @submit.stop.prevent="$router.push({ name: 'regrasdeagendamento', query: { procurar: procurar.regrasdeAgendamento }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.regrasdeAgendamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'regrasdeagendamento' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('regras-agendamento-alterar')" :to="{ name: 'regradeagendamento' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                </div>
                 <div class="column is-4">
                        <div v-if="isInRole('tipos-agendamento-alterar') || isInRole('tipos-agendamento-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.TIPOAGENDAMENTO')}}
                            </p>                                                
                            <form @submit.stop.prevent="$router.push({ name: 'tiposdeagendamento', query: { procurar: procurar.tiposdeAgendamento }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.tiposdeAgendamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tiposdeagendamento' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('tipos-agendamento-alterar')" :to="{ name: 'tipoagendamento' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                </div>  
                </div> 
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {},
                examesValidacao: false,
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            verificarExameValidacao() {                
                this.$http.get('api/Dashboard/PossuiExameValidacao')
                    .then(res => {
                        this.examesValidacao = res.data
                    });
            }                       
        },
        mounted() {                
            this.verificarExameValidacao();                                    

        }         
    }
</script>