<template>
    <section>
        <div style="padding: 1rem; border-bottom: 1px solid black;">
        <span style="display: flex; align-items: center">
            <span style="color: grey;">Configurações / &nbsp; </span> 
            <strong> Financeiro</strong>
        </span>
        </div>
        <div class="columns">
                    <div class="column is-4">
                        <div v-if="isInRole('fornecedor-alterar') || isInRole('fornecedor-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.FORNECEDOR')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'fornecedores', query: { procurar: procurar.fornecedor }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.fornecedor"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>                           
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'fornecedores' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('fornecedor-alterar')" :to="{ name: 'fornecedor' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div v-if="isInRole('repassemedico-alterar') || isInRole('repassemedico-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.REPASSEMEDICO')}}
                            </p>                            
                            <form @submit.stop.prevent="$router.push({ name: 'repassesmedicos', query: { procurar: procurar.amostrasStatus }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.amostrasStatus"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'repassesmedicos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('repassemedico-alterar')" :to="{ name: 'repassemedico' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}} 
                                </router-link>
                            </div>
                        </div>                        
                    </div>  
                    
                    <div class="column is-4">
                    <div v-if="isInRole('condicaopagamento-alterar') || isInRole('condicaopagamento-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.CONDPAGAMENTO')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'condicoespagamentos', query: { procurar: procurar.condicaopagamento }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.condicaopagamento"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'condicoespagamentos' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('condicaopagamento-alterar')" :to="{ name: 'condicaopagamento' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                </div>
                <div class="columns">
                <div class="column is-4">
                    <div v-if="isInRole('especie-pagamento-alterar') || isInRole('especie-pagamento-consultar')" class="panel">
                        <p class="panel-heading background-1">
                           {{$t('MANUTENCAOLISTAR.ESPECIEPAGAMENTO')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'especiespagamentos', query: { procurar: procurar.especiepagamento }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.especiepagamento"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'especiespagamentos' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('especie-pagamento-alterar')" :to="{ name: 'especiepagamento' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                <div v-if="isInRole('rateio-alterar') || isInRole('rateio-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.RATEIO')}} 
                    </p>
                    <form action="/configuracoes/rateios" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                         <router-link :to="{ name: 'rateios' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('rateio-alterar')" :to="{ name: 'rateio' }" class="button is-success is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}} 
                        </router-link>
                    </div>
                </div> 
                </div>
                <div class="column is-4">
                <div v-if="isInRole('contaCorrente-alterar') || isInRole('contaCorrente-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.CONTACORRENTESCARTAO')}}
                    </p>
                    <form action="/financeiro/lista-conta-corrente" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                 name="procurar"
                                 icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'lista-conta-corrente' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('contaCorrente-alterar')" :to="{ name: 'conta-corrente' }" class="button is-success is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}}
                        </router-link>
                    </div>
                </div>
            </div>
            </div>
            <div class="columns">
            <div class="column is-4">
                <div v-if="isInRole('bandeira-alterar') || isInRole('bandeira-consultar')" class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.BANDEIRAS')}} 
                    </p>
                    <form @submit.stop.prevent="$router.push({ name: 'bandeiras', query: { procurar: procurar }})" method="get" class="panel-block">
                        <b-input :placeholder="$t('MENUFINANCEIRO.PLACEHOLDERPROCURAR')"
                                    name="procurar" 
                                    icon="magnify"></b-input>
                    </form>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'bandeiras' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNLISTARTODOS')}} 
                        </router-link>
                        <router-link v-if="isInRole('bandeira-alterar')" :to="{ name: 'bandeira' }" class="button is-success is-fullwidth">
                            {{$t('MENUFINANCEIRO.BTNINCLUIR')}} 
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column is-4">
                <div class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.DRETITULO')}} 
                    </p>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'dre-lista' }" class="button is-light is-fullwidth">
                            {{$t('SISTEMA.LISTAR')}}
                        </router-link>
                        <router-link v-if="isInRole('pagar-parcela-lote-alterar')" :to="{ name: 'dre' }" class="button is-success is-fullwidth">
                            {{$t('SISTEMA.INCLUIR')}} 
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="column is-4">
                <div  class="panel">
                    <p class="panel-heading background-1">
                        {{$t('MENUFINANCEIRO.CENTRORECEITASEDESPESAS')}}
                    </p>
                    <div v-if="isInRole('categoriaFinanceira-alterar') || isInRole('categoriaFinanceira-consultar')" class="panel-block is-justified-between">
                        <router-link :to="{ name: 'categoria-financeira' }" class="button is-light  is-fullwidth">
                            {{$t('MENUFINANCEIRO.CATEGORIAFINANCEIRA')}} 
                        </router-link>
                    </div>
                    <div class="panel-block is-justified-between">
                        <router-link :to="{ name: 'centro-resultados' }" class="button is-light is-fullwidth">
                            {{$t('MENUFINANCEIRO.CENTRORESULTADOS')}} 
                        </router-link>
                    </div>
                </div>
            </div>
            </div>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {},
                examesValidacao: false,
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            verificarExameValidacao() {                
                this.$http.get('api/Dashboard/PossuiExameValidacao')
                    .then(res => {
                        this.examesValidacao = res.data
                    });
            }                       
        },
        mounted() {                
            this.verificarExameValidacao();                                    

        }         
    }
</script>