<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-loading :active.sync="isLoading"></b-loading>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">
                            <div class="columns">
                                <div class="column is-3">
                                    <ValidationProvider rules="required" :name="$t('CONCILIACAOBANCARIA.SALDOANTERIOR')" v-slot="{ errors, valid }">
                                        <b-field :label="$t('CONCILIACAOBANCARIA.SALDOANTERIOR')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input
                                                type="number" 
                                                step=".01"
                                                v-model="previousBalance"
                                            />
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column">
                                    <ValidationProvider rules="required|ext:ofx" name="arquivo" v-slot="{ errors, valid, validate }">
                                        <b-field :label="$t('CONCILIACAOBANCARIA.ARQUIVO')"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                    :message="errors">
                                            <b-button expanded @click="$refs.fileOfx.click()">{{fileOfx.name || 'Selecione o arquivo ofx...'}}</b-button> 
                                            <div class="file has-name fullwidth">
                                                <label class="file-label fullwidth">
                                                    <input class="file-input" type="file" name="arquivo" ref="fileOfx"  @change="uploadFileOfx($event) || validate($event)">
                                                </label>
                                            </div>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div> 
                        </article>  
                    </div> 
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                        
                        <b-button
                            type="is-danger"
                            native-type="button"
                            icon-left="close-circle"
                            @click="$router.back()"
                        >
                            {{$t('CONCILIACAOBANCARIA.CANCELAR')}}
                        </b-button>
                        <b-button
                            type="is-success"
                            :loading="isLoading"
                            native-type="submit"
                            icon-left="check-circle"
                        >
                            {{$t('CONCILIACAOBANCARIA.IMPORTAROFX')}}
                        </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>                                 
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {},
                total: 0,
                isLoading: false,
                page: 1,
                perPage: 20,
                previousBalance: null,
                fileOfx: {
                    name: null
                }
            };
        },
        components: {
            titlebar 
        },
        computed: {
            titleStack() {
                return [
                    'Financeiro',
                    this.$t('CONCILIACAOBANCARIA.CONCILIACAO'),
                    this.$t('CONCILIACAOBANCARIA.IMPORTAROFX')
                ]
            },          
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            changePage(page) {
                this.page = page
            },
            uploadFileOfx(event) {
                this.fileOfx = event.target.files[0]
            },
            onSubmit() {
                const formData = new FormData()
                formData.append('arquivo', this.fileOfx)
                formData.append('saldoAnterior', this.previousBalance.replace(/[,.]/g, ''))
                this.isLoading = true
                this.$http
                .post(`/api/ConciliacaoBancaria/ImportarExtratoOfx`, formData)
                .then(() => {
                    this.isLoading = false
                    this.$router.push({name: 'importacoes-ofx'}) 
                })
                .catch((error) => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.body.mensagem,
                        type: "is-danger",
                        queue: false,
                    });
                    this.isLoading = false
                    console.error(error)
                })
            }
        }
    }
</script>