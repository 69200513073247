<template>
    <section>
        <div style="padding: 1rem; border-bottom: 1px solid black;">
        <span style="display: flex; align-items: center">
            <span style="color: grey;">Configurações / &nbsp; </span> 
            <strong> Empresa</strong>
        </span>
        </div>
        <div class="columns">
        <div class="column is-4">                        
                        <div v-if="isInRole('setor-alterar') || isInRole('setor-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.SETORES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'setores', query: { procurar: procurar.setor }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.setor"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'setores' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('setor-alterar')" :to="{ name: 'setor' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="column is-4">
                        <div v-if="isInRole('departamento-alterar') || isInRole('departamento-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.DEPARTAMENTOS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'departamentos', query: { procurar: procurar.departamento }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.departamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'departamentos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('departamento-alterar')" :to="{ name: 'departamento' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="column is-4">
                        <div v-if="isInRole('medico-alterar') || isInRole('medico-consultar')" class="panel">
                            <p class="panel-heading background-1">
                               {{$t('MANUTENCAOLISTAR.MEDICOS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'medicos', query: { procurar: procurar.medico }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.medico"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'medicos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                                  </router-link>
                                <router-link v-if="isInRole('medico-alterar')" :to="{ name: 'medico' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="columns">
                <div class="column is-4">
                    <div v-if="isInRole('unidade-alterar') || isInRole('unidade-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.UNIDADES')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'unidades', query: { procurar: procurar.unidade }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.unidade"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'unidades' }" class="button is-light is-fullwidth">
                               {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('unidade-alterar')" :to="{ name: 'unidade' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div v-if="isInRole('guiaLocal-alterar') || isInRole('guiaLocal-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.LOCAIS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'locais', query: { procurar: procurar.guiaLocal }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.guiaLocal"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'locais' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('guiaLocal-alterar')" :to="{ name: 'local' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column is-4">
                    <div v-if="isInRole('tipoDeLocal-alterar') || isInRole('tipoDeLocal-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.TIPOSLOCAIS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'tiposdelocais', query: { procurar: procurar.tipoDeLocal }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.tipoDeLocal"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'tiposdelocais' }" class="button is-light is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('tipoDeLocal-alterar')" :to="{ name: 'tipodelocal' }" class="button is-success is-fullwidth">
                                {{$t('MANUTENCAOLISTAR.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>   
                

            </div>
            <div class="column is-4">
                        <div v-if="isInRole('motivo-alterar') || isInRole('motivo-consultar')" class="panel">
                            <p class="panel-heading background-1">
                               {{$t('MANUTENCAOLISTAR.MOTIVOS')}} 
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'motivos', query: { procurar: procurar.motivo }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('MANUTENCAOLISTAR.PROCURAR')"
                                         v-model="procurar.motivo"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'motivos' }" class="button is-light is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.LISTARTODOS')}} 
                                </router-link>
                                <router-link v-if="isInRole('motivo-alterar')" :to="{ name: 'motivo' }" class="button is-success is-fullwidth">
                                    {{$t('MANUTENCAOLISTAR.INCLUIR')}} 
                                </router-link>
                            </div>
                        </div>                        
                    </div>     
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {},
                examesValidacao: false,
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores'
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            verificarExameValidacao() {                
                this.$http.get('api/Dashboard/PossuiExameValidacao')
                    .then(res => {
                        this.examesValidacao = res.data
                    });
            }                       
        },
        mounted() {                
            this.verificarExameValidacao();                                    

        }         
    }
</script>