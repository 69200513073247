<template>
    <section>
        <titlebar :title-stack="titleStack">

        </titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">
                    <div class="tile is-parent is-vertical is-12">
                        <article class="tile is-child box">

                            <div class="columns">
                                <div class="column is-10">
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PESQUISACADASTRO.NOME')"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                 :message="errors">
                                            <b-input type="text" v-model="model.nome" maxlength="100" :has-counter="true"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-2">
                                    <ValidationProvider rules="required" name="Destino Pesquisa" v-slot="{ errors, valid }">
                                        <b-field :label="$t('PESQUISACADASTRO.DESTINO')"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-select placeholder="Selecione o destino" v-model="model.destinoPesquisa">
                                                <option value="Nenhum">{{$t('PESQUISACADASTRO.NENHUM')}}</option>
                                                <option value="Paciente">{{$t('SISTEMA.PACIENTE')}}</option>
                                                <option value="Medico">{{$t('SISTEMA.SOLICITANTE')}}</option>
                                                <option value="Convenio">{{$t('SISTEMA.CONVENIO')}}</option>
                                                <option value="Local">{{$t('PESQUISACADASTRO.LOCAL')}}</option>
                                                <option value="Recepção">{{$t('PESQUISACADASTRO.RECEPCAO')}}</option>
                                                <option value="Coleta">{{$t('PESQUISACADASTRO.COLETA')}}</option>
                                                <option value="Triagem">{{$t('PESQUISACADASTRO.TRIAGEM')}}</option>
                                                <option value="Execucao">{{$t('PESQUISACADASTRO.EXECUCAO')}}</option>
                                            </b-select>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <b-switch v-model="model.obrigatorio">
                                            {{$t('PESQUISACADASTRO.OBGT')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-switch v-model="model.compartilharRespostaGuia">
                                            {{$t('PESQUISACADASTRO.COMPARTILHARGUIAS')}}
                                        </b-switch>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="field">
                                        <b-switch v-model="model.compartilharRespostaExame">
                                            {{$t('PESQUISACADASTRO.COMPARTILHAREXAMES')}}
                                        </b-switch>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <article class="tile is-child box">
                            <label class="label">{{$t('PESQUISACADASTRO.PERGUNTAS')}}</label>
                            <section v-for="(pergunta, i) in model.perguntas" :key="pergunta.id || i">
                                <div class="columns">
                                    <div class="column is-2">
                                        <b-field>
                                            <p class="control">
                                                <button @click="deletaPergunta(i)" class="button is-danger"><i class="mdi mdi-close-box-outline"></i></button>
                                            </p>
                                            <b-input type="text" v-model="pergunta.id" readonly disabled></b-input>
                                        </b-field>
                                    </div>
                                    <div class="column">
                                        <ValidationProvider rules="required" name="texto" v-slot="{ errors, valid }">
                                            <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                     :message="errors">
                                                <b-input type="text" v-model="pergunta.texto" maxlength="100" :has-counter="false"></b-input>
                                            </b-field>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column is-11 is-offset-1 box">
                                        <label class="label">{{$t('PESQUISACADASTRO.RESPOSTAS')}}</label>
                                        <div class="columns" v-for="(perguntaItem, j) in pergunta.perguntaItens" :key="perguntaItem.id || j">
                                            <div class="column is-1">
                                                <b-field>
                                                    <p class="control">
                                                        <button @click="deletaPerguntaItem(i, j)" class="button is-danger"><i class="mdi mdi-close-box-outline"></i></button>
                                                    </p>
                                                    <b-input type="text" v-model="perguntaItem.id" readonly disabled></b-input>
                                                </b-field>
                                            </div>
                                            <div class="column is-4">
                                                <ValidationProvider rules="required" name="texto" v-slot="{ errors, valid }">
                                                    <b-field :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                                             :message="errors">
                                                        <b-input type="text" v-model="perguntaItem.texto" maxlength="100" :has-counter="false"></b-input>
                                                    </b-field>
                                                </ValidationProvider>
                                            </div>
                                            <div class="column">
                                                <div class="field">
                                                    <b-switch 
                                                        v-model="perguntaItem.aceitaDescritivo"
                                                        :disabled="pergunta.perguntaItens.length >= 2"
                                                    >
                                                       {{$t('PESQUISACADASTRO.LIVRE')}}
                                                    </b-switch>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="field">
                                                    <b-switch 
                                                        v-model="perguntaItem.aceitaData"
                                                        :disabled="pergunta.perguntaItens.length >= 2"
                                                    >
                                                       {{$t('PESQUISACADASTRO.DATA')}} 
                                                    </b-switch>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="field">
                                                    <b-switch 
                                                        v-model="perguntaItem.aceitaArquivo"
                                                        :disabled="pergunta.perguntaItens.length >= 2"
                                                    >
                                                        {{$t('PESQUISACADASTRO.ARQV')}} 
                                                    </b-switch>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="field"  @change="handleChange(perguntaItem, true, false)">
                                                    <b-switch 
                                                        v-model="perguntaItem.somenteNumerico" 
                                                        :disabled="pergunta.perguntaItens.length >= 2"
                                                    >
                                                        {{$t('PESQUISACADASTRO.NUM')}} 
                                                    </b-switch>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="field"  @change="handleChange(perguntaItem, false, true)">
                                                    <b-switch  
                                                            v-model="perguntaItem.somenteInteiro" 
                                                            :disabled="pergunta.perguntaItens.length >= 2"
                                                    >
                                                        {{$t('PESQUISACADASTRO.SOMENTEINTEIRO')}} 
                                                    </b-switch>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column has-text-centered">
                                                <b-tooltip 
                                                        :active="pergunta.perguntaItens.length >= 1 
                                                            && pergunta.perguntaItens.some(item => (item.aceitaDescritivo || item.somenteInteiro || item.somenteNumerico 
                                                            || item.aceitaArquivo || item.aceitaData))" :label="$t('PESQUISACADASTRO.CRIARPERGUNTA')" 
                                                        multilined
                                                    >
                                                    <b-button :disabled="pergunta.perguntaItens.length >= 1 
                                                        && pergunta.perguntaItens.some(item => (item.aceitaDescritivo || item.somenteInteiro || item.somenteNumerico 
                                                        || item.aceitaArquivo || item.aceitaData))" 
                                                    type="is-success"
                                                    native-type="button"
                                                    @click="novaPerguntaItem(i)"
                                                    >
                                                        {{$t('PESQUISACADASTRO.INCLUIRRESP')}} 
                                                    </b-button>
                                                </b-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </section>
                            <div class="columns">
                                <div class="column has-text-centered">
                                    <b-button type="is-success"
                                              native-type="button"
                                              @click="novaPergunta()">
                                        {{$t('PESQUISACADASTRO.INCLUIRPERGUNTA')}} 
                                    </b-button>
                                </div>
                            </div>
                        </article>
                        <div class="columns">
                            <div class="column is-one-third">
                                <article class="tile is-child box">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            {{$t('PESQUISACADASTRO.CODINTEG')}}
                                        </div>
                                        <div class="panel-block">
                                            <table class="table is-bordered is-striped is-fullwidth">
                                                <thead>
                                                    <tr>
                                                        <th>{{$t('PESQUISACADASTRO.TIPO')}}</th>
                                                        <th>{{$t('PESQUISACADASTRO.CODIGO')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in model.codigosIntegrador" v-bind:key="index">
                                                        <td>
                                                            <b-select :placeholder="$t('PESQUISACADASTRO.INTEG')" v-model="item.integradorId">
                                                                <option v-for="option in integradores"
                                                                        :value="option.id"
                                                                        :key="option.id">
                                                                    {{ option.nome }}
                                                                </option>
                                                            </b-select>
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.codigo">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="panel-block">
                                            <button type="button" class="button is-success is-fullwidth" @click="addCodigo">
                                                {{$t('PESQUISACADASTRO.ADDCODIGO')}}
                                            </button>
                                        </div>
                                    </article>
                                </article>
                            </div>
                            <div class="column is-one-third">
                                <article class="tile is-child box">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            {{$t('PESQUISACADASTRO.CODINTEGPEG')}}
                                        </div>
                                        <div class="panel-block">
                                            <table class="table is-bordered is-striped is-fullwidth">
                                                <thead>
                                                    <tr>
                                                        <th>{{$t('PESQUISACADASTRO.TIPO')}}</th>
                                                        <th>{{$t('PESQUISACADASTRO.PERGUNTAS')}}</th>
                                                        <th>{{$t('PESQUISACADASTRO.CODIGO')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in codigosIntegradorPergunta" v-bind:key="index">
                                                        <td>
                                                            <b-select :placeholder="$t('PESQUISACADASTRO.INTEG')" v-model="item.integradorId">
                                                                <option v-for="option in integradores"
                                                                        :value="option.id"
                                                                        :key="option.id">
                                                                    {{ option.nome }}
                                                                </option>
                                                            </b-select>
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.pergunta.id">
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.codigo">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="panel-block">
                                            <button type="button" class="button is-success is-fullwidth" @click="addCodigoPergunta">
                                                {{$t('PESQUISACADASTRO.ADDCODIGO')}}
                                            </button>
                                        </div>
                                    </article>
                                </article>
                            </div>
                            <div class="column is-one-third">
                                <article class="tile is-child box">
                                    <article class="panel">
                                        <div class="panel-heading has-text-centered">
                                            {{$t('PESQUISACADASTRO.CODINTEGRES')}}
                                        </div>
                                        <div class="panel-block">
                                            <table class="table is-bordered is-striped is-fullwidth">
                                                <thead>
                                                    <tr>
                                                        <th>{{$t('PESQUISACADASTRO.TIPO')}}</th>
                                                        <th>{{$t('PESQUISACADASTRO.PEGITEM')}}</th>
                                                        <th>{{$t('PESQUISACADASTRO.CODIGO')}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in codigosIntegradorPerguntaItem" v-bind:key="index">
                                                        <td>
                                                            <b-select :placeholder="$t('PESQUISACADASTRO.INTEG')" v-model="item.integradorId">
                                                                <option v-for="option in integradores"
                                                                        :value="option.id"
                                                                        :key="option.id">
                                                                    {{ option.nome }}
                                                                </option>
                                                            </b-select>
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.perguntaItem.id">
                                                        </td>
                                                        <td>
                                                            <input class="input" type="text" v-model="item.codigo">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="panel-block">
                                            <button type="button" class="button is-success is-fullwidth" @click="addCodigoPerguntaItem">
                                                {{$t('PESQUISACADASTRO.ADDCODIGO')}}
                                            </button>
                                        </div>
                                    </article>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('PESQUISACADASTRO.CANCELAR')}}
                            </b-button>
                            
                            <b-button type="is-success"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                {{$t('PESQUISACADASTRO.SALVAR')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {
                    id: 0,
                    nome: null,
                    obrigatorio: false,
                    destinoPesquisa: null,
                    compartilharRespostaExame: false,
                    compartilharRespostaGuia: false,
                    dataCriacao: new Date(),
                    perguntas: []
                },
                codigosIntegradorPergunta: [],
                codigosIntegradorPerguntaItem: [],
                salvandoDados: false
            }
        },
        components: {
            titlebar,
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/pesquisa/pesquisa?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;

                        for (let i = 0; i < this.model.perguntas.length; i++) {
                            for (let j = 0; j < this.model.perguntas[i].codigosIntegrador.length; j++) {
                                this.codigosIntegradorPergunta.push({
                                    integradorId: this.model.perguntas[i].codigosIntegrador[j].integradorId,
                                    pergunta: { id: this.model.perguntas[i].id },
                                    codigo: this.model.perguntas[i].codigosIntegrador[j].codigo
                                });
                            }

                            for (let j = 0; j < this.model.perguntas[i].perguntaItens.length; j++) {
                                for (let k = 0; k < this.model.perguntas[i].perguntaItens[j].codigosIntegrador.length; k++) {
                                    this.codigosIntegradorPerguntaItem.push({
                                        integradorId: this.model.perguntas[i].perguntaItens[j].codigosIntegrador[k].integradorId,
                                        perguntaItem: { id: this.model.perguntas[i].perguntaItens[j].id },
                                        codigo: this.model.perguntas[i].perguntaItens[j].codigosIntegrador[k].codigo
                                    });
                                }
                            }
                        }
                    })
            }
        },
        computed: {
            titleStack() {
                return [
                    this.$t('PESQUISACADASTRO.MANUTENCAO'),
                    this.$t('PESQUISACADASTRO.PESQUISA'),
                    this.$route.params.id ?? this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([
                'unidadeId',
                'config',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
            onSubmit() {

                this.salvandoDados = true;
                if(this.model.destinoPesquisa == 'Triagem') {
                    this.model.obrigatorio = true;
                }
                
                const salvarPesquisa = {
                    pesquisa: this.model,
                    codigosIntegradorPergunta: this.codigosIntegradorPergunta,
                    codigosIntegradorPerguntaItem: this.codigosIntegradorPerguntaItem
                };

                this.$http.post('/api/pesquisa/pesquisa', salvarPesquisa)
                    .then(() => {
                        this.salvandoDados = false;
                        this.$router.push({ name: 'pesquisas' })
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('PESQUISACADASTRO.ERROSALVAR') + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            },
            addCodigoPergunta() {
                this.codigosIntegradorPergunta.push({
                    integradorId: null,
                    pergunta: {
                        id: null
                    },
                    codigo: null
                });
            },
            addCodigoPerguntaItem() {
                this.codigosIntegradorPerguntaItem.push({
                    integradorId: null,
                    perguntaItem: {
                        id: null
                    },
                    codigo: null
                });
            },
            novaPerguntaItem(index) {
                this.model.perguntas[index].perguntaItens.push({
                    id: 0,
                    texto: null,
                    aceitaDescricao: false,
                    aceitaData: false,
                    aceitaArquivo: false,
                    somenteNumerico: false,
                    somenteInteiro: false,
                    codigosIntegrador: []
                });
            },
            novaPergunta() {
                this.model.perguntas.push({
                    id: 0,
                    texto: null,
                    codigosIntegrador: [],
                    perguntaItens: [{
                        id: 0,
                        texto: null,
                        aceitaDescricao: false,
                        aceitaData: false,
                        aceitaArquivo: false,
                        somenteNumerico: false,
                        somenteInteiro: false,
                        codigosIntegrador: []
                    }]
                });
            },
            deletaPergunta(index) {
                this.model.perguntas.splice(index, 1);
            },
            deletaPerguntaItem(indexPergunta, indexItem) {
                this.model.perguntas[indexPergunta].perguntaItens.splice(indexItem, 1);
            },
            handleChange(perguntaItem, numerico, inteiro) {
                
                if(numerico ) {
                    perguntaItem.somenteInteiro = false
                }

                if(inteiro) {
                    perguntaItem.somenteNumerico = false;
                }
            }
        }
    }
</script>