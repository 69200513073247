<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column">
                            <b-field :label="$t('LOTEAMOSTRA.NOME')">
                                <b-input type="text" readonly v-model="model.descricao"></b-input>
                            </b-field>
                        </div>                            
                    </div>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-6">
                            <div id="mapa" style="height: 400px;"></div>
                        </div>  
                        <div class="column is-6">
                            <div v-for="(log, index) in model.log" v-bind:key="index" class="panel-block is-justified-between">
                                <span class="label">{{log.acao}}</span>
                                <small>
                                    {{ log.dataHora  | moment('DD/MM/YYYY HH:mm:ss') }} - {{ log.usuarioDescricao }}
                                </small>
                                <div class="columns" v-if="log.temperatura || log.umidade">
                                    <div class="column" v-if="log.temperatura">{{$t('LOTEAMOSTRA.TEMPERATURA')}} <b>{{log.temperatura}} C°</b></div>
                                    <div class="column has-text-right" v-if="log.umidade">{{$t('LOTEAMOSTRA.UMIDADE')}} <b>{{log.umidade}} %</b></div>
                                </div>
                            </div>
                        </div>                           
                    </div>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-12">
                <article class="tile is-child box">
                    <div class="columns">
                        <div class="column is-5">
                            <span class="label">{{$t('LOTEAMOSTRA.AMOSTRAS')}}</span>
                            <span>{{selecionadas.length}} {{$t('LOTEAMOSTRA.SELECIONADAS')}}</span>
                        </div>
                        <div class="column is-2">
                            <button class="button is-success" type="button" @click="imprimirLote()">
                                {{$t('LOTEAMOSTRA.IMPRIMIRLOTE')}}
                            </button>
                        </div>
                        <div class="column is-2">
                            <button v-if="!todas" class="button is-light" type="button" @click="selecionarTodas()">
                                {{$t('LOTEAMOSTRA.SELECIONARTODAS')}}
                            </button>
                            <button v-else class="button is-light" type="button" @click="desselecionarTodas()">
                                {{$t('LOTEAMOSTRA.DESSELECIONARTODAS')}}
                            </button>
                        </div>

                        <div class="column is-2">
                            <button v-if="selecionadas.length > 0" class="button is-success" type="button" @click="imprimeTodas()">
                                {{$t('LOTEAMOSTRA.IMPRIMEETIQUETA')}}
                            </button>
                        </div>
                    </div>

                    <div class="columns is-multiline">
                        <div class="column is-12" v-for="(item, index) in model.itens" v-bind:key="index" @click="selecionarAmostra(item, item.id)">
                            <amostra v-bind:key="index" :amostra="item.amostra" :selecionada="item.amostra.selecionada" :mostraPaciente="true"></amostra> 
                        </div>                
                    </div>
                </article>
            </div>
        </div>
    </section>
</template>

<script>
    const loadQuery = () => {
        const script = document.createElement('script'); 
        script.type = 'text/javascript'; 
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAaBDfrAbHdHviY1ODIAMNjG1GIngnYyPs&libraries=visualization&callback=initMap';
        document.body.appendChild(script); 
    }
    loadQuery();

    import titlebar from '@/components/titlebar.vue'
    import amostra from '@/components/atendimento/amostra.vue'
    import { mapGetters, mapState } from 'vuex'

    export default {
        data() {
            return {
                model: {
                },
                map: null,
                selecionadas: [],
                todas: false
            }
        },
        components: {
            titlebar,
            amostra
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.EXAME'),
                    this.$t('LOTEAMOSTRA.LOTE'),
                    this.$route.params.id
                ]
            },
            ...mapState([
                'integradores',
                'config'
            ])
        },
        created() {
            if (this.$route.params.id) {
                this.$http.get('/api/atendimento/amostralote?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;     
                        this.initMap();                 
                    })
                    .catch((err) => {
                        if(err.status === 404){
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('LOTEAMOSTRA.LOTENAOENCONTRADO'),
                                type: 'is-danger'
                            });

                            this.$router.push({ name: 'atendimento' });
                        }
                    })
            }
        },        
        methods: {
            initMap(){
                let mapcenter = { lat: -21.188627, lng: -47.798903 };
                const google = window.google;

                if(this.model.log.length > 0) {
                    mapcenter = { lat: this.model.log[0].latitude, lng: this.model.log[0].longitude };                    
                }

                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        mapcenter = { lat: position.coords.latitude, lng: position.coords.longitude };
                    });
                }

                this.map = new google.maps.Map(document.getElementById('mapa'), {
                    zoom: 13,
                    center: mapcenter
                });

                this.model.log?.forEach(log => {
                    new google.maps.Marker({
                        position: { lat: log.latitude, lng: log.longitude },
                        map: this.map,
                        label: log.acao[0],
                        title: log.acao
                    });
                });
            },
            selecionarAmostra(item, id){
                if(this.selecionadas.indexOf(id) >= 0){
                    this.selecionadas = this.selecionadas.filter(item => item != id);
                } else {
                    this.selecionadas.push(id); 
                }

                if(item.amostra.selecionada == null){
                    item.amostra.selecionada = false;
                }

                item.amostra.selecionada = !item.amostra.selecionada;  
                
            },
            desselecionarTodas(){
                this.model.itens?.forEach(item => item.amostra.selecionada = false);
                this.selecionadas = [];
                this.todas = false;
            },
            selecionarTodas(){
                this.selecionadas = [];
                this.model.itens?.forEach(item => {
                    item.amostra.selecionada = true;
                    this.selecionadas.push(item.id)
                });
                this.todas = true;
            },          
            imprimirLotePdf(){
                window.open('/Guia/comprovanteLotePdf?id='+this.model.id);
            },
            imprimirLoteTxt() {
                window.open('/Guia/comprovanteLoteTxt?id='+this.model.id);
            },
            imprimirLote() {
                if (this.model.temTemplatePdf && this.model.temTemplateTxt) {
                    this.imprimirLotePdf();
                    this.imprimirLoteTxt();
                } else if (this.model.temTemplatePdf){
                    this.imprimirLotePdf();
                } else if (this.model.temTemplateTxt) {
                    this.imprimirLoteTxt();
                }
            },
            imprimeTodas(){
                const modelo = window.localStorage.getItem("etiquetaAtual");
                const modelItens = this.model.itens;

                const amostras = modelItens.reduce((acc, item) => {
                    if(this.selecionadas.includes(item.id))
                        acc.push(item.amostra.id);
                    return acc;
                }, []);
                
                this.$notificacaoHub.invoke("EtiquetaAmostra", amostras, modelo,false);
            }
        }
    }
</script>