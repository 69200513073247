<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="notification is-info" v-if="salvandoDados">
                    <button type="button" class="delete"></button>
                    {{$t('TABPRECOATUALIZAR.NOTIFICACAOATUALIZAPRECO')}}
                </div>
                <article class="box">
                    <div class="columns is-mobile">
                        <div class="column is-one-third">
                            <b-tooltip  
                                size="is-small" 
                                :label="label"
                                :active="tabelasSelecionadas.length > 0 || conveniosSelecionados.length > 0"
                            >
                                <searchIdName :leitura="tabelasSelecionadas.length > 0 || conveniosSelecionados.length > 0" :id.sync="model.tipoConvenioId" :nome.sync="model.tipoConvenioNome" :label="$t('TABPRECOATUALIZAR.TIPOCONVENIO')" table="TipoConvenio"></searchIdName>
                            </b-tooltip>
                        </div>
                    </div>
                    <div class="columns is-mobile">
                            <b-tooltip  
                                :label="label"
                                :active="model.tipoConvenioId > 0 || conveniosSelecionados.length > 0"
                            >
                                <b-field 
                                    :label="$t('TABPRECOATUALIZAR.TABELA')"
                                    class="ml-2"
                                >
                                    <b-taginput
                                        :disabled="model.tipoConvenioId > 0 || conveniosSelecionados.length > 0"
                                        v-model="tabelasSelecionadas"
                                        :data="tabelasLista"
                                        autocomplete
                                        inputsTags
                                        :allow-new="false"
                                        :before-adding="(item) => { tabelasLista = tabelas; return item;}"
                                        :open-on-focus="openOfFocus"
                                        field="nome"
                                        icon="label"
                                        :placeholder="$t('TABPRECOATUALIZAR.SELECIONETABELAS')"
                                        @typing="buscarTabelas"
                                        >
                                        <template slot-scope="props" >
                                            <div class="media" >   
                                                <div class="media-left">
                                                    <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                                </div>
                                                <div class="media-content">
                                                    {{ props.option.nome }}
                                                </div>                           
                                            </div>
                                        </template>
                                    </b-taginput>
                                </b-field>
                            </b-tooltip>
                            <b-tooltip  
                                :label="label"
                                :active="tabelasSelecionadas.length > 0 || model.tipoConvenioId > 0"
                            >
                                <b-field  :label="$t('TABPRECOATUALIZAR.CONVENIO')" class="ml-2">
                                    <b-taginput
                                        :disabled="tabelasSelecionadas.length > 0 || model.tipoConvenioId > 0"
                                        v-model="conveniosSelecionados"
                                        :data="conveniosLista"
                                        autocomplete
                                        inputsTags
                                        :allow-new="false"
                                        :before-adding="(item) => { conveniosLista = convenios; return item;}"
                                        :open-on-focus="openOfFocus"
                                        field="nome"
                                        icon="label"
                                        :placeholder="$t('TABPRECOATUALIZAR.SELECIONECONV')"
                                        @typing="buscarConvenio"
                                        >
                                        <template slot-scope="props">
                                            <div class="media">   
                                                <div class="media-left">
                                                    <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                                </div>
                                                <div class="media-content">
                                                    {{ props.option.nome }}
                                                </div>                           
                                            </div>
                                        </template>
                                    </b-taginput>
                                </b-field>
                            </b-tooltip>
                            <b-field :label="$t('TABPRECOATUALIZAR.EXAMES')" class="ml-2">
                                <b-taginput
                                    v-model="examesSelecionados"
                                    :data="examesLista"
                                    autocomplete
                                    inputsTags
                                    :allow-new="false"
                                    :before-adding="(item) => { examesLista = exames; return item;}"
                                    :open-on-focus="openOfFocus"
                                    field="nome"
                                    icon="label"
                                    :placeholder="$t('TABPRECOATUALIZAR.SELECIONEEXA')"
                                    @typing="buscarExames"
                                    >
                                    <template slot-scope="props">
                                        <div class="media">   
                                            <div class="media-left">
                                                <span class="tag is-pulled-right is-light">{{ props.option.id }}</span>
                                            </div>
                                            <div class="media-content">
                                                {{ props.option.nome }}
                                            </div>                           
                                        </div>
                                    </template>
                                </b-taginput>
                            </b-field>
                    </div>
                    <div class="columns">
                        <div class="column is-2 ">
                            <b-field label="Ação">
                                <b-select v-model="model.type">
                                    <option value="1">Atualizar preço das guias</option>
                                    <option value="2">Atualizar preço das tabelas</option>
                                </b-select>
                            </b-field>
                        </div>
                        <div class="column is-5 ml-6" v-if="model.type == 2">
                            <b-field :label="$t('TABPRECOATUALIZAR.PERCENTUAL')">
                                <b-input v-model="model.porcentagem" type="number" step="0.01"></b-input>
                            </b-field>

                        </div>
                        <div v-else-if="model.type == 1"  class="column is-8 ml-6">
                            <b-field :label="$t('TABPRECOATUALIZAR.NOTIFICACAOEXAME')">
                                <periodoHora
                                :dataInicial.sync="model.dataInicial"
                                :dataFinal.sync="model.dataFinal"
                                :placeHolder="$t('TABPRECOATUALIZAR.SELECIONEPERIODO')"
                                :selecionadas="[model.dataInicial,model.dataFinal]"
                                />
                            </b-field>
                        </div>
                    </div>
                </article>

                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                {{$t('TABPRECOATUALIZAR.CANCELAR')}}
                            </b-button>
                            <b-button 
                                type="is-success"
                                :disabled="model.tipoConvenioId == null && tabelasSelecionadas.length == 0 && conveniosSelecionados.length == 0"
                                v-show="isInRole('tabelapreço-alterar')"
                                :loading="salvandoDados"
                                native-type="submit"
                                icon-left="check-circle"
                            >
                                {{$t('TABPRECOATUALIZAR.ATUALIZAPRECO')}}
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import { mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import periodoHora from '@/components/periodoHora.vue'
    import moment from 'moment';    

    export default {
        components: {
            titlebar,
            searchIdName,
            periodoHora
        },
        data() {
            return {
                model: {
                    tipoConvenioId: null,
                    tipoConvenioNome: null,
                    tabelasIds: [],
                    conveniosIds: [],
                    examesIds: [],
                    dataInicial:  moment().set('hours',0).set('minutes',0).toDate(),
                    dataFinal: moment().set('hours',23).set('minutes',59).toDate(),
                    porcentagem: null,
                    type: null,
                },
                tabelasSelecionadas: [],
                conveniosSelecionados: [],
                examesSelecionados: [],
                tabelas: [],
                tabelasLista: [],
                convenios: [],
                conveniosLista: [],
                exames: [],
                examesLista: [],
                openOfFocus: true,
                salvandoDados: false,
                label: "",
            }
        },
        watch: {
            'model.tipoConvenioId'() {
                if (this.model.tipoConvenioId) {
                    this.label = this.$t('TABPRECOATUALIZAR.TIPOCONVSELECIONADO');
                    this.tabelasSelecionadas = [];
                    this.conveniosSelecionados = [];
                }
            },
            tabelasSelecionadas() {
                if(this.tabelasSelecionadas.length > 0)
                    this.label = this.$t('TABPRECOATUALIZAR.TABELASELECIONADA');
            },
            conveniosSelecionados() {
                if(this.conveniosSelecionados.length > 0)
                    this.label = this.$t('TABPRECOATUALIZAR.CONVENIOSELECIONADO');
            },
        },
        computed: {
            titleStack() {
                return [
                    this.$t('TABPRECOATUALIZAR.MANUTENCAO'),
                    this.$t('TABPRECOATUALIZAR.ATUALIZAR')
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        mounted() {
            this.loadTabelas();
            this.loadConvenios();
            this.loadExames();
        },            
        methods: {
            onSubmit() {
                if(this.tabelasSelecionadas.length > 0)
                    this.model.tabelasIds = this.tabelasSelecionadas.map(item => item.id);

                if(this.conveniosSelecionados.length > 0)
                    this.model.conveniosIds = this.conveniosSelecionados.map(item => item.id);

                if(this.examesSelecionados.length > 0)
                    this.model.examesIds = this.examesSelecionados.map(item => item.id);

                if(this.model.type == null) {
                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TABPRECOATUALIZAR.SELECIONEACAO'),
                        type: 'is-danger'
                    })
                }

                this.salvandoDados = true
                this.$http.post(`/api/manutencao/atualizarTabelaPreco`, this.model)
                .then(() => {
                    this.salvandoDados = false
                    this.$router.push({ name: 'tabelasprecos' })
                })
                .catch((error) => {
                    this.lista = []
                    this.isLoading = false
                    throw error
                })
            },
            loadTabelas(){
                this.$http.get('/api/search/tabelasDePreco')
                .then(res => {
                    this.tabelas = res.data;
                });
            },
            loadExames(){
                this.$http.get('/api/search/exames')
                .then(res => {
                    this.exames = res.data;
                });
            },
            loadConvenios(){
                this.$http.get('/api/search/convenios')
                .then(res => {
                    this.convenios = res.data;
                });
            },
            buscarTabelas(text) {
                this.tabelasLista = this.tabelas;
                this.tabelasLista = this.tabelasLista.filter(option => option.nome != null).filter((option) => {
                    return option?.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarConvenio(text){
                this.conveniosLista = this.convenios;
                this.conveniosLista = this.conveniosLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            buscarExames(text) {
                this.examesLista = this.exames;
                this.examesLista = this.examesLista.filter((option) => {
                    return option.nome
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
        },
    }
</script>