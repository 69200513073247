<template>
    <div class="column is-12">
        <div :class="classes" @click.capture="selecionar">
            <div v-if="mostraAccessionNumber">
                <div v-if="guiaId" class="column is-1">
                    <small>{{$t('ESTUDO.GUIA')}}</small>
                    <br />
                    <span v-if="!isInRole('guia-consultar')">
                        {{ guiaId  }}
                    </span>
                    <span v-else>
                        <router-link  class="is-block" :to="{ name: 'guia', params: { id: guiaId }}"  >
                            {{ guiaId  }}
                        </router-link>                          
                    </span>                    

                </div>  
                <div v-else class="column is-1">
                    <small>{{$t('ESTUDO.ACCESSIONNUMBER')}}</small>
                    <br />
                    <b>{{ estudo.accessionNumber }}</b>
                </div>
            </div>
            <div class="column is-3" v-for="(item, index) in this.estudo.paciente" v-bind:key="index">
                <small>{{$t('ESTUDO.MOSTRAPACIENTESEXOIDADE')}}</small>
                <br />
                <span><b>{{ item.nome }}, {{ item.sexo }}, {{ item.idade }}</b></span>
                <span v-if="item.racaNome">
                    <span v-if="item.especieNome">
                        <br />
                        <small>{{$t('ESTUDO.MOSTRARACAESPECIE')}}</small>
                        <br />
                        <b>{{ item.racaNome }}, {{ item.especieNome }}</b>
                    </span>    
                </span>
            </div>
            <div class="column is-2" v-for="(item) in this.estudo.medico" v-bind:key="item.id">
                    <small>{{$t('SISTEMA.SOLICITANTE')}}:</small>
                    <br />
                    <b>{{ item.nome }}</b>
            </div>
            <div class="column is-1 has-text-centered">
                <img :src="imageSrc()" style="max-height:50px;">
            </div>
            <div class="column is-2">
                <b>{{this.estudo.studyDescription}}</b>
                <br />
                <small>{{this.estudo.modsInStudy}}</small>
            </div>  
            <div class="column has-text-centered">
                <p class="is-size-7">{{ estudo.studyDatetime | moment('DD/MM/YY') }}</p>
                <p class="is-size-7">{{ estudo.studyDatetime | moment('HH:mm') }}</p>
            </div>
            <div class="column" v-for="(item) in this.estudo.exames" v-bind:key="item.id">
                <b-tooltip :label="$t(exameStatusTitle(item))" placement="bottom" position="is-bottom" type="is-dark"></b-tooltip>
                <b-tag :type="exameStatus(item)">
                    <span v-if="item.apelido">{{ item.apelido }}</span>
                    <span v-else>{{ item.exameApelido }}</span>
                </b-tag>
                <span >
                    <b-tooltip :label="$t('COLETA.EXAMEURGENTE')" placement="bottom" position="is-bottom" type="is-light">
                        <b-icon v-if="item.urgente" icon="alert-circle" type="is-danger"></b-icon>
                    </b-tooltip>
                </span>
            </div>

        
            <div class="column" style="flex-basis: 15%;">
                <b-tooltip :label="$t('Visualizar imagens')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-button v-if="this.estudo.viewers != null && this.estudo.viewers.length > 0"
                              type="is-primary is-pulled-right" size="is-medium" icon-left="eye"
                              tag="a" :href="this.estudo.viewers[0].url" target="_blank"></b-button>
                </b-tooltip>

                <b-tooltip :label="$t('Download Dicom')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-button v-if="config.urlDownloadDicom && this.estudo.viewers != null && this.estudo.viewers.length > 0"
                              type="is-pulled-right" size="is-medium" icon-left="download"
                              @click="downloadDicom"></b-button>
                </b-tooltip>

                <b-tooltip :label="$t('Impressão')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-button type="is-pulled-right" size="is-medium" icon-left="printer"
                              @click="impressaoEstudo"></b-button>
                </b-tooltip>

                <b-tooltip :label="$t('Laudar estudo')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-button v-if="guiaId && !semVinculo && isInRole('resultado-estudo-editar')"
                              type="is-pulled-right" size="is-medium" icon-left="file-document-edit-outline"
                              tag="router-link" :to="{ name: 'estudolaudo', params: { id: this.estudo.id }}"></b-button>
                </b-tooltip>

                <b-tooltip :label="$t('Vincular estudo')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-button v-if="semVinculo" type="is-pulled-right" size="is-medium" icon-left="link-variant"
                              @click="modalEstudo(estudo.id)"></b-button>
                </b-tooltip>

                <b-tooltip :label="$t('Desvincular estudo')" placement="bottom" position="is-bottom" type="is-dark">
                    <b-button v-if="desvincular && isInRole('desvincularestudo-alterar')" type="is-pulled-right has-text-danger" size="is-medium" icon-left="water-off"
                              @click="desvincularEstudo(estudo)"></b-button>
                </b-tooltip>


                <br />
                <b-tag>{{this.estudo.numSeries}}{{$t('ESTUDO.SERIES')}}</b-tag>
                <b-tag type="is-info">{{this.estudo.numInstances}}{{$t('ESTUDO.IMAGENS')}}</b-tag>
            </div> 
        </div>
    </div>
</template>

<script>
    import { mapState,mapGetters } from 'vuex'
    import modalVincularEstudo from '@/components/modalVincularEstudo.vue'
    import modalMotivos from '../modalMotivos.vue'

    export default {
        props: {
            estudo: Object,
            index: Number,
            selecionado: Boolean,
            mostraAccessionNumber: Boolean,
            setores: Array,
            departamentos: Array,
            unidades: Array,
            convenios: Array,
            desvincular: Boolean
        },
        data() {
            return {
                classes: this.defineClasses(),
                semVinculo: false,
            }
        },
        watch: {
            selecionado: function() {
                this.classes = this.defineClasses()
            }
        },
        computed: {
            ...mapState([
                'config',
            ]), 
            ...mapGetters([
                'isInRole'
            ]),                               
            guiaId() {
                return this.estudo.exames?.length > 0
                    ? this.estudo.exames[0].guiaId
                    : null
            }
        },
        mounted () {
            if(this.$route?.query?.semVinculo) {
                this.semVinculo = true;
            }
        },
        methods: {
           
            defineClasses() {
                return "columns is-multiline guiaEstudo" +
                    (this.selecionado ? " guiaEstudoSelecionado" : "")
            },
            selecionar() {
                if (this.estudo.id != 0) {
                    this.$emit('selecionarEstudo', this.estudo)
                }
            },
            impressaoEstudo() {
                let url  = this.config.urlImpressaoEstudo;                                
                url += "&studyUID="+ this.estudo.studyUID;                  
                window.open(url,"_blank");

                
            },            
            imageSrc() {
                return "data:image/png;base64, " + this.estudo.thumbnail
            },
            exameStatus(item) {
                return item.cancelado
                    ? "is-danger"
                    : item.resultadoAssinado
                        ? "is-success"
                        : item.resultadoDigitado
                            ? "is-info"
                            : ""
            },
            exameStatusTitle(item) {
                return item.cancelado
                    ? this.$t('AMOSTRA.EXAMECANCELADO')
                    : item.resultadoAssinado
                        ? this.$t('AMOSTRA.EXAMEASSINADO')
                        : item.resultadoDigitado
                            ? this.$t('AMOSTRA.EXAMEDIGITADO')
                            : ""
            },
            modalEstudo(estudoId){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalVincularEstudo,
                    props: {
                        id: estudoId,
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            desvincularEstudo(estudo){
                estudo.exames.forEach(x => {
                    if(x.assinado){
                        this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('ESTUDO.ALERTADESVINCULARESTUDO'),
                        type: 'is-danger',
                        queue: false
                    });
                    }else {
                        this.$buefy.modal.open({
                            parent: this,
                            component: modalMotivos,
                            props: {
                                id: this.estudo.id,
                                guiaId: this.guiaId,
                                selecionado: this.estudo.exames
                            },
                            hasModalCard: true,
                            trapFocus: true
                        })
                    }
                });                
            },
            downloadDicom() {
                const url = this.config.urlDownloadDicom.replace("{$studyIUID}", this.estudo.studyUID);
                window.location.href = url;
            }
        }
    }
</script>