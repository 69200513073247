<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ $t('MODALLOTEGLOSACRIADO.TITULO') }}</p>
        </header>
        <section class="modal-card-body is-centered">
             <div class="columns">
                <div class="column">
                    {{ $t('MODALLOTEGLOSACRIADO.AVISO') }}: {{ loteGlosaId }}
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <router-link :to="{ name: 'cadastro-fatura', params: { id: loteGlosaId }}" target="_blank" ref="noopener">{{ $t('MODALLOTEGLOSACRIADO.ABRIR') }}</router-link>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$emit('close')">{{ $t('MODALLOTEGLOSACRIADO.FECHAR') }}</button>
        </footer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            loteGlosaId : null,
        },
        data() {
            return {}
        },
        computed: {
            ...mapState([
                'config'
            ])
        },
        methods: {
        }
    }
</script>