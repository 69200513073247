<template>
    <section style="width: 100%">
        <div class="field">
            <label class="label">{{ label }}</label>
            <div class="control is-flex is-fullwidth-mobile-periodo">
                <b-dropdown aria-role="list">
                    <button type="button" class="button is-light" slot="trigger" >
                        <b-icon icon="calendar"></b-icon>
                    </button>

                    <b-dropdown-item aria-role="listitem"
                                     v-for="opcao in opcoes"
                                     :key="opcao.id"
                                     @click="opcao.acao">{{ opcao.label }}</b-dropdown-item>

                    <div class="is-hidden-desktop">
                        <hr class="dropdown-divider" aria-role="menuitem">
                        <b-dropdown-item aria-role="listitem"
                                         @click="limparDatas()">
                            Nenhum
                        </b-dropdown-item>
                    </div>
                </b-dropdown>
                <b-datetimepicker editable expanded
                              :placeholder="placeHolder"
                              v-model="dates[0]"
                              :max-datetime=" (regraBloqueio) ? dates[1] : null"
                              ref="datePickerInicial"
                              class="is-fullwidth-mobile"
                              style="width: 45%"
                              :disabled="disabledPeriodo">

                <template #right>
                    <b-button
                        label="Fechar"                        
                        icon-left="close-circle"
                        outlined
                         @click="$refs.datePickerInicial.toggle()" />
                </template>

                </b-datetimepicker>                
                <b-datetimepicker expanded
                              :placeholder="placeHolder"
                              v-model="dates[1]"                              
                              :min-datetime=" (regraBloqueio) ? dates[0] : null"
                              class="is-fullwidth-mobile"
                              ref="datePickerFinal"
                              style="width: 45%"
                              :disabled="disabledPeriodo">

                <template #right>
                    <b-button
                        label="Fechar"                        
                        icon-left="close-circle"
                        outlined
                         @click="$refs.datePickerFinal.toggle()" />
                </template>


                </b-datetimepicker>
                <b-button @click="limparDatas()"
                          title="Limpar filtro por data"
                          class="is-hidden-touch"
                          icon-left="close">
                </b-button>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        components: {},
        props: {
            dataInicial: null,
            dataFinal: null,
            label: null,
            placeHolder: null,
            selecionadas: null,
            regraBloqueio: {
                type: Boolean,
                default: true
            },         
            disabledPeriodo: Boolean
        },
        mounted() {
            if (this.dataInicial && this.dataFinal) {
                this.dates = [this.dataInicial, this.dataFinal];
            }
        },
        data() {
            return {
                dates: [],
                getDataHojeFimDoDia() {
                    return this.$moment()
                        .set("hour", 23)
                        .set("minute", 59)
                        .set("second", 59)
                        .toDate();
                },
                opcoes: [
                    {
                        id: 1,
                        label: "Hoje",
                        acao: () => {
                            this.dates = [
                                this.$moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .toDate(),
                                this.getDataHojeFimDoDia(),
                            ];
                        },
                    },
                    {
                        id: 2,
                        label: "Ontem",
                        acao: () => {
                            this.dates = [
                                this.$moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("d", -1)
                                    .toDate(),
                                    this.$moment()
                                    .set("hour", 23)
                                    .set("minute", 59)
                                    .set("second", 59)
                                    .add("d", -1)
                                    .toDate()
                            ];
                        },
                    },
                    {
                        id: 3,
                        label: "Últimos 7 dias",
                        acao: () => {
                            this.dates = [
                                this.$moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("d", -6)
                                    .toDate(),
                                this.getDataHojeFimDoDia(),
                            ];
                        },
                    },
                    {
                        id: 4,
                        label: "Últimos 30 dias",
                        acao: () => {
                            this.dates = [
                                this.$moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("d", -29)
                                    .toDate(),
                                this.getDataHojeFimDoDia(),
                            ];
                        },
                    },
                    {
                        id: 5,
                        label: "Últimos 60 dias",
                        acao: () => {
                            this.dates = [
                                this.$moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("d", -59)
                                    .toDate(),
                                this.getDataHojeFimDoDia(),
                            ];
                        },
                    },
                    {
                        id: 6,
                        label: "Este mês",
                        acao: () => {
                            this.dates = [
                                this.$moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .startOf("month")
                                    .toDate(),
                                this.$moment()
                                    .endOf("month")
                                    .set("hour", 23)
                                    .set("minute", 59)
                                    .set("second", 59)
                                    .toDate(),
                            ];
                        },
                    },
                    {
                        id: 7,
                        label: "Mês Passado",
                        acao: () => {
                            this.dates = [
                                this.$moment()
                                    .set("hour", 0)
                                    .set("minute", 0)
                                    .set("second", 0)
                                    .add("month", -1)
                                    .startOf("month")
                                    .toDate(),
                                this.$moment()
                                    .add("month", -1)
                                    .endOf("month")
                                    .set("hour", 23)
                                    .set("minute", 59)
                                    .set("second", 59)
                                    .toDate(),
                            ];
                        },
                    },
                ],
            };
        },
        watch: {
            dataInicial(valor) {
                if (valor) {
                    if (this.dataInicial && this.dataFinal) {
                        this.dates[this.dataInicial, this.dataFinal]
                    }
                }
            },
            dataFinal(valor) {
                if (valor) {
                    if (this.dataInicial && this.dataFinal) {
                        this.dates[this.dataInicial, this.dataFinal]
                    }
                }
            },
            dates(novoValor) {
                if (novoValor && novoValor.length) {
                    this.$emit("update:dataInicial", novoValor[0]);
                    this.$emit("update:dataFinal", novoValor[1]);
                    this.$emit("changed", { dataInicial: novoValor[0], dataFinal: novoValor[1] });
                }
            },
            selecionadas(novoValor) {
                if (novoValor && novoValor.length) {
                    this.dates = novoValor;
                }
            }
        },
        methods: {
            limparDatas(){
                this.dataInicial = null;
                this.dataFinal = null;
                this.dates = [];
                this.$emit('limparDatas', { dataInicial: null, dataFinal: null });
            }
        }
    };
</script>
