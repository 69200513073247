<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <div class="box">                
            <b-tabs position="is-right" type="is-boxed" v-model="activeItemTab">
                <b-tab-item>
                    <template slot="header">
                        <span @click="activeItemTab == 0"> {{$t('FATURAR.FILTRO')}} </span>
                    </template>
                    <div class="columns is-multiline">            
                        <div class="column is-6">
                            <searchIdName :id.sync="unidade.id" :item.sync="unidade" table="Unidade" :label="$t('FATURAR.UNIDADE')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="local.id" :item.sync="local" table="Local" :label="$t('FATURAR.LOCALORIGEM')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="convenio.id" :item.sync="convenio" table="Convenio" :label="$t('SISTEMA.CONVENIO')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="tipoConvenio.id" :item.sync="tipoConvenio" table="TipoConvenio" :label="$t('FATURAR.TIPOCONVENIO')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="setor.id" :item.sync="setor" table="Setor" :label="$t('FATURAR.SETOR')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="departamento.id" :item.sync="departamento" table="Departamento" :label="$t('FATURAR.DEPARTAMENTO')"/>
                        </div>
                        <div class="column is-6">
                            <div class="panel">
                                <p class="panel-tabs">
                                    <a :class="{ 'is-active': dataFiltro == 'emissao' }" @click="dataFiltro = 'emissao'">{{$t('FATURAR.EMISSAO')}}</a>
                                    <a :class="{ 'is-active': dataFiltro == 'solicitacao' }" @click="dataFiltro = 'solicitacao'">{{$t('FATURAR.SOLICITACAO')}}</a>
                                    <a :class="{ 'is-active': dataFiltro == 'alta' }" @click="dataFiltro = 'alta'">{{$t('FATURAR.ALTA')}}</a>
                                    <a :class="{ 'is-active': dataFiltro == 'digitacao' }" @click="dataFiltro = 'digitacao'">{{$t('FATURAR.DIGITACAO')}}</a>
                                    <a :class="{ 'is-active': dataFiltro == 'impressao' }" @click="dataFiltro = 'impressao'">{{$t('FATURAR.IMPRESSAO')}}</a>
                                    <a :class="{ 'is-active': dataFiltro == 'coleta' }" @click="dataFiltro = 'coleta'">{{$t('FATURAR.COLETA')}}</a>
                                    <a :class="{ 'is-active': dataFiltro == 'assinatura' }" @click="dataFiltro = 'assinatura'">{{$t('FATURAR.ASSINATURA')}}</a>
                                </p>
                                <div class="panel-block">
                                    <periodoHora :dataInicial.sync="datas[0]"
                                        :dataFinal.sync="datas[1]"/>
                                </div>
                            </div>
                        </div>
                        <div class="column is-6">
                            <b-field>
                                <b-checkbox v-model="incluirConvenioParticular">{{$t('FATURAR.INCLUIRCONVENIOSPARTICULARES')}}</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="somenteGuiaConferida">{{$t('FATURAR.GUIACONFERIDA')}}</b-checkbox>
                            </b-field>
                            <b-field>
                                <b-checkbox v-model="somenteGuiaNaoConferida">{{$t('FATURAR.GUIANAOCONFERIDA')}}</b-checkbox>
                            </b-field>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item>
                    <template slot="header">
                        <span @click="activeItemTab == 0"> {{$t('FATURAR.SELECIONADOS')}} </span>
                    </template>
                    <div class="columns is-multiline">   
                        <div class="column is-12">                       
                            <b-table v-if="guias.length > 0" :data="guias"
                                    :loading="loading"
                                    striped
                                    hoverable
                                    :checked-rows.sync="checkedRows"
                                    checkable
                                    checkbox-position="left"
                                    sort-icon-size="is-small">

                                <template >
                                    <b-table-column  v-slot="props" field="Guia" :label="$t('FATURAR.GUIA')">                                    
                                        {{ props.row.id }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" v-if="config.faturarBuscandoReferencia" field="Referencia" :label="$t('FATURAR.REFERENCIA')">
                                        {{ props.row.referencia }}
                                    </b-table-column>

                                    <b-table-column  v-slot="props" field="Paciente" :label="$t('SISTEMA.PACIENTE')">
                                        {{ props.row.pacienteNome }}
                                    </b-table-column>
                                </template>
                            </b-table>
                        </div>
                    </div>
                    <br>
                    <div class="columns is-multiline">  
                        <div class="box">
                            <article class="level">
                                <div class="level-left">
                                    <div class="is-light">
                                        <form @submit.prevent="incluirGuia" method="get">
                                            <label><strong>{{$t('FATURAR.GUIA')}}</strong></label>
                                            <div class="field has-addons">
                                                <div class="control">
                                                    <b-input v-model="guia" ref="guiaFaturar"></b-input>
                                                </div>
                                                <div class="control">
                                                    <b-button native-type="submit" type="is-success">
                                                        {{$t('FATURAR.INCLUIR')}}
                                                    </b-button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </b-tab-item>
                <b-tab-item v-if="config.faturaAgendamento && isInRole('fatura-agendamento')">
                    <template slot="header">
                        <span @click="activeItemTab == 0"> {{$t('FATURAR.AGENDAMENTOS')}} </span>
                    </template>
                    <div class="columns is-multiline">  
                        <agendamentos></agendamentos>
                    </div>
                </b-tab-item>
                <b-tab-item v-if="isInRole('financeiro-retornotiss')">
                    <template slot="header">
                        <span @click="activeItemTab == 0"> {{$t('FATURAR.RETORNOTISS')}} </span>
                    </template>
                    <div class="columns is-multiline">            
                        <div class="column is-6">
                            <searchIdName :id.sync="unidade.id" :item.sync="unidade" table="Unidade" :label="$t('FATURAR.UNIDADE')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="local.id" :item.sync="local" table="Local" :label="$t('FATURAR.LOCALORIGEM')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="convenio.id" :item.sync="convenio" table="Convenio" :label="$t('SISTEMA.CONVENIO')"/>
                        </div>
                        <div class="column is-6">
                            <searchIdName :id.sync="tipoConvenio.id" :item.sync="tipoConvenio" table="TipoConvenio" :label="$t('FATURAR.TIPOCONVENIO')"/>
                        </div>
                        <div class="column">
                            <b-upload class="pr-2 is-pulled-right" accept=".xml" @input="uploadXml(arquivosInseridos)" v-model="arquivosInseridos" multiple>
                                <a class="button is-primary is-fullwidth " icon="upload">
                                <b-icon icon="upload"></b-icon>
                                <span> 
                                    {{$t('FATURAR.CARREGARARQUIVO')}}</span>
                                </a>
                            </b-upload>
                        </div>
                    </div>
                    <br>
                    <div class="tags pr-2 is-pulled-right">
                        <span v-for="(file, index) in arquivosInseridos" :key="index" class="tag is-primary is-2">
                            {{file.name}}
                            <button class="delete is-small" type="button" @click="removerXml(index)"></button>
                        </span>
                    </div>
                </b-tab-item>
            </b-tabs>    
            <div class="columns is-multiline" v-if="activeItemTab != 3">     
                <div class="column tile is-12">
                    <div class="column is-2">
                        <label class="label">{{$t('FATURAR.ORDENARPOR')}}</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="guia">
                            {{$t('FATURAR.GUIA')}}
                        </b-radio>
                    </div>
                    <div class="column is-2">
                        <label class="label" style="visibility: hidden;">{{$t('FATURAR.ORDENARPOR')}}</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="referencia">
                            {{$t('FATURAR.REFERENCIA')}}
                        </b-radio>
                    </div>
                    <div class="column is-2">
                        <label class="label" style="visibility: hidden;">{{$t('FATURAR.ORDENARPOR')}}</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="cliente">
                            {{$t('SISTEMA.PACIENTE')}}
                        </b-radio>
                    </div>
                    <div class="column is-2">
                        <label class="label" style="visibility: hidden;">{{$t('FATURAR.ORDENARPOR')}}</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="dataEmissao">
                            {{$t('FATURAR.DATAEMISSAO')}}
                        </b-radio>
                    </div>
                    <div class="column is-2">
                        <label class="label" style="visibility: hidden;">{{$t('FATURAR.ORDENARPOR')}}</label>
                        <b-radio v-model="ordena"
                            name="ordena"
                            native-value="dataSolicitacao">
                            {{$t('FATURAR.DATASOLICITACAO')}}
                        </b-radio>
                    </div>
                </div>
            </div>
            <hr v-if="activeItemTab != 3">        
            <div class="columns is-multiline"  v-if="activeItemTab != 3">  
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="dataNascimento">{{$t('FATURAR.DATANASCIMENTO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="numeroConselho">{{$t('FATURAR.NUMEROCONSELHO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="dataSolicitacao">{{$t('FATURAR.DATASOLICITACAO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="CNS">{{$t('FATURAR.CNSEMVEZDEINSCRICAO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="materialEntregue">{{$t('FATURAR.SOMENTECOMMATERIALENTREGUE')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteResultadoAssinado">{{$t('FATURAR.SOMENTECOMRESULTADOASSINADO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="agrupaSetor">{{$t('FATURAR.AGRUPARELATROPIOPORSETOR')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteFaturados">{{$t('FATURAR.SOMENTEJAFATURADOS')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="estudoClinico">{{$t('FATURAR.ESTUDOCLINICO')}}</b-checkbox>
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="somenteNaoFaturados">{{$t('FATURAR.SOMENTENAOFATURADOS')}}</b-checkbox>
                    </b-field>
                </div>                                
                <div class="column is-6">
                    <b-field>
                        <b-checkbox v-model="naoIncluirGuiasPendentes">{{$t('FATURAR.GUIASPENDENTES')}}</b-checkbox>
                    </b-field>
                </div>                
            </div>
        </div>

        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        {{$t('FATURAR.VOLTAR')}}
                    </b-button>
                    <b-button v-if="activeItemTab != 3" type="is-success"
                                native-type="button"
                                :loading="loading"
                                @click="enviar()">
                        {{$t('FATURAR.CRIARDOCUMENTO')}}
                    </b-button>
                    <b-button v-if="activeItemTab != 3" type="is-primary"
                                native-type="button"
                                :loading="loading"
                                @click="gerarPdf()">
                        {{$t('FATURAR.GERARPDF')}}
                    </b-button>
                    <b-button v-if="activeItemTab != 3" type="is-warning"
                                native-type="button"
                                :loading="loading"
                                @click="gerarXls()">
                        {{$t('FATURAR.GERARXLS')}}
                    </b-button>
                     <b-button v-if="activeItemTab != 3" type="is-primary"
                                native-type="button"
                                :loading="loading"
                                @click="validarTiss()">
                        {{$t('FATURAR.VALIDARTISS')}}
                    </b-button>
                    <b-button  type="is-info" v-if="config.faturaAgendamento && isInRole('fatura-agendamento') && activeItemTab != 3"
                                native-type="button"
                                :loading="loading"
                                @click="agendar()">
                        {{$t('FATURAR.CRIARAGENDAMENTO')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import { mapGetters, mapState  } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import moment from 'moment';
    import periodoHora from '@/components/periodoHora.vue'
    import agendamentos from '@/components/financeiro/agendamentos.vue'

    export default {
        data(){
            return {
                activeItemTab: 0,
                unidade: {
                    id: null,
                    nome: null
                },
                local: {
                    id: null,
                    nome: null
                },
                convenio: {
                    id: null,
                    nome: null
                },
                tipoConvenio: {
                    id: null,
                    nome: null
                },
                setor: {
                    id: null,
                    nome: null
                },
                departamento: {
                    id: null,
                    nome: null
                },
                dataFiltro: 'emissao',
                incluirConvenioParticular: false,
                somenteGuiaConferida: false,
                somenteGuiaNaoConferida: false,
                guias: [],
                checkedRows: [],
                guia: "",
                loading: false,
                datas: [
                    this.$moment().subtract(1, 'days').toDate(),
                    this.$moment().toDate()
                ],
                ordena: 'guia',
                dataNascimento: true,
                numeroConselho: true,
                dataSolicitacao: true,
                CNS: false,
                materialEntregue: false,
                somenteResultadoAssinado: false,
                agrupaSetor: false,
                somenteFaturados: false,
                somenteNaoFaturados:false,
                estudoClinico: false,
                naoIncluirGuiasPendentes: false,
                arquivosInseridos: [],
            }
        },
        components: {
            titlebar,
            searchIdName,
            periodoHora,
            agendamentos
        },        
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.FATURAMENTO'),
                    this.$t('FATURAR.TITULO')
                ]
            },
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'unidadeId'
            ])
        },     
        watch: {
            somenteGuiaConferida(value) {
                if (value) this.somenteGuiaNaoConferida = false;
            },
            somenteGuiaNaoConferida(value) {
                if (value) this.somenteGuiaConferida = false;                
            }
        },   
        methods:{            
            incluirGuia(){
                this.loading = true;

                let url = '/api/Financeiro/BuscarGuiaFaturar?id=' + this.guia;

                if(this.config.faturarBuscandoReferencia){
                    url = '/api/Financeiro/BuscarGuiaFaturar?referencia=' + this.guia + 
                        '&dataInicial=' + this.$moment(this.datas[0]).format() +
                        '&datafinal=' + this.$moment(this.datas[1]).format()
                }

                this.$http.get(url)
                    .then(res => res.data)
                    .then(data => {
                        if (data != null && data.length != 0) {
                            for (const i in data) {
                                this.guias.push(data[i]);
                                this.checkedRows.push(data[i]);
                            }
                        }
                        this.loading = false;
                    });

                this.$refs.guiaFaturar.focus();
            },
            enviar(){
                this.loading = true;
                const faturar = this.parametros();

                this.$http.post('/api/financeiro/ReceberFaturar', faturar)
                    .then(res => res.data)
                    .then(data => {
                        this.$router.push({ name: 'lista-faturas' });
                        
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                    }); 

                this.limparLista();
            },
            agendar(){
                this.loading = true;
                const faturar = this.parametros();

                this.$http.post('/api/financeiro/ReceberFaturarAgendamento', faturar)
                    .then(res => res.data)
                    .then(data => {
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Agendamento realizado.',
                            type: 'is-info',
                            queue: false
                        })
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                    }); 

                this.limparLista();
            },
            parametros(){
                const faturar = {
                    convenioId: this.convenio.id, 
                    dataInicial: this.datas[0],
                    dataFinal: this.datas[1], 
                    somenteFaturados: this.somenteFaturados, 
                    somenteNaoFaturados: this.somenteNaoFaturados,
                    somenteResultadoAssinado: this.somenteResultadoAssinado, 
                    estudoClinico: this.estudoClinico, 
                    naoIncluirGuiasPendentes: this.naoIncluirGuiasPendentes, 
                    dataNascimento: this.dataNascimento,
                    numeroConselho: this.numeroConselho,
                    CNS: this.CNS,
                    dataSolicitacao: this.dataSolicitacao,
                    materialEntregue: this.materialEntregue,
                    agrupaSetor: this.agrupaSetor,
                    ordenaPor: this.ordena,
                    filtraPor: this.dataFiltro,
                    unidadeId: this.unidade.id,
                    localDeOrigemId: this.local.id,
                    setorId: this.setor.id,
                    departamentoId: this.departamento.id,
                    tipoConvenioId: this.tipoConvenio.id,
                    guiaId: this.checkedRows.map(item => item.id),
                    incluirConvenioParticular: this.incluirConvenioParticular,
                    somenteGuiaConferida: this.somenteGuiaConferida,
                    somenteGuiaNaoConferida: this.somenteGuiaNaoConferida,
                    unidadeIdBrowser: this.unidadeId
                };

                return faturar;
            },
            parametrosArquivo(){

                const params = [
                    `somenteFaturados=${this.somenteFaturados}`,
                    `somenteNaoFaturados=${this.somenteNaoFaturados}`,                    
                    `somenteResultadoAssinado=${this.somenteResultadoAssinado}`,
                    `estudoClinico=${this.estudoClinico}`,
                    `naoIncluirGuiasPendentes=${this.naoIncluirGuiasPendentes}`,
                    `dataNascimento=${this.dataNascimento}`,
                    `numeroConselho=${this.numeroConselho}`,
                    `CNS=${this.CNS}`,
                    `dataSolicitacao=${this.dataSolicitacao}`,
                    `materialEntregue=${this.materialEntregue}`,
                    `agrupaSetor=${this.agrupaSetor}`,
                    `ordenaPor=${this.ordena}`,
                    `filtraPor=${this.dataFiltro}`,
                    `incluirConvenioParticular=${this.incluirConvenioParticular}`,
                    `somenteGuiaConferida=${this.somenteGuiaConferida}`,
                    `somenteGuiaNaoConferida=${this.somenteGuiaNaoConferida}`,
                    `dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`
                ];

                if (this.checkedRows != null && this.checkedRows.length > 0){
                    for (const i in this.checkedRows) {
                        params.push(`guiaId[${i}]=${this.checkedRows[i].id}`);
                    }
                }

                if(this.convenio != null && this.convenio.id != null){
                    params.push(`convenioId=${this.convenio.id}`);
                }
                if(this.unidade != null && this.unidade.id != null){
                    params.push(`unidadeId=${this.unidade.id}`);
                }
                if(this.local != null && this.local.id != null){
                    params.push(`localDeOrigemId=${this.local.id}`);
                }
                if(this.setor != null && this.setor.id != null){
                    params.push(`setorId=${this.setor.id}`);
                }
                if(this.departamento != null && this.departamento.id != null){
                    params.push(`departamentoId=${this.departamento.id}`);
                }
                if(this.tipoConvenio != null && this.tipoConvenio.id != null){
                    params.push(`tipoConvenioId=${this.tipoConvenio.id}`);
                }

                return params;
            },
            gerarPdf(){
                const params = this.parametrosArquivo();
                params.push(`formato=pdf`);

                window.open(`/api/financeiro/FaturamentoConvenio?${params.join('&')}`,'_blank');

                this.limparLista();
            },
            gerarXls(){
                const params = this.parametrosArquivo();
                params.push(`formato=xls`);

                window.open(`/api/financeiro/FaturamentoConvenio?${params.join('&')}`,'_blank');

                this.limparLista();
            },
            validarTiss(){

                const params = this.parametrosArquivo();
                if(this.convenio.id == null && this.tipoConvenio.id == null){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('FATURAR.CONVOBRIGATORIO'),
                        type: 'is-danger',
                        queue: false
                    })
                    return;
                }
                
                this.loading = true;

                this.$http.get(`/api/financeiro/validarTiss?${params.join('&')}`)
                .then(res => {                
                    this.$router.push({ name: 'autorizacao-tiss', params: {
                        lista: res.data,
                        parametros: this.parametros(),
                    } });

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    throw error
                }); 

            },
            limparLista(){                
                this.guias = [];
                this.checkedRows = [];
            },
            uploadXml(arquivos){
                const formData = new FormData()

                if(this.convenio.id == null) 
                {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('FATURAR.CONVENIOOBRIGATORIO'),
                        type: 'is-danger'
                    });

                    return;
                }


                Array.from(Array(arquivos.length).keys())
                .map(x => {       
                    formData.append('xmlFile', arquivos[x], arquivos[x].name);
                })
                
                if(this.convenio.id != null)
                    formData.append("receberParametros.convenioId", this.convenio.id);
                if(this.tipoConvenio.id != null)
                    formData.append("receberParametros.unidadeId", this.tipoConvenio.id);
                if(this.unidade.id != null)
                    formData.append("receberParametros.localDeOrigemId", this.unidade.id);
                if(this.local.id != null)
                    formData.append("receberParametros.tipoConvenioId", this.local.id);
                 

                this.$http.post('/api/financeiro/uploadTiss', formData)
                .then((res) => {  
                    if(res.data.mensagensAvisos != null && res.data.mensagensAvisos != undefined && res.data.mensagensAvisos != ""){
                        this.$buefy.snackbar.open({
                            duration: 5000,
                            message: this.$t('CONTASRECEBERCADASTRO.ARQUIVOIMPORTADO') + res.data.mensagensAvisos,
                            type: 'is-warning'
                        });
                    }

                    this.$router.back()
                });
            },
            removerXml(index) {
                this.arquivosInseridos.splice(index, 1);
            },
            }
    }
</script>